import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

const BootstrapDialog = ({ open, onClose, title, content, lastModified }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle className='text-center'>{title}</DialogTitle>
            <DialogContent dividers style={{ maxHeight: '400px', overflowY: 'auto' }}>
                <Typography gutterBottom>  {lastModified} </Typography>
                {content.map((section, index) => (
                    <div key={index}>
                        {section.heading && (
                            <Typography gutterBottom variant="h6">
                                {section.heading}
                            </Typography>
                        )}
                        {section.content && section.content.map((para, idx) => (
                            <Typography gutterBottom key={idx}>
                                <div
                                    dangerouslySetInnerHTML={{ __html: para }}
                                    style={{
                                        wordWrap: 'break-word',
                                        wordBreak: 'break-word',
                                    }}
                                />
                            </Typography>
                        ))}


                        {section.heading1 && (
                            <Typography gutterBottom variant="h6" sx={{ marginTop: 2 }}>
                                {section.heading1}
                            </Typography>
                        )}
                        {section.paragraph && (
                            <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                {section.paragraph}
                            </Typography>
                        )}
                        {section.content1 && (
                            <List>
                                {section.content1.map((item, idx) => (
                                    <ListItem key={idx} sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <ListItemIcon sx={{ minWidth: 'auto', marginRight: 1 }}>
                                            <Typography variant="body1" component="span">
                                                &#8226;
                                            </Typography>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: item, // Ensure this item contains valid HTML with anchor tags
                                                    }}
                                                    style={{
                                                        wordWrap: 'break-word',
                                                        wordBreak: 'break-word',
                                                    }}
                                                />
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        )}

                    </div>
                ))}
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
                <Button
                    className="secondary"
                    autoFocus
                    onClick={onClose}
                    classes={{ root: "Secondary" }}
                >
                    <span>Close</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BootstrapDialog;
