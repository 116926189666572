import React, { useEffect, useState, useRef } from 'react';
import DataTable from "react-data-table-component";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import Navbar from '../NavBar/Navbar';
import { useNavigate } from 'react-router-dom';
import MapDashboard from '../myproporties/MapDashboard';
import FadeLoader from "react-spinners/FadeLoader";
import { IoEyeSharp } from "react-icons/io5";
import { BiSortAlt2 } from "react-icons/bi";
import StatusDropdown from '../MyWorklist/StatusDropdown';
import  PropertyList from './PropertyList' 
const PendingApprovals = () => {
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const [status, setStatus] = useState('Pending');
    const location = useLocation();
    const [parkingLotBookingDetails, setParkingLotBookingDetails] = useState([]);
    const [filter, setFilter] = useState([]);
    const [search, setSearch] = useState("");
    const [popupVisible, setPopupVisible] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
    const actionRef = useRef(null);
    const [open, setOpen] = React.useState(false);
    const [userData, setUserdata] = useState();
    const [rowData, setRowData] = useState([]);
    const [areasmarked, setAreasmarked] = useState([]);
    const [isEdit, setEdit] = useState();
    const [parkinglotObj, setparkinglotObj] = useState();
    const [defaultaddress, setDefaultaddress] = useState();
    const [loader, setLoader] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(''); // This will store the selected status
    const [sortDirection, setSortDirection] = useState('asc'); // State for sorting direction
    const [isSalespersonloggedin, setSalespersonloggedin] = useState(true);
    const [myworklistredirect, setMyworklistredirect] = useState(true);
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));
    // Function to handle status change from StatusDropdown
    const handleStatusChange = (selectedStatus) => {
        setSelectedStatus(selectedStatus);
    };
    const handleSort = () => {
        // Toggle the sorting direction
        const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        setSortDirection(newSortDirection);

        // Sort the data based on the 'createdDate' field
        const sortedData = [...filter].sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);

            if (newSortDirection === 'asc') {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });

        setFilter(sortedData);
    };
    const navigate = useNavigate();

    function getLoggedInUser() {
        const loggedInUser = localStorage.getItem('LogedInUser');
        if (!loggedInUser) {
            console.error('No LoggedInUser found');
            return null;
        }
        try {
            const stateLoggedInUser = JSON.parse(loggedInUser);
            return stateLoggedInUser;
        } catch (error) {
            console.error('Error parsing LoggedInUser', error);
            return null;
        }
    }
    const stateLogedInUser = getLoggedInUser();

    useEffect(() => {
        const fetchParkinglotDetails = async () => {
            setLoader(true);
            try {
                const response = await fetch(`${hostPath}/api/GetAllParkingLots?status=Submitted`, {
                    method: 'GET',
                    headers: { "x-functions-key": `${hostHeader}` },
                });
                setLoader(false);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setParkingLotBookingDetails(data);
            } catch (error) {
                setLoader(false);
                console.error('Failed to fetch user details:', error);
            }
        };

        fetchParkinglotDetails();
    }, [hostPath, userData]); // Add stateLogedInUser to the dependency array
    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await fetch(`${hostPath}/api/GetUserDetailsbyid`, {
                    method: 'POST',
                    headers: { "x-functions-key": `${hostHeader}` },
                    body: JSON.stringify({ EntityRegistrationID: stateLogedInUser }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setUserdata(data[0])
            } catch (error) {
                console.error('Failed to fetch user details:', error);
            }
        };

        fetchUserDetails();
    }, [stateLogedInUser]);
    useEffect(() => {
        let result = parkingLotBookingDetails;

        // Filter by search query
        if (search) {
            result = result.filter((item) => {
                return (item.parkingLotAddress || item.createdDate).toLowerCase().includes(search.toLowerCase());
            });
        }

        // Filter by selected status
        if (selectedStatus.length > 0) {
            result = result.filter((item) => selectedStatus.includes(item.status));
        }

        setFilter(result);
    }, [search, parkingLotBookingDetails, selectedStatus]);
    const handleDelete = (val, typeOfButton) => {
        let data = { EmailId: val.emailId, Parking_Lot_ID: val.parking_Lot_ID }
        setRowData(data);
        setOpen(true);
        setPopupVisible(false);
    };
    const handleConfirm = (text) => {
        if (text == 'confirm') {
            const requestOptions = {
                method: 'POST',
                headers: { "x-functions-key": hostHeader },
                body: JSON.stringify(rowData)
            };
            axios.post(`${hostPath}/api/DeleteParkingLotDetailsByEmailId`, requestOptions.body, { headers: requestOptions.headers })
                .then(response => {
                    if (response.data) {
                        if (response.data.status) {
                            const newdata = parkingLotBookingDetails.filter((item) => item.parking_Lot_ID !== rowData.Parking_Lot_ID);
                            setParkingLotBookingDetails(newdata);
                            setFilter(newdata);
                            setOpen(false);
                        }
                        else {
                            alert(response.data.responseMessage);
                        }
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
        else if (text == 'cancel') {
            setOpen(false);
        }
    };

    const handleEdit = (val, typeOfButton) => {
        setparkinglotObj(val);
        setDefaultaddress(val?.parkingLotAddress);
        const transformedAreas = val?.coardinateDetails?.map(details => ({
            Coordinates: {
                Title: details?.title || 'Unknown',
                Latitude: parseFloat(details?.latitude) || 0,
                Longitude: parseFloat(details?.longitude) || 0
            },
            Name: details?.title || 'Unknown',
            id: parseInt(details?.id, 10) || 0,
            latandlong: `Latitude: ${parseFloat(details?.latitude) || 0} Longitude: ${parseFloat(details?.longitude) || 0}`
        }));
        setAreasmarked(transformedAreas);
        setEdit(true);
        setPopupVisible(false);
    };

    const handleActionClick = (row, event) => {
        setSelectedRow(row);
        const rect = event.target.getBoundingClientRect();
        setPopupPosition({
            top: rect.bottom + window.scrollY - 75,  // Adding some offset
            right: rect.right + window.scrollX - 1450 // Center align with dots
        });
        setPopupVisible(true);
    };

    const columns = [
        {
            name: "Nickname",
            selector: (row) => row.streetName,
            style: {
                minWidth: '200px', // Increase the minimum width of the column
            },
        },
        {
            name: "Street Address",
            selector: (row) => row.parkingLotAddress,
            style: {
                minWidth: '250px', // Increase the minimum width of the column
            },
        },
        {
            name: (
                <div style={{ display: 'flex', alignItems: 'start' }}>
                    <StatusDropdown handleStatusChange={handleStatusChange} />
                </div>
            ),
            selector: (row) => row?.status,
        },
        {
            name: "Created By",
            selector: (row) => "Jhon",
        },
        {
            name: "Approved By",
            selector: (row) => "Mike",
        },
        {
            name: (
                <div style={{ display: 'flex', alignItems: 'center' }} onClick={handleSort}>
                    Last Updated <BiSortAlt2 style={{ marginLeft: '5px' }} />
                </div>
            ),
            selector: (row) => row.createdDate,
        },
        {
            name: "Comments",
            selector: (row) => row.comment || 'NA',
        },
        {
            name: "Actions",
            cell: (row) => (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                    <IoEyeSharp
                        title="View"
                        aria-label="View"
                        style={{
                            width: "38px",
                            height: "25px",
                            padding: "2px 2px",
                            cursor: "pointer",
                            justifyContent: "center",
                        }}
                        onClick={() => handleEdit(row)}
                    />
                    <FiEdit3
                        title="Edit"
                        aria-label="Edit"
                        style={{
                            width: "38px",
                            height: "25px",
                            padding: "2px 2px",
                            cursor: "pointer",
                            justifyContent: "center",
                        }}
                        onClick={() => {
                            handleEdit(row);
                            setSalespersonloggedin(false);
                        }}
                    />
                    <FiTrash2
                        title="Delete"
                        aria-label="Delete"
                        style={{
                            width: "38px",
                            height: "25px",
                            padding: "2px 2px",
                            cursor: "pointer",
                            justifyContent: "center",
                        }}
                        onClick={() => handleDelete(row)}
                    />
                    {/* Review Property Button */}
                    <button
                        onClick={() => {
                            handleEdit(row);
                            setSalespersonloggedin(false);
                        }}
                        style={{
                            padding: "6px 12px",
                            backgroundColor: "#007bff",
                            color: "#fff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer"
                        }}
                    >
                        Review Property
                    </button>
                </div>
            ),
        },
    ];
    

    const tableHeaderStyle = {
        headCells: {
            style: {
                height: "90px",
                fontWeight: 800,
                fontSize: "16px",
                backgroundColor: "white",
                lineHeight: "19.2px",
                fontFamily: "Lato",
                textAlign: "left",
                borderBottom: "none",
            },
        },
        cells: {
            style: {
                minWidth: '200px', // Ensure all cells have a minimum width
            },
        },
    };

    const navigateToDashboard = () => {
        navigate('/dashboard');
    };


    return (
        <div>
            {loader && (
                <div className="loader-overlay">
                    <FadeLoader />
                </div>
            )}
            <Navbar userData={userData} />

            {!isEdit ? (
                <div>
                    <div className='container-fluid p-0'>
                        <div className="lotmanagements">
                            <div className="row" style={{ textAlign: "left" }}>
                                <div className="col-8">
                                    <div className='viewHeader'>Registered Parking Lots</div>
                                </div>
                                <div className="col-4">
                                    <input type='text' className="form-control" placeholder='Search by address.' onChange={(e) => setSearch(e.target.value)} />
                                </div>
                            </div>
                            <div className="row" style={{ textAlign: "left" }}>
                                <div className="col-12 mb-2">
                                    Total parking lots: {parkingLotBookingDetails.length}
                                </div>
                            </div>
                            {/* <React.Fragment>
                                <DataTable
                                    customStyles={{
                                        headCells: tableHeaderStyle.headCells,
                                        cells: {
                                            style: {
                                                fontFamily: 'Lato',
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                lineHeight: '18.6px',
                                                textAlign: 'left',
                                                height: "50px",
                                                borderBottom: "none"
                                            },
                                        },
                                    }}
                                    columns={columns}
                                    data={filter.length ? filter : []}  // Pass empty array to prevent row rendering
                                    pagination
                                    fixedHeader
                                    selectableRowsHighlight
                                    highlightOnHover
                                    persistTableHead
                                    noDataComponent={
                                        <div style={{ padding: '20px 0', textAlign: 'center' }}>
                                            No records found
                                        </div>
                                    } />
                            </React.Fragment> */}
                        </div>
                        
                        <PropertyList filter={filter} />
                    </div>
                    <Dialog
                        classes={{ paper: 'custom-paper-class-delete' }}
                        aria-labelledby="customized-dialog-title"
                        dividers
                        open={open}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <DialogTitle dividers sx={{ textAlign: 'start', color: "#000;", fontSize: "24px", fontWeight: "700", padding: "5px 24px " }} id="customized-dialog-title">
                            Are you Sure You Want to Delete This Property?
                        </DialogTitle>
                        <DialogContent style={{ border: "none", padding: "5px 22px", color: "#000;" }}>
                            <Typography gutterBottom>
                                <span>Once you delete this property, you will not be able to retrieve the data. This action is irreversible. Please confirm if you wish to proceed.</span>
                            </Typography>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: 'right' }}>
                            <Button
                                className='custom-button-cancel'
                                onClick={() => handleConfirm('cancel')}
                            >
                                Cancel
                            </Button>
                            <Button
                                className='custom-button-delete'
                                onClick={() => handleConfirm('confirm')}
                            >
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>

                </div>
            ) : (
                <div><MapDashboard isEdit={isEdit} areasMarked={areasmarked} defaultaddress={defaultaddress} parkinglotObj={parkinglotObj} isSalespersonloggedin={isSalespersonloggedin} myworklistredirect={myworklistredirect} /></div>
            )}
        </div>
       
    );
};

export default PendingApprovals;
