import React, { useState, useCallback, useEffect } from 'react';
import CalendarComponent from './CalendarComponent.jsx';
import AvailabilityModal from './AvailabilityModal.jsx';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { addDays, addWeeks, addMonths, format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import EditChoiceModal from './EditChoiceModal.jsx';
import { ToastContainer, toast } from 'react-toastify'
import HolidayPopup from './HolidayPopup.jsx';
import 'react-toastify/dist/ReactToastify.css';
import ScheduleSummaryPopup from './SchedulerSummaryPopup.jsx';
import CustomToolbar from './CustomToolbar.jsx';

const Scheduler = (props) => {
  const {
    overrides,
    handleradiobutton,
    selectedDate,
    handleNavigate,
    logEvents,
    selectedValue,
    timeValues,
    errors,
    handleSave,
    isSaveDisabled,
    handleChange,
    handleStepsback,
    handleSteps,
    bankdetails,
    EmailId,
    handlebankaccountdetails,
    timeSlotesAvailability,
    toggleCondition,
    events,
    setEvents,
    newEventTriggered,
    ...rest
  } = props;
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [validatedEvents, setValidatedEvents] = useState([]);
  const [editMode, setEditMode] = useState('single');
  const [showHolidayPopup, setShowHolidayPopup] = useState(false);
  // Scheduler.jsx
  const [showEditChoiceModal, setShowEditChoiceModal] = useState(false);
  const [showSummary, setShowSummary] = useState(false);


  const handleEventsValidated = useCallback((validatedEvents) => {
    setValidatedEvents(validatedEvents);
  }, []);
  useEffect(() => {
    console.log('Scheduler - events before conversion:', events);

    // 1) Build the cutoff date = "yesterday at midnight" 
    //    (or any logic you prefer)
    const cutoff = new Date();
    cutoff.setDate(cutoff.getDate() - 1);
    cutoff.setHours(0, 0, 0, 0);

    // 2) Filter out events that start *before* the cutoff
    const futureOrYesterdayEvents = events.filter((evt) => {
      const evtStart = new Date(evt.start);
      return evtStart >= cutoff;
    });

    // 3) Then map the *filtered* list
    const convertedEvents = futureOrYesterdayEvents.map((evt) => ({
      ...evt,
      start: new Date(evt.start),
      end: new Date(evt.end),
      title: evt.title,
      id: evt.id,
      recurrenceId: evt.recurrenceId
    }));

    console.log('Scheduler - calendarEvents after conversion:', convertedEvents);
    setCalendarEvents(convertedEvents);
  }, [events]);





  const handleAddEvent = (start) => {
    setSelectedEvent({
      id: uuidv4(),
      title: '',
      start: start,
      end: new Date(start.getTime() + 60 * 60 * 1000),
      allDay: false,
      recurrence: null,
      recurrenceId: uuidv4(),
      selectedDays: [],
      endType: 'never',
      endDateOn: null,
    });
    setIsEditing(false);
    setShowModal(true);
  };


  const handleNextButtonClick = () => {
    // Validate time slot availability
    timeSlotesAvailability();
    // Move to the next step
    toggleCondition('4 of 5 - Enter Bank Details');
    toggleCondition('3 of 5 - Schedule Availability');
  };
  const eventsOverlap = (e1, e2) => {
    const start1 = new Date(e1.start);
    const end1 = new Date(e1.end);
    const start2 = new Date(e2.start);
    const end2 = new Date(e2.end);
    return start1 < end2 && end1 > start2;
  };


  const handleBackButtonClick = () => {
    props.handleConfirm(false);
    toggleCondition('2 of 5 - Mark Parking Spaces & Other Areas');
    toggleCondition('3 of 5 - Schedule Availability');
  };

  // Scheduler.jsx
  const handleSelectEvent = (event) => {
    console.log('Clicked event:', event);
    if (event.recurrenceId) {
      // It's part of a recurring series -> show the "series or single?" modal
      setSelectedEvent(event);
      setShowEditChoiceModal(true);
    } else {
      // Non‐recurring -> go straight to editing single
      setEditMode('single');
      setSelectedEvent(event);
      setIsEditing(true);
      setShowModal(true);
    }
  };


  // Scheduler.jsx
  const handleEditChoice = (mode) => {
    setEditMode(mode);
    setIsEditing(true);
    setShowModal(true);
    setShowEditChoiceModal(false);
  };

  const WEEKDAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
  const WEEKENDS = ['Sat', 'Sun'];
  const handleSaveEvent = (newEvent, recurrenceChanged = false) => {
    console.log('Scheduler - Event received from AvailabilityModal:', newEvent);

    // 1) Prepare the new (or updated) event
    const savedEvent = { ...newEvent };

    savedEvent.isMaster = true                // Mark as master by default
    savedEvent.id = savedEvent.id || uuidv4(); // Ensure it has an ID

    // Check if event is in the past
    const eventStart = new Date(savedEvent.start);
    const now = new Date();
    if (eventStart < now - 1) {
      alert("Cannot save events in the past.");
      return;
    }

    // 2) Build up your existing events, removing anything that needs replacing
    let existingEvents = [...events];
    if (savedEvent.recurrence) {
      // Ensure RecurrenceId is set
      savedEvent.recurrenceId = savedEvent.recurrenceId || uuidv4();

      // If we’re editing, remove old occurrences per edit mode
      if (isEditing) {
        if (editMode === 'series') {
          // Remove all old occurrences in that recurring series
          existingEvents = existingEvents.filter(
            (evt) => evt.recurrenceId !== selectedEvent.recurrenceId
          );
          // Keep the same RecurrenceId to overwrite old series
          savedEvent.recurrenceId = selectedEvent.recurrenceId;
        } else if (editMode === 'single') {
          // Remove only the selected occurrence
          existingEvents = existingEvents.filter((evt) => evt.id !== selectedEvent.id);
          // Break out from the old series: this becomes a standalone (or new series) event
          savedEvent.recurrenceId = null;
        }
      }

      // 3) Generate the entire recurring series (master + children)
      let recurringEvents = [];
      switch (savedEvent.recurrence) {
        case 'custom':
          // If your “custom” generator returns only children, make sure it’s updated
          // to return [masterEvent, ...children] if that’s what you prefer.
          recurringEvents = generateCustomRecurringEvents(
            savedEvent,
            savedEvent.start,
            new Date(savedEvent.end) - new Date(savedEvent.start),
            savedEvent.endAfter,
            savedEvent.endDateOn
          );
          break;
        case 'daily':
        case 'weekly':
        case 'bi-weekly':
        case 'monthly':
        case 'yearly':
          recurringEvents = generateRecurringEvents(savedEvent);
          break;
        case 'weekdays':
          recurringEvents = generateWeekdayRecurringEvents(
            savedEvent,
            savedEvent.start,
            new Date(savedEvent.end) - new Date(savedEvent.start),
            savedEvent.endAfter,
            savedEvent.endDateOn
          );
          break;
        case 'weekends':
          savedEvent.selectedDays = ['Sat', 'Sun'];
          savedEvent.customRecurrenceType = 'week';
          savedEvent.customRecurrenceCount = 1;

          // THEN call generateCustomRecurringEvents or your custom logic
          recurringEvents = generateWeekendRecurringEvents(
            savedEvent,
            savedEvent.start,
            new Date(savedEvent.end) - new Date(savedEvent.start),
            savedEvent.endAfter,
            savedEvent.endDateOn
          );
          break;

        case 'custom':
          // normal custom logic
          break;
        default:
          console.warn(`Unsupported recurrence type: ${savedEvent.Recurrence}`);
          break;
      }

      // 4) Filter out any duplicates that might conflict with existing events
      recurringEvents = recurringEvents.filter((child) => {
        return !existingEvents.some(
          (evt) => evt.start === child.start && evt.end === child.end
        );
      });

      // 5) Decide if we keep the whole series or only this single occurrence
      let newEvents;
      if (isEditing && editMode === 'single') {
        // The user chose to edit only this one occurrence: treat it as a single event
        newEvents = [savedEvent];
      } else {
        // Otherwise, include the entire master+children that the generator provided
        newEvents = recurringEvents;
      }

      // 6) Check for overlapping
      const hasOverlap = newEvents.some((evtA) =>
        existingEvents.some((evtB) => eventsOverlap(evtA, evtB))
      );
      if (hasOverlap) {
        alert('Cannot save events that overlap with existing events.');
        return;
      }

      // 7) Combine everything and store
      setEvents([...existingEvents, ...newEvents]);
    } else {
      //
      // ============== Non-Recurring Single Events ==============
      //
      if (isEditing) {
        // Remove just the event we’re editing
        existingEvents = existingEvents.filter((evt) => evt.id !== selectedEvent.id);
      }
      const newEvents = [savedEvent];

      // Check overlap
      const hasOverlap = newEvents.some((evtA) =>
        existingEvents.some((evtB) => eventsOverlap(evtA, evtB))
      );
      if (hasOverlap) {
        alert('Cannot save events that overlap with existing events.');
        return;
      }

      // Add it to the rest
      setEvents([...existingEvents, ...newEvents]);
    }

    // 8) Trigger your callback, close the modal, etc.
    newEventTriggered(savedEvent);
    handleCloseModal();
  };



  // Scheduler.jsx
  const handleDeleteEvent = (event) => {
    setEvents((prevEvents) => {
      let updatedEvents;
  
      if (editMode === 'series') {
        // Remove all occurrences in the series
        updatedEvents = prevEvents.filter((e) => e.recurrenceId !== event.recurrenceId);
      } else {
        // Remove only the selected occurrence
        updatedEvents = prevEvents.filter((e) => e.id !== event.id);
  
        // Find the next earliest occurrence in the same series
        const remainingOccurrences = updatedEvents
          .filter((e) => e.recurrenceId === event.recurrenceId)
          .sort((a, b) => new Date(a.start) - new Date(b.start));
  
        if (remainingOccurrences.length > 0) {
          // Update the new earliest event's summary
          remainingOccurrences[0].recurrenceSummary = `Occurs every ${remainingOccurrences[0].recurrence} starting ${format(new Date(remainingOccurrences[0].start), "PPPP")}`;
        }
      }
  
      console.log("Updated events after deletion:", updatedEvents);
      return updatedEvents;
    });
  
    handleCloseModal();
  };
 



  const handleCloseModal = () => {
    setShowModal(false);
    setIsEditing(false);
    setSelectedEvent(null);
    setEditMode('single');
  };

  // Recurrence Generation Functions
  function generateCustomRecurringEvents(
    event,
    startDate,
    duration,
    occurrences,
    endDateOn
  ) {
    const childEvents = [];
    let currentDate = new Date(startDate);
    let generatedCount = 0;
    let lastOccurrenceDate = null;

    // Use event.selectedDays (lowercase) or fallback to today's weekday
    const selectedDays =
      event.selectedDays && event.selectedDays.length > 0
        ? event.selectedDays
        : [format(new Date(startDate), 'EEE')];

    // Use event.customRecurrenceCount
    const recurrenceIntervalWeeks = parseInt(event.customRecurrenceCount, 10) || 1;

    while (
      (!occurrences || generatedCount < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))
    ) {
      selectedDays.forEach((day) => {
        const dayIndex = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].indexOf(day);
        if (dayIndex === -1) return;

        const eventDate = new Date(currentDate);
        eventDate.setDate(
          currentDate.getDate() + ((dayIndex - currentDate.getDay() + 7) % 7)
        );

        if (
          eventDate >= new Date(startDate) &&
          (!endDateOn || eventDate <= new Date(endDateOn))
        ) {
          if (generatedCount === 0) {
            // First occurrence is the master
            lastOccurrenceDate = new Date(eventDate);
            generatedCount++;
          } else {
            // Child occurrence
            const child = {
              ...event,
              id: uuidv4(),
              recurrenceId: event.recurrenceId,
              start: formatLocalDateTime(eventDate),
              end: formatLocalDateTime(new Date(eventDate.getTime() + duration)),
              isMaster: false,
            };
            // Avoid duplicates
            if (
              !childEvents.some(
                (e) => e.start === child.start && e.end === child.end
              )
            ) {
              childEvents.push(child);
              lastOccurrenceDate = new Date(eventDate);
            }
            generatedCount++;
          }
        }
      });

      // Move "currentDate" ahead by N weeks
      currentDate = addWeeks(currentDate, recurrenceIntervalWeeks);
    }

    // Master event
    const masterEvent = {
      ...event,
      id: event.id || uuidv4(),
      isMaster: true,
      start: formatLocalDateTime(new Date(startDate)),
      end: formatLocalDateTime(
        new Date(new Date(startDate).getTime() + duration)
      ),
      lastOccurrence: lastOccurrenceDate
        ? formatLocalDateTime(lastOccurrenceDate)
        : null,
    };

    return [masterEvent, ...childEvents];
  }



  function generateWeeklyRecurringEvents(
    event,
    startDate,
    duration,
    occurrences,
    endDateOn,
    interval = 1
  ) {
    console.log('Generating weekly recurring events:', event);
    const weeklyEvents = [];
    let currentDate = new Date(startDate);
    let i = 0;

    // Use event.selectedDays (lowercase)
    const selectedDays = event.selectedDays && event.selectedDays.length
      ? event.selectedDays
      : [format(currentDate, 'EEE')];

    while (
      (!occurrences || i < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))
    ) {
      const dayName = format(currentDate, 'EEE');
      if (selectedDays.includes(dayName)) {
        console.log('Adding event for date:', currentDate);
        weeklyEvents.push({
          ...event,
          start: new Date(currentDate),
          end: new Date(currentDate.getTime() + duration),
        });
        i++;
      }
      currentDate = calculateNextWeeklyDate(currentDate, interval);
    }
    return weeklyEvents;
  }

  function generateMonthlyRecurringEvents(
    event,
    startDate,
    duration,
    occurrences,
    endDateOn
  ) {
    console.log('Generating monthly recurring events:', event);
    const monthlyEvents = [];
    let currentDate = new Date(startDate);
    let i = 0;

    while (
      (!occurrences || i < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))
    ) {
      console.log('Adding event for date:', currentDate);
      monthlyEvents.push({
        ...event,
        start: new Date(currentDate),
        end: new Date(currentDate.getTime() + duration),
      });

      // Use event.customRecurrenceCount
      const interval = event.customRecurrenceCount || 1;
      currentDate = calculateNextMonthlyDate(currentDate, interval);
      i++;
    }

    return monthlyEvents;
  }

  function generateDailyRecurringEvents(
    event,
    startDate,
    duration,
    occurrences,
    endDateOn
  ) {
    const dailyEvents = [];
    let currentDate = new Date(startDate);
    let i = 0;

    const recurrenceInterval = event.customRecurrenceCount || 1;

    while (
      (!occurrences || i < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))
    ) {
      dailyEvents.push({
        ...event,
        start: new Date(currentDate),
        end: new Date(currentDate.getTime() + duration),
      });

      i++;
      currentDate = addDays(currentDate, recurrenceInterval);
    }

    return dailyEvents;
  }

  function generateWeekdayRecurringEvents(
    event,
    startDate,
    duration,
    occurrences,
    endDateOn
  ) {
    if (!(startDate instanceof Date)) {
      startDate = new Date(startDate);
    }
    if (endDateOn && !(endDateOn instanceof Date)) {
      endDateOn = new Date(endDateOn);
    }

    const childEvents = [];
    let currentDate = new Date(startDate);
    let generatedCount = 0;
    let lastOccurrenceDate = null;

    while (
      (!occurrences || generatedCount < occurrences) &&
      (!endDateOn || currentDate <= endDateOn)
    ) {
      const dayOfWeek = currentDate.getDay(); // 0=Sunday, 1=Monday,...
      // Monday=1 ... Friday=5
      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        if (generatedCount === 0) {
          // The very first day is master
          lastOccurrenceDate = new Date(currentDate);
          generatedCount++;
        } else {
          // Child
          const childEvent = {
            ...event,
            id: uuidv4(),
            recurrenceId: event.recurrenceId,
            start: formatLocalDateTime(currentDate),
            end: formatLocalDateTime(
              new Date(currentDate.getTime() + duration)
            ),
            isMaster: false,
          };
          childEvents.push(childEvent);
          lastOccurrenceDate = new Date(currentDate);
          generatedCount++;
        }
      }
      currentDate = addDays(currentDate, 1);
    }

    const masterEvent = {
      ...event,
      id: event.id || uuidv4(),
      recurrenceId: event.recurrenceId,
      isMaster: true,
      start: formatLocalDateTime(startDate),
      end: formatLocalDateTime(new Date(startDate.getTime() + duration)),
      lastOccurrence: lastOccurrenceDate
        ? formatLocalDateTime(lastOccurrenceDate)
        : null,
    };

    return [masterEvent, ...childEvents];
  }
  function generateWeekendRecurringEvents(
    event,
    startDate,
    duration,
    occurrences,
    endDateOn,
    interval = 1
  ) {
    // Ensure Date objects
    if (!(startDate instanceof Date)) {
      startDate = new Date(startDate);
    }
    if (endDateOn && !(endDateOn instanceof Date)) {
      endDateOn = new Date(endDateOn);
    }

    const childEvents = [];
    let generatedOccurrences = 0;
    let lastOccurrenceDate = null;

    // -----------------------------
    // 1) If startDate is SUNDAY => create that single event immediately
    //    and anchor the loop from Monday. That prevents the double Sunday
    //    and ensures next iteration sees the upcoming Saturday.
    // -----------------------------
    if (startDate.getDay() === 0) {
      // The user specifically started on a Sunday => "master"
      lastOccurrenceDate = new Date(startDate);
      generatedOccurrences++;

      // If we want it in the events array as the "master" day:
      // We'll do that at the end, see below
      // but we record "start" as the original day.

      // Now push the anchor to Monday
      startDate.setDate(startDate.getDate() + 1);
    }

    // 'currentDate' anchors each iteration (one iteration = one weekend).
    let currentDate = new Date(startDate);

    // Normal weekend iteration
    while (
      (!occurrences || generatedOccurrences < occurrences) &&
      (!endDateOn || currentDate <= endDateOn)
    ) {
      // 2) Find next Saturday
      const saturday = nextSaturday(currentDate);
      if (
        saturday >= startDate &&
        (!endDateOn || saturday <= endDateOn) &&
        (!occurrences || generatedOccurrences < occurrences)
      ) {
        if (generatedOccurrences === 0) {
          // If truly the first occurrence => master
          lastOccurrenceDate = new Date(saturday);
          generatedOccurrences++;
        } else {
          // child
          const childSat = {
            ...event,
            id: uuidv4(),
            recurrenceId: event.recurrenceId,
            start: formatLocalDateTime(saturday),
            end: formatLocalDateTime(
              new Date(saturday.getTime() + duration)
            ),
            isMaster: false
          };
          childEvents.push(childSat);
          lastOccurrenceDate = new Date(saturday);
          generatedOccurrences++;
        }
      }

      // 3) Find next Sunday
      const sunday = nextSunday(currentDate);
      if (
        sunday >= startDate &&
        (!endDateOn || sunday <= endDateOn) &&
        (!occurrences || generatedOccurrences < occurrences)
      ) {
        if (generatedOccurrences === 0) {
          // If truly the first occurrence => master
          lastOccurrenceDate = new Date(sunday);
          generatedOccurrences++;
        } else {
          // child
          const childSun = {
            ...event,
            id: uuidv4(),
            recurrenceId: event.recurrenceId,
            start: formatLocalDateTime(sunday),
            end: formatLocalDateTime(
              new Date(sunday.getTime() + duration)
            ),
            isMaster: false
          };
          childEvents.push(childSun);
          lastOccurrenceDate = new Date(sunday);
          generatedOccurrences++;
        }
      }

      // Jump forward 'interval' weeks
      currentDate = addWeeks(currentDate, interval);
    }

    // Build the "master" event:
    // If the user started on Sunday, that was the real day => the "master" start is that day
    // Otherwise it's the original startDate. We'll define "master" below:
    const masterStart = event.start; // The original start, e.g. Sunday if so
    const startDT = new Date(masterStart);
    // Because we are setting "start" from event.start, which presumably is "YYYY-MM-DD..."
    // or you can store the Sunday if day=0

    const masterEvent = {
      ...event,
      id: event.id || uuidv4(),
      isMaster: true,
      recurrenceId: event.recurrenceId,
      start: formatLocalDateTime(startDT),
      end: formatLocalDateTime(new Date(startDT.getTime() + duration)),
      lastOccurrence: lastOccurrenceDate
        ? formatLocalDateTime(lastOccurrenceDate)
        : null
    };

    return [masterEvent, ...childEvents];
  }

  // nextSaturday, nextSunday, addWeeks, formatLocalDateTime, etc
  function nextSaturday(date) {
    const temp = new Date(date);
    const day = temp.getDay(); // 0=Sun, 6=Sat
    const offset = (6 - day + 7) % 7;
    temp.setDate(temp.getDate() + offset);
    return temp;
  }
  function nextSunday(date) {
    const temp = new Date(date);
    const day = temp.getDay(); // 0=Sun
    const offset = (7 - day + 7) % 7;
    temp.setDate(temp.getDate() + offset);
    return temp;
  }
  function addWeeks(date, n) {
    const copy = new Date(date);
    copy.setDate(copy.getDate() + n * 7);
    return copy;
  }
  function formatLocalDateTime(date) {
    if (!(date instanceof Date)) date = new Date(date);
    const y = date.getFullYear();
    const m = String(date.getMonth() + 1).padStart(2, '0');
    const d = String(date.getDate()).padStart(2, '0');
    const hh = String(date.getHours()).padStart(2, '0');
    const mm = String(date.getMinutes()).padStart(2, '0');
    return `${y}-${m}-${d}T${hh}:${mm}`;
  }

  // Example local date/time formatter


  // Helper methods
  const calculateNextWeeklyDate = (currentDate, interval) => {
    let nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + interval * 7);
    return nextDate;
  };

  const calculateNextMonthlyDate = (currentDate, interval) => {
    let nextDate = new Date(currentDate);
    nextDate.setMonth(nextDate.getMonth() + interval);
    return nextDate;
  };

  // Function to calculate recurrence interval in days
  const recurrenceInterval = (event) => {
    switch (event.Recurrence) {
      case 'daily':
        return 1;
      case 'weekly':
        return 7;
      case 'bi-weekly':
        return 14;
      case 'monthly':
        return 0; // Months handled separately
      case 'yearly':
        return 0; // Years handled separately
      case 'custom':
        const interval = event.CustomRecurrenceCount || 1;
        switch (event.CustomRecurrenceType) {
          case 'day':
            return interval;
          case 'week':
            return interval * 7;
          case 'month':
            return 0; // Months handled separately
          case 'year':
            return 0; // Years handled separately
          default:
            return 1;
        }
      default:
        return 1;
    }
  };

  function handleSummaryEdit(event) {
    // We *never* want to show the "EditChoiceModal" here,
    // so we skip the usual "if (event.RecurrenceId) => setShowEditChoiceModal(true)"

    setSelectedEvent(event);
    setIsEditing(true);

    if (event.recurrenceId) {
      // If it’s a recurring event, default to editing the entire series
      // (or single if you prefer)
      setEditMode('series');
    } else {
      // Non-recurring => single
      setEditMode('single');
    }

    setShowModal(true);
  }
  // Function to get the next occurrence date
  // const getNextOccurrence = (date, event) => {
  //   const nextDate = new Date(date);
  //   let interval = 1; // Default interval for standard recurrence types

  //   if (event.Recurrence === 'custom') {
  //     interval = parseInt(event.CustomRecurrenceCount, 10) || 1;
  //   }

  //   switch (event.Recurrence) {
  //     case 'daily':
  //       nextDate.setDate(nextDate.getDate() + interval);
  //       break;
  //     case 'weekly':
  //       nextDate.setDate(nextDate.getDate() + 7 * interval);
  //       break;
  //     case 'bi-weekly':
  //       nextDate.setDate(nextDate.getDate() + 14 * interval);
  //       break;
  //     case 'monthly':
  //       nextDate.setMonth(nextDate.getMonth() + interval);
  //       break;
  //     case 'yearly':
  //       nextDate.setFullYear(nextDate.getFullYear() + interval);
  //       break;
  //     case 'custom':
  //       switch (event.CustomRecurrenceType) {
  //         case 'day':
  //           nextDate.setDate(nextDate.getDate() + interval);
  //           break;
  //         case 'week':
  //           nextDate.setDate(nextDate.getDate() + 7 * interval);
  //           break;
  //         case 'month':
  //           nextDate.setMonth(nextDate.getMonth() + interval);
  //           break;
  //         case 'year':
  //           nextDate.setFullYear(nextDate.getFullYear() + interval);
  //           break;
  //         default:
  //           nextDate.setDate(nextDate.getDate() + 1);
  //           break;
  //       }
  //       break;
  //     default:
  //       nextDate.setDate(nextDate.getDate() + 1);
  //       break;
  //   }
  //   return nextDate;
  // };


  // Function to format local date and time as a string
  // const formatLocalDateTime = (date) => {
  //   const year = date.getFullYear();
  //   const month = (`${date.getMonth() + 1}`).padStart(2, '0');
  //   const day = (`${date.getDate()}`).padStart(2, '0');
  //   const hours = (`${date.getHours()}`).padStart(2, '0');
  //   const minutes = (`${date.getMinutes()}`).padStart(2, '0');
  //   return `${year}-${month}-${day}T${hours}:${minutes}`;
  // };


  function generateRecurringEvents(event) {
    const finalEvents = [];
    const startDateTime = new Date(event.start);
    const endDateTime = new Date(event.end);
    const duration = endDateTime - startDateTime;

    const recurrenceId = event.recurrenceId || uuidv4();
    let endConditionDate = null;
    let maxOccurrences = Infinity;
    let lastOccurrenceDate = null;

    // If user specified event.endType === 'on'
    if (event.endType === 'on' && event.endDateOn) {
      endConditionDate = new Date(event.endDateOn);
    } else if (event.endType === 'after' && event.endAfter) {
      maxOccurrences = parseInt(event.endAfter, 10) || 1;
    } else {
      // endType === 'never' => default 1 year
      endConditionDate = new Date(startDateTime);
      endConditionDate.setFullYear(endConditionDate.getFullYear() + 1);
    }

    let generatedOccurrences = 0;
    let currentDate = new Date(startDateTime);

    while (
      generatedOccurrences < maxOccurrences &&
      (!endConditionDate || currentDate <= endConditionDate)
    ) {
      if (generatedOccurrences === 0) {
        // First occurrence => master day
        lastOccurrenceDate = new Date(currentDate);
        generatedOccurrences++;
      } else {
        // Child
        const child = {
          ...event,
          id: uuidv4(),
          recurrenceId,
          start: formatLocalDateTime(currentDate),
          end: formatLocalDateTime(new Date(currentDate.getTime() + duration)),
          isMaster: false,
        };
        finalEvents.push(child);
        lastOccurrenceDate = new Date(currentDate);
        generatedOccurrences++;
      }
      currentDate = getNextOccurrence(currentDate, event);
    }

    // Master
    const masterEvent = {
      ...event,
      id: event.id || uuidv4(),
      recurrenceId,
      isMaster: true,
      start: formatLocalDateTime(startDateTime),
      end: formatLocalDateTime(new Date(startDateTime.getTime() + duration)),
      lastOccurrence: lastOccurrenceDate
        ? formatLocalDateTime(lastOccurrenceDate)
        : null,
    };

    return [masterEvent, ...finalEvents];
  }

  // Example helper that moves to the next occurrence
  function getNextOccurrence(currentDate, event) {
    const next = new Date(currentDate);
    switch (event.recurrence) {
      case 'daily':
        next.setDate(next.getDate() + 1);
        break;
      case 'weekly':
        next.setDate(next.getDate() + 7);
        break;
      case 'bi-weekly':
        next.setDate(next.getDate() + 14);
        break;
      case 'monthly':
        next.setMonth(next.getMonth() + 1);
        break;
      // etc. for other types
      default:
        // If none matched, just increment 1 day
        next.setDate(next.getDate() + 1);
        break;
    }
    return next;
  }

  // Example local date/time formatter
  function formatLocalDateTime(dateInput) {
    // If it’s not already a date, convert it
    if (!(dateInput instanceof Date)) {
      dateInput = new Date(dateInput);
    }
    // Now dateInput is definitely a Date, so:
    const year = dateInput.getFullYear();
    const month = String(dateInput.getMonth() + 1).padStart(2, '0');
    const day = String(dateInput.getDate()).padStart(2, '0');
    const hour = String(dateInput.getHours()).padStart(2, '0');
    const min = String(dateInput.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hour}:${min}`;
  }
  function handleSummaryDelete(event) {
    const isSure = window.confirm('Are you sure you want to delete this event?');
    if (!isSure) return;

    if (event.recurrenceId) {
      // It's a recurring event => delete the entire series
      setEvents((prevEvents) =>
        prevEvents.filter((e) => e.recurrenceId !== event.recurrenceId)
      );
    } else {
      // Single event => delete just this one
      setEvents((prevEvents) =>
        prevEvents.filter((e) => e.id !== event.id)
      );
    }
  }


  const handleHolidaySelect = (holiday) => {
    console.log("this is the holiday object", holiday);
    const holidayDateStr = holiday.date; // e.g. "2025-01-31"

    // Build local times for an entire day:
    const startStr = `${holidayDateStr}T00:00:00`;
    const endStr = `${holidayDateStr}T23:59:59`;

    const holidayEventDetails = {
      id: uuidv4(),         // if you want an ID upfront
      title: holiday.name,
      start: startStr,      // Now a valid local datetime
      end: endStr,          // same day (covers 24h)
      allDay: true,
      recurrence: null,
      recurrenceId: null,
      // anything else you want
    };

    setSelectedEvent(holidayEventDetails);
    setIsEditing(false);
    setShowModal(true);
  }

  const handleOpenModal = () => {
    // Set default date to today
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth(); // Note: month is zero-based
    const date = today.getDate();

    // Create Date objects for 12:00 PM and 1:00 PM
    const startTime = new Date(year, month, date, 12, 0, 0); // 12:00 PM
    const endTime = new Date(year, month, date, 13, 0, 0);   // 1:00 PM

    setSelectedEvent({
      id: uuidv4(),
      title: '',
      start: startTime.toISOString(), // Set default Start time
      end: endTime.toISOString(),     // Set default End time
      allDay: false,
      recurrence: null,
      recurrenceId: uuidv4(),
      selectedDays: [],
      endType: 'never',
      endDateOn: null,
    });
    setIsEditing(false);
    setShowModal(true);
  };


  const backButtonClick = () => {
    props.handleConfirm(false);
    toggleCondition('2 of 5 - Mark Pick up and Drop off Area');
    toggleCondition('3 of 5 - Schedule Availability');
  };

  return (
    <div className="Scheduler">
      <ToastContainer />
      <button
        onClick={() => setShowSummary(true)}
        style={{
          background: '#6acd74',
          position: 'absolute',
          bottom: '100px',
          left: '100px',
          zIndex: 9999,
        }}
      >
        Show Summary
      </button>

      {/* Render the summary popup conditionally */}
      {showSummary && (
        <ScheduleSummaryPopup
          open={showSummary}    // pass boolean
          events={events}
          onClose={() => setShowSummary(false)}
          onEdit={handleSummaryEdit}
          onDelete={handleSummaryDelete}
        />
      )}



      {/* (2) Render the Holiday Popup */}
      <HolidayPopup
        open={showHolidayPopup}
        onClose={() => setShowHolidayPopup(false)}
        //onAddHolidayEvent={handleAddHolidayEvent}
        const holi
        onHolidaySelect={handleHolidaySelect}
      />
      <CalendarComponent
        events={calendarEvents}
        onSelectEvent={handleSelectEvent}
        onAddEvent={handleAddEvent}
        onOpenModal={() => setShowModal(true)}
        onEventsValidated={handleEventsValidated}
        selectedDate={selectedDate}
        onNavigate={handleNavigate}
        components={{
          toolbar: (toolbarProps) => (
            <CustomToolbar
              {...toolbarProps}
              onShowHolidays={() => setShowHolidayPopup(true)}
            />
          )
        }}
      />
      <div className="mb-3">
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenModal}
          style={{ marginTop: '2vh' }}
        >
          Schedule Closed Hours
        </Button>
      </div>
      <EditChoiceModal
        show={showEditChoiceModal}
        onClose={() => setShowEditChoiceModal(false)}
        onEditChoice={handleEditChoice}
      />

      <AvailabilityModal
        show={showModal}
        onClose={handleCloseModal}
        onSave={handleSaveEvent}
        onDelete={handleDeleteEvent}
        isEditing={isEditing}
        editMode={editMode}
        eventDetails={
          selectedEvent || {
            title: '',
            start: '',
            end: '',
            recurrence: '',
            allDay: false,
            recurrenceId: null,

          }
        }
      />
      <div style={{ textAlign: 'end', marginTop: '40px ', marginRight: '7vw' }}>
        <div
          className="back-wrapper"
          style={{
            width: '100px',
            backgroundColor: '#fff',
            boxShadow: '0 0 3px 2px #cec7c759',
          }}
          onClick={handleBackButtonClick}
        >
          <b className="next">Go Back</b>
        </div>
        <div
          className="next-wrapper"
          onClick={handleNextButtonClick}
          style={{

            backgroundColor: '#fff',
            boxShadow: '0 0 3px 2px #cec7c759',
          }}
        >
          <b className="next">Next</b>
        </div>
        <div
          className="autosave1-wrapper"
          onClick={(e) => handleSave(e)}
          style={{
            pointerEvents: isSaveDisabled ? 'none' : 'auto',
            opacity: isSaveDisabled ? 0.5 : 1,
            marginRight: '30px',
            backgroundColor: '#6ACD54',
            boxShadow: '0 0 3px 2px #cec7c759',

          }}
        >
          <b className="next">Save</b>

        </div>

      </div>
    </div>
  );
};

export default Scheduler;