import React from "react";
const CustomToolbar = ({
    label,
    onNavigate,
    onView,
    views,
    view,
    onShowHolidays, // <— we receive the callback here
}) => {
    const goToBack = () => {
        onNavigate('PREV');
    };

    const goToNext = () => {
        onNavigate('NEXT');
    };

    const goToToday = () => {
        onNavigate('TODAY');
    };

    const handleViewChange = (newView) => {
        onView(newView);
    };

    return (
        <div className="toolbar-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* Left side: "Today," "Show U.S. Holidays," plus the calendar label */}
            <div className="toolbar-left" style={{ display: 'flex', alignItems: 'center' }}>
                <span className="toolbar-title" style={{ fontWeight: 'bold' }}>
                    {label}
                </span>
                <button
                    className="toolbar-button today-button"
                    onClick={goToToday}
                    style={{ marginRight: '1rem' }}
                >
                    Today
                </button>

                {/* Add your Show Holidays button next to "Today" */}
                <button
                    className="toolbar-button holiday-button"
                    onClick={() => {

                        console.log("DEBUG: Show U.S. Holidays button clicked!");
                        onShowHolidays();
                    }}
                    style={{ marginRight: '1rem' }}
                >
                    Show U.S. Holidays
                </button>

                {/* The month label, e.g. "January 2025" */}

            </div>

            {/* Right side: next/prev arrows + view buttons */}
            <div className="toolbar-right" style={{ display: 'flex', alignItems: 'center' }}>
                <button
                    className="toolbar-svg-container"
                    onClick={goToBack}
                    style={{ marginRight: '0.5rem', cursor: 'pointer' }}
                >
                    ‹
                </button>
                <button
                    className="toolbar-svg-container"
                    onClick={goToNext}
                    style={{ marginRight: '1rem', cursor: 'pointer' }}
                >
                    ›
                </button>

                {/* The view buttons (Month, Week, Day, etc.) */}
                {views.map((viewName) => (
                    <button
                        key={viewName}
                        className={`toolbar-button ${view === viewName ? 'active' : ''}`}
                        onClick={() => handleViewChange(viewName)}
                        style={{ marginRight: '0.5rem' }}
                    >
                        {viewName.charAt(0).toUpperCase() + viewName.slice(1)}
                    </button>
                ))}
            </div>
        </div>
    );
};


export default CustomToolbar;