import React, { useState, useEffect } from "react";
import RobotaxiLogin from "../../Images/LoginImage.png";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import BootstrapDialog from '../BootstrapDialog/BootstrapDialog.jsx';
import termsOfServicesData from '../TermsOfServices/TermsOfServices.json';
import termsOfServicesData1 from '../TermsOfServices/TermsOfServicesOfOwner.json'

import termsOfUsesData from '../TermsOfServices/TermsOfUses.json';




// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   "& .MuiDialogContent-root": {
//     padding: theme.spacing(2),
//   },
//   "& .MuiDialogActions-root": {
//     padding: theme.spacing(1),
//   },
// }));

const AccountInformation = (props) => {
  const hostPath = process.env.REACT_APP_API_BASE_URL;
  const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
  const navigate = useNavigate();
  const EntityRegistration = props.loginUserDetails;
  const [errors, setErrors] = useState({});
  const [postId, setPostId] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openDuplicate, setOpenDuplicate] = React.useState(false);
  const [rangeValue, setRangeValue] = useState(100);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [entityId, setEntityId] = useState(null);
  const [dialogData, setDialogData] = useState({ title: '', sections: [] });


  const [formData, setFormData] = useState({
    EmailId: "",
    Password: "",
    ConfirmPassword: "",
    TandC: false,
    TandC2: false,
  });
  const [passwordRequirements, setPasswordRequirements] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false,
    noSpaces: false
  });

  const backgroundStyle = {
    background: `linear-gradient(90deg, green ${rangeValue}%, rgb(230 224 224) )`,
  };

  useEffect(() => {
    const EntityRegistration = props.loginUserDetails;
    setFormData((prevState) => ({
      ...prevState,
      ...EntityRegistration,
    }));
  }, []);

  const validateForm = () => {
    const errors = {};
    if (!formData.TandC) {
      errors.TandC = "Agree Terms of Service";
    }
    if (!formData.TandC2) {
      errors.TandC2 = "Agree Terms of Use";
    }

    if (!formData.EmailId) {
      errors.EmailId = "Email is required";
    }

    if (!formData.Password) {
      errors.Password = "Password is required";
    } else if (!validatePassword(formData.Password)) {
      errors.Password = "Passwords requirements are not satisified: ";
    }
    if (!formData.ConfirmPassword) {
      errors.ConfirmPassword = "Confirm Password is required";
    } else if (formData.Password != formData.ConfirmPassword) {
      errors.ConfirmPassword = "Please ensure the Passwords match";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const registrationButtonClick = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const updatedFormData = { ...EntityRegistration, ...formData };
      const requestOptions = {
        method: "POST",
        headers: { "x-functions-key": `${hostHeader}` },
        body: JSON.stringify(updatedFormData),
      };
      axios
        .post(`${hostPath}/api/entityregistration`, requestOptions.body, {
          headers: requestOptions.headers,
        })
        .then((response) => {
          const errors = {};
          if (response.data.status === true) {
            setPostId(response.data.id);
            localStorage.clear();
            const LogedInUserRes = response.data.responseMessage;
            const entityID = response?.data?.userData?.entityRegistrationID;
            setEntityId(entityID);
            localStorage.setItem(
              "LogedInUser",
              JSON.stringify(entityID)
            );
            if (entityID) {
              fetchUserDetails(entityID);
            }
          } else {
            if (response.data.status === false) {
              errors.EmailId = response.data.responseMessage;
            }
          }
          setErrors(errors);
          return Object.keys(errors).length === 0;
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  const fetchUserDetails = async (entityID) => {
    try {
      const response = await fetch(`${hostPath}/api/GetUserDetailsbyid`, {
        method: 'POST',
        headers: { "x-functions-key": `${hostHeader}` },
        body: JSON.stringify({ EntityRegistrationID: entityID }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const firstInitial = data[0]?.firstName && data[0]?.firstName?.length > 1 ? data[0]?.firstName?.charAt(0) : '';
      const lastInitial = data[0]?.lastName && data[0]?.lastName?.length > 1 ? data[0]?.lastName?.charAt(0) : '';
      const initials = firstInitial && lastInitial ? (firstInitial + lastInitial).toUpperCase() : '';
      localStorage.setItem('initials', JSON.stringify(initials));
      navigate("/dashboard");
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleCheckboxChange = () => {
    setFormData({
      ...formData,
      TandC: !formData.TandC,
    });
    errors.TandC = "";
  };
  const handleClickOpen = (type) => {
    let item;
    if (type === 'termsOfServices') {
      item = termsOfServicesData1.termsOfServicesOfOwner[0];
    } else if (type === 'termsOfUses') {
      item = termsOfUsesData.termsOfUses[0];
    }
    if (item) {
      setDialogData(item);
      setOpen(true);
    } else {
      console.error("No data found.");
    }
  };

  const handleClickOpenDuplicate = () => {
    setOpenDuplicate(true);
  };

  const handleCloseDuplicate = () => {
    setOpenDuplicate(false);
  };

  const handleCheckboxChange2 = () => {
    setFormData({
      ...formData,
      TandC2: !formData.TandC2,
    });
    errors.TandC2 = "";
  };



  const handleClose = () => {
    setOpen(false);
  };

  const validatePassword = (password) => {
    const requirements = {
      minLength: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digit: /\d/.test(password),
      specialChar: /[!@#$%^&*]/.test(password),
      noSpaces: !/\s/.test(password),
    };
    setPasswordRequirements(requirements);
    const allRequirementsMet = Object.values(requirements).every(requirement => requirement);
    return allRequirementsMet;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    clearError(name); // Clear error for the corresponding input field
    if (name === "Password") {
      validatePassword(value);
    }
  };

  const clearError = (fieldName) => {
    setErrors((prevErrors) => {
      return { ...prevErrors, [fieldName]: "" };
    });
  };

  const goBack = () => {
    if (props && props.onEnityInfo && props.onEnityInfo()) {
      props?.onEnityInfo();
    }
  };

  return (
    <div className="container-fluid p-0">
      <div className="row m-0">
        <div className="col-md-7 p-0">
          <img src={RobotaxiLogin} alt="Login" className="GeoparkingImage" />
        </div>
        <div className="col-md-5">
          <div className="contain">
            <div className="registersteps formbox">Step 3 of 3</div>
            <input
              type="range"
              className="range-input"
              value={rangeValue}
              min="0"
              max="100"
              style={{
                ...backgroundStyle,
                width: "80%",
                height: "5px",
                padding: "0",
                borderRadius: "5.5px",
              }}
            />
            <form className="login-form ">
              <div className=" login">
                <h3 className="loginheader textleft">Account Information</h3>
                <p className="mb-4 loginDescription textleft">
                  Choose Email and Password to complete your registration.
                </p>
              </div>
              <div className="Username mb-3 textleft">
                <label htmlFor="EmailId" className="userName loginlabel ">
                  Email
                </label>
                <span className="mandatorymark loginlabel">*</span>
                <input
                  type="text"
                  className={`form-control ${formData.EmailId ? "input-active" : ""
                    }`}
                  id="EmailId"
                  placeholder="Enter Email"
                  name="EmailId"
                  value={formData?.EmailId?.toLowerCase()}
                  onChange={handleChange}
                  readOnly
                />
                {errors.EmailId && (
                  <div className="error-message mandatorymark">
                    {errors.EmailId}
                  </div>
                )}
              </div>
              <div className="Password mb-3 textleft">
                <style>
                  {`
                  input::-ms-reveal {
                  display: none;
                  }
                `}
                </style>
                <label htmlFor="password" className="userName loginlabel">
                  Password
                </label>
                <span className="mandatorymark loginlabel">*</span>
                <div
                  className="password-container"
                  style={{ position: "relative" }}
                >
                  <input
                    type={passwordVisible ? "text" : "password"}
                    className={`form-control ${formData.Password ? "input-active" : ""
                      }`}
                    id="password"
                    placeholder="Enter Password"
                    name="Password"
                    value={formData.Password}
                    onChange={handleChange}
                  />
                  <IconButton
                    data-testid="toggle-password-visibility"
                    onClick={togglePasswordVisibility}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  >
                    {passwordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
                {errors.Password && (
                  <div className="error-message mandatorymark">
                    {errors.Password}
                  </div>
                )}
                <ul style={{ listStyleType: "none", paddingLeft: 0, paddingTop: 10 }}>
                  {" "}
                  {/* Remove padding to align to the extreme left */}
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.minLength ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.minLength ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    At least 8 characters
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.uppercase ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.uppercase ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    Includes at least one uppercase letter (A-Z)
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.lowercase ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.lowercase ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    Includes at least one lowercase letter (a-z)
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.digit ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.digit ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    Includes at least one digit (0-9)
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.specialChar ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.specialChar ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    Includes at least one special character (!, @, #, $, %, ^,
                    &, *)
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: passwordRequirements.noSpaces && formData?.Password?.length > 0 ? "green" : "red",
                    }}
                  >
                    {passwordRequirements.noSpaces && formData?.Password?.length > 0 ? (
                      <CheckCircleOutlineIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "8px",
                          color: "red",
                        }}
                      />
                    )}
                    Does not contain spaces.
                  </li>
                </ul>
              </div>
              <div className="Password mb-3 textleft">
                <style>
                  {`
                  input::-ms-reveal {
                  display: none;
                  }
                `}
                </style>
                <label
                  htmlFor="confirmPassword"
                  className="userName loginlabel"
                >
                  Confirm Password
                </label>
                <span className="mandatorymark loginlabel">*</span>
                <div
                  className="password-container"
                  style={{ position: "relative" }}
                >
                  <input
                    type={confirmPasswordVisible ? "text" : "password"}
                    className={`form-control ${formData.ConfirmPassword ? "input-active" : ""
                      }`}
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    name="ConfirmPassword"
                    value={formData.ConfirmPassword}
                    onChange={handleChange}
                  />
                  <IconButton
                    data-testid="toggle-confirm-password-visibility"
                    onClick={toggleConfirmPasswordVisibility}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  >
                    {confirmPasswordVisible ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </div>
                {errors.ConfirmPassword && (
                  <div className="error-message mandatorymark">
                    {errors.ConfirmPassword}
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-between mb-2">
                <div className="remember">
                  <label className="control control--checkbox mb-0">
                    <input
                      type="checkbox"
                      checked={formData.TandC2}
                      onChange={handleCheckboxChange2}
                    />{" "}
                    <span className="">
                      I Agree to{" "}
                      <a className="joingeo" style={{ cursor: 'pointer' }} onClick={() => handleClickOpen("termsOfUses")}>
                        {" "}
                        Terms of Use
                      </a>{" "}
                    </span>
                    <div className="control__indicator"></div>
                  </label>
                  {errors.TandC2 && (
                    <div className="error-message mandatorymark">
                      {errors.TandC2}
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between mb-5">
                <div className="remember">
                  <label className="control control--checkbox mb-0">
                    <input
                      type="checkbox"
                      checked={formData.TandC}
                      onChange={handleCheckboxChange}
                    />{" "}
                    <span className="">
                      I Agree to{" "}
                      <a className="joingeo" style={{ cursor: 'pointer' }} onClick={() => handleClickOpen("termsOfServices")}>
                        {" "}
                        Terms of Service
                      </a>{" "}
                    </span>
                    <div className="control__indicator"></div>
                  </label>
                  {errors.TandC && (
                    <div className="error-message mandatorymark">
                      {errors.TandC}
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <Link onClick={goBack} className="joingeo">
                  Go Back
                </Link>
                <button
                  type="submit"
                  className="nextBtn"
                  onClick={registrationButtonClick}
                >
                  Register
                </button>
              </div>
              <div className="joinus remember" style={{ textAlign: "end" }}>
                Already have an account?
                <a href="/Home" className="joingeo">Log in</a>here.
              </div>
            </form>
          </div>
        </div>
        <BootstrapDialog
          open={open}
          onClose={handleClose}
          title={`${dialogData.title}`}
          content={dialogData.sections}
          lastModified={`${dialogData.lastModified}`}
          setOpen={setOpen}
        />


      </div>
    </div >
  );
};

export default AccountInformation;
