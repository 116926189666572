import React, { useState, useEffect, useMemo } from 'react';
import GlobalSearchDataTable from './GlobalSearchTable.jsx';
import GlobalSearchFilters from './GlobalSearchFilters.jsx';
import Navbar from '../NavBar/Navbar';
import API_URLS from '../apiCalls/apiUrls';

const GlobalSearchDashBoard = (props) => {
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;

    const [allData, setAllData] = useState([]); // Store all API data
    const [filteredData, setFilteredData] = useState([]); // Filtered data for display
    const [search, setSearch] = useState(""); // Search term
    const [City, SetCity] = useState([]);
    const [State, SetState] = useState([]);
    const [Status, SetStatus] = useState([]);
    const [Createdby, SetCreatedby] = useState([]);
    const columns = useMemo(() => [
        { field: 'OwnerName', headerName: 'Owner Name', width: 150, headerAlign: 'center' },
        { field: 'OwnerEmail', headerName: 'Owner Email', width: 200, headerAlign: 'center' },
        { field: 'EntityName', headerName: 'Entity Name', width: 160, headerAlign: 'center' },
        { field: 'EntityAddress', headerName: 'Entity Address', width: 200, headerAlign: 'center' },
        { field: 'ParkingLotAddress', headerName: 'ParkingLot Address', width: 200, headerAlign: 'center' },
        { field: 'Status', headerName: 'Status', width: 150, headerAlign: 'center' },
        { field: 'City', headerName: 'City', width: 150, headerAlign: 'center' },
        { field: 'State', headerName: 'State', width: 150, headerAlign: 'center' },
        { field: 'RegisterBy', headerName: 'Registered By', width: 174, headerAlign: 'center' },
    ], []);

    // Fetching data once on component mount
    useEffect(() => {
        const fetchProperties = async () => {
            try {
                const response = await fetch(
                    `${hostPath}${API_URLS.BASE_URL_GetSearchProperty}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "x-functions-key": hostHeader,
                        }
                    }
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                const filteredData = data.filter(
                    (item) => item.city !== "NA" || item.state !== "NA" || item.status !== "NA" || item.createdBy !== "NA"
                );
                // Map data to rows
                const mappedData = data.map((row, index) => ({
                    id: row.entityRegistrationID || `row-${index}`,
                    OwnerName: row.ownerName,
                    OwnerEmail: row.emailId,
                    EntityName: row.entityName,
                    EntityAddress: row.enityMailingAdderess,
                    ParkingLotAddress: row.parkingLotMailingAdderess,
                    Status: row.status,
                    City: row.city,
                    State: row.state,
                    RegisterBy: row.createdBy,
                }));

                setAllData(mappedData);
                setFilteredData(mappedData);
                SetCity([...new Set(filteredData.map((item) => item.city))]);
                SetState([...new Set(filteredData.map((item) => item.state))]);
                SetStatus([...new Set(filteredData.map((item) => item.status))]);
                //  SetCreatedby([...new Set(filteredData.map((item) => item.createdBy))]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchProperties();
    }, [hostPath]);

    // Handle search with debounce
    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            const result = allData?.filter((item) => {
                return (
                    (item.OwnerName)?.toLowerCase().includes(search.toLowerCase()) ||
                    (item.EntityName)?.toLowerCase().includes(search.toLowerCase()) ||
                    (item.EntityAddress)?.toLowerCase().includes(search.toLowerCase()) ||
                    (item.RegisterBy)?.toLowerCase().includes(search.toLowerCase()) ||
                    (item.ParkingLotAddress)?.toLowerCase().includes(search.toLowerCase())
                );
            });
            setFilteredData(result);
        }, 500);

        return () => clearTimeout(debounceTimeout);
    }, [search, allData]);

    const handleSearch = (searchValue) => {
        setSearch(searchValue);
    };
    useEffect(() => {
        console.log("filteredData:", filteredData);
    }, [filteredData]);


    const ALLSearch = (searchValue, City, State, Status) => {
        const result = allData.filter((item) => {
            const matchesSearchValue = searchValue
                ? (item.OwnerName || "")?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                (item.EntityName || "")?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                (item.EntityAddress || "")?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                (item.ParkingLotAddress || "")?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                (item.RegisterBy || "")?.toLowerCase().includes(searchValue?.toLowerCase())
                : true;

            const matchesCity = City ? (item.City || "")?.toLowerCase().includes(City?.toLowerCase()) : true;
            const matchesState = State ? (item.State || "")?.toLowerCase() === State?.toLowerCase() : true;
            const matchesStatus = Status ? (item.Status || "")?.toLowerCase().includes(Status?.toLowerCase()) : true;
            // const matchesRegisterBy = registerby ? (item.RegisterBy || "").toLowerCase().includes(registerby.toLowerCase()) : true;
            return matchesSearchValue && matchesCity && matchesState && matchesStatus;
        });
        setFilteredData(result);
    };
    return (
        <>
            <Navbar userData={props.userData} />
            <div className="container-fluid p-0 d-flex flex-column ">
                <div className="SearchFiltertab">
                    <GlobalSearchFilters
                        propertSearched={handleSearch}
                        City={City}
                        State={State}
                        Status={Status}
                        // Createdby={Createdby}
                        setALLSearch={ALLSearch}
                    />
                </div>
                <div className="row w-100 mr-5 d-flex justify-content-center">
                    <div className="col-md-12">
                        <form className="GlobalSearch-Table">
                            <GlobalSearchDataTable
                                properties={filteredData}
                                columns={columns}
                                getRowId={(row) => row.id}
                            />
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GlobalSearchDashBoard;
