export function getRecurrenceGroups(allEvents) {
  const recurringMap = new Map();

  allEvents.forEach((evt) => {
    // Only group events that have a recurrenceId
    if (!evt.recurrenceId) {
      return;
    }

    // If this recurrenceId isn't in the map yet, create a new group
    if (!recurringMap.has(evt.recurrenceId)) {
      recurringMap.set(evt.recurrenceId, {
        // Copy everything from this event
        ...evt,
        
        // Keep track of earliestStart and latestEnd
        earliestStart: evt.start,
        latestEnd: evt.end,
        
        // Keep an array of all occurrences
        occurrences: [evt],
      });
    } else {
      // We already have a group for this recurrenceId
      const existing = recurringMap.get(evt.recurrenceId);

      // Compare start times to see if this new event is earlier
      if (new Date(evt.start) < new Date(existing.earliestStart)) {
        // This event is the new earliest
        existing.earliestStart = evt.start;

        // Update the "representative" fields to match this earliest event:
        existing.start = evt.start;
        existing.end = evt.end;
        existing.title = evt.title;
        existing.recurrenceSummary = evt.recurrenceSummary;
        // ... copy other fields if needed
      }

      // Also check if this event extends the latestEnd
      if (new Date(evt.end) > new Date(existing.latestEnd)) {
        existing.latestEnd = evt.end;
      }

      // Push this event into the group's list of occurrences
      existing.occurrences.push(evt);

      // Optionally keep the 'grouped' object's .end field at the latest
      // so you can see the overall range if you want:
      existing.end = existing.latestEnd;

      // Finally, store the updated group back in the map
      recurringMap.set(evt.recurrenceId, existing);
    }
  });

  // Return all group objects as an array
  return Array.from(recurringMap.values());
}