import React, { useRef } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarComponent.css';
import CustomToolbar from './CustomToolbar';

const locales = {
  'en-US': require('date-fns/locale/en-US'),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const CalendarComponent = ({
  events,
  onSelectEvent,
  onAddEvent,
  selectedDate,
  onNavigate,
  components = {},
}) => {
  const calendarRef = useRef(null);


  const handleSelectSlot = ({ start }) => {
    const today = new Date();
    // Remove the time part for accurate comparison
    today.setHours(0, 0, 0, 0);
    const selectedDate = new Date(start);
    selectedDate.setHours(0, 0, 0, 0);

    if (selectedDate >= today) {
      onAddEvent(start);
    } else {
      // Optional: Show a message or simply ignore
      alert("Cannot schedule events in the past.");
    }
  };
  const        handleEventClick = (event) => {
    onSelectEvent(event);
  };


  const handleNavigate = (date, view, action) => {
    if (onNavigate) {
      onNavigate(date); // Update the selectedDate in the parent component
    }
  };

  const mergedComponents = {
    event: EventComponent, 
    // If parent passed a toolbar, use theirs; else fallback to your default
    toolbar: components.toolbar
      ? components.toolbar
      : (toolbarProps) => <CustomToolbar {...toolbarProps} />,
    ...components
  };


  return (
    <div className="calendar-wrapper">
      <div className="calendar-container">
        <Calendar
          popup={true}
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          selectable
          onSelectEvent={handleEventClick}
          onSelectSlot={handleSelectSlot}
          views={['month', 'week', 'day']}
          date={selectedDate}
          onNavigate={onNavigate}
          components={mergedComponents}
        />
      </div>
    </div>
  );
};

// CalendarComponent.jsx
const EventComponent = ({ event }) => {
  if (!event) {
    return null;
  }

  const isAllDay = event.allDay || event.AllDay;

  if (isAllDay) {
    const eventTitle = event.title || 'Closed All Day';
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
          height: '20px',
          lineHeight: '20px',
        }}
        title={eventTitle}
      >
        {eventTitle}
      </div>
    );
  } else {
    const startTime = event.start ? format(new Date(event.start), 'hh:mm aa') : 'N/A';
    const endTime = event.end ? format(new Date(event.end), 'hh:mm aa') : 'N/A';

    const timeRange = `${startTime} - ${endTime}`;

    const displayedTime =
      timeRange.length > 15
        ? `${timeRange.substring(0, 15)}...`
        : `${timeRange}`;

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
          height: '20px',
          lineHeight: '20px',
        }}
        title={`${startTime} - ${endTime}`}
      >
        {displayedTime}
      </div>
    );
  }
};






const ArrowLeftSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 19l-7-7 7-7"
      stroke="#333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ArrowRightSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 5l7 7-7 7"
      stroke="#333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CalendarComponent;