import React from 'react';
import { Card, Button, Typography, Grid } from '@mui/material';


const PropertyCard = ({ property }) => {
  return (
    <Card style={{ marginBottom: '20px', padding: '20px', borderRadius: '10px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
      <Grid container spacing={2} alignItems="center">
        {/* Property Image/Logo */}
        <Grid item xs={12} sm={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img
            src="https://via.placeholder.com/100" 
            alt="Parking Logo"
            style={{ width: '50px', height: '50px' }}
          />
        </Grid>

        {/* Property Info */}
        <Grid item xs={12} sm={1} style={{ textAlign: 'center' }}>
          <Typography variant="">Property ID</Typography>
          <Typography variant="h6" color="black">
            {property.parking_Lot_ID}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3} style={{ textAlign: 'center' }}>
          <Typography variant="">Parking Lot Address</Typography>
          <Typography variant="h6" color="black">
            {property.parkingLotAddress} 
          </Typography>
        </Grid>

        {/* Submitted Info */}
        <Grid item xs={12} sm={2} style={{ textAlign: 'center' }}>
          <Typography variant="">Submitted by</Typography>
          <Typography variant="h6" color="black">{property.emailId.split('.')[0]}</Typography>
        </Grid>

        <Grid item xs={12} sm={2} style={{ textAlign: 'center' }}>
          <Typography variant="">Submitted on</Typography>
          <Typography variant="h6" color="black">{property.createdDate}</Typography>
        </Grid>

        {/* Review Button */}
        <Grid item xs={12} sm={2} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button  variant="contained" color="primary" style={{ backgroundColor: '#0A66C2', color: '#fff' }} >  Review Property </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PropertyCard;
