// ScheduleSummaryPopup.jsx
import React from 'react';
import { FaWindowClose, FaEdit, FaTrash } from 'react-icons/fa';
import { format } from 'date-fns';
import { getRecurrenceGroups } from './helpers'; // import your grouping logic
import './SchedulerSummaryPopup.css';

function ScheduleSummaryPopup({ events = [], onClose, onEdit, onDelete }) {
  // 1) Group events: One row per recurrence + single events
  const grouped = getRecurrenceGroups(events);
  console.log('[ScheduleSummaryPopup] Grouped events:', grouped);

  // 2) Format functions
  const formatTimeRange = (evt) => {
    const start = new Date(evt.start);
    const end = new Date(evt.end);
    return `${format(start, 'p')} - ${format(end, 'p')}`;
  };

  const formatStartDate = (evt) => {
    return format(new Date(evt.start), 'PP p');
  };

  const formatEndDate = (evt) => {
    // If you stored earliestStart / latestEnd in your grouping logic, you might use them here
    if (evt.earliestStart && evt.latestEnd) {
      // Example: show entire range for the series
      return format(new Date(evt.latestEnd), 'PP p');
    }

    // Otherwise just show this event’s end
    return format(new Date(evt.end), 'PP p');
  };

  return (
    <div className="summary-popup-overlay">
      <div className="summary-popup-content">
        {/* Header */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2>All Unavailability / Events</h2>
          <button
            style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            onClick={onClose}
          >
            <FaWindowClose size={20} />
          </button>
        </div>

        {/* Body */}
        {grouped.length === 0 ? (
          <p>No events found.</p>
        ) : (
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={headerStyle}>Start Date</th>
                <th style={headerStyle}>End Date</th>
                <th style={headerStyle}>Time Closed</th>
                <th style={headerStyle}>Summary</th>
                <th style={headerStyle}>Edit</th>
                <th style={headerStyle}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {grouped.map((evt) => (
                <tr key={evt.recurrenceId || evt.id}>
                  {/* Start Date */}
                  <td style={cellStyle}>{formatStartDate(evt)}</td>

                  {/* End Date */}
                  <td style={cellStyle}>{formatEndDate(evt)}</td>

                  {/* Time Closed (time-only range) */}
                  <td style={cellStyle}>{formatTimeRange(evt)}</td>

                  {/* Summary */}
                  <td style={cellStyle}>
                    {evt.recurrenceId
                      ? (evt.recurrenceSummary || 'Recurring Event')
                      : 'No recurrence (Single)'
                    }
                  </td>

                  {/* Edit button */}
                  <td style={cellStyle}>
                    <button
                      onClick={() => onEdit(evt)}
                      style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                    >
                      <FaEdit />
                    </button>
                  </td>

                  {/* Delete button */}
                  <td style={cellStyle}>
                    <button
                      onClick={() => onDelete(evt)}
                      style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

// Table styling
const headerStyle = {
  border: '1px solid #ccc',
  padding: '8px',
  background: '#f2f2f2',
};
const cellStyle = {
  border: '1px solid #ccc',
  padding: '8px',
};

export default ScheduleSummaryPopup;