import React from 'react';
import { Card, CardContent, Button, Typography, Grid } from '@mui/material';
import PropertyCard from './PropertyCard'


const PropertyList = (props) => {
console.log("props.filter",props.filter)
  return (
    <div style={{ padding: '20px' }}>
      {props.filter.map((property, index) => (
        <PropertyCard key={index} property={property} />
      ))}
    </div>
  );
};

export default PropertyList;
