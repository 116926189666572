import React, { useEffect, useState, useRef } from 'react';
import { states } from './stateMappings.js';
import './FilterScreen.css';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import { FaGlobeAmericas, FaMapMarkerAlt, FaCity } from 'react-icons/fa';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Button, Typography } from '@mui/material';
import Navbar from '../NavBar/Navbar.jsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


const FilterScreen = () => {
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [totalSalesCount, setTotalSalesCount] = useState(0);
  const [stateSalesCounts, setStateSalesCounts] = useState([]);
  const [citySalesCounts, setCitySalesCounts] = useState([]);
  const [stateSalesCount, setStateSalesCount] = useState(0);
  const [citySalesCount, setCitySalesCount] = useState(0);
  const [error, setError] = useState('');
  const [loadingStates, setLoadingStates] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);

  const [selectedSalesperson, setSelectedSalesperson] = useState('');
  const [salespersons, setSalespersons] = useState([]);
  const [statusFilters, setStatusFilters] = useState({
    green: false,
    yellow: false,
    red: false,
  });
  const [additionalFilters, setAdditionalFilters] = useState({
    reit: false,
    gatedFenced: false,
    garage: false,
    fee: false,
  });

  const hostPath = process.env.REACT_APP_API_BASE_URL;
  const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;

  const API_BASE = `${hostPath}/api/crmSales`;

  const cities = [
    { name: 'San Francisco', stateAbbreviation: 'CA' },
    { name: 'Los Angeles', stateAbbreviation: 'CA' },
    { name: 'San Diego', stateAbbreviation: 'CA' },
    { name: 'Sacramento', stateAbbreviation: 'CA' },
    { name: 'San Jose', stateAbbreviation: 'CA' },
    { name: 'New York', stateAbbreviation: 'NY' },
    { name: 'Buffalo', stateAbbreviation: 'NY' },
    { name: 'Rochester', stateAbbreviation: 'NY' },
    { name: 'Albany', stateAbbreviation: 'NY' },
    { name: 'Syracuse', stateAbbreviation: 'NY' },
    { name: 'Chicago', stateAbbreviation: 'IL' },
    { name: 'Springfield', stateAbbreviation: 'IL' },
    { name: 'Naperville', stateAbbreviation: 'IL' },
    { name: 'Peoria', stateAbbreviation: 'IL' },
    { name: 'Rockford', stateAbbreviation: 'IL' },
  ];

  // State to track selected node in the tree
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);

  const formatNumber = (num) => num.toLocaleString();

  const fetchData = async (endpoint) => {
    const url = `${hostPath}/api/crmSales/locationAggregates`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'x-functions-key': hostHeader,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch ${endpoint}: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (err) {
      throw err;
    }
  };

  const mockData = [
    {
      key: 'USA',
      name: 'United States',
      salesCount: 84,
      children: [
        {
          key: 'CA',
          name: 'California',
          salesCount: 80,
          children: [
            {
              key: 'CA-SanFrancisco',
              name: 'San Francisco',
              isLeaf: true,
              salesCount: 80,
              stateAbbreviation: 'CA'
            }
          ]
        }
      ]
    }
  ];

  const transformLocationDataToTree = (locationData) => {
    // Group data by state and city
    const groupedByState = locationData.reduce((acc, item) => {
      if (!acc[item.State]) {
        acc[item.State] = { name: item.State, salesCount: 0, cities: [] };
      }
      acc[item.State].salesCount += item.Count;
      acc[item.State].cities.push(item);
      return acc;
    }, {});

    // Create tree structure
    const treeNodes = Object.keys(groupedByState).map((stateAbbreviation) => {
      const state = groupedByState[stateAbbreviation];
      const cityNodes = state.cities.map((city) => ({
        key: `${stateAbbreviation}-${city.City}`,
        name: city.City,
        isLeaf: true,
        salesCount: city.Count,
        stateAbbreviation,
      }));

      return {
        key: stateAbbreviation,
        name: states.find((s) => s.abbreviation === stateAbbreviation)?.name || stateAbbreviation,
        salesCount: state.salesCount,
        children: cityNodes,
      };
    });

    // Add USA as the root node
    return [
      {
        key: 'USA',
        name: 'United States',
        salesCount: locationData.reduce((sum, item) => sum + item.Count, 0),
        children: treeNodes,
      },
    ];
  };
  const fetchSalespersons = async () => {
    try {
      const response = await fetch(`${hostPath}/api/GetAllSales`, {
        method: 'GET',
        headers: {
          "x-functions-key": `${hostHeader}`,
          "Content-Type": "application/json"
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();

      const salesPersons = new Set();
      for (let i = 0; i < data.length; i++) {
        salesPersons.add(data[i].Salesperson);
      }

      const salesList = Array.from(salesPersons);
      setSalespersons(salesList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoadingStates(true);
      try {
        // Fetch all location aggregates in a single API call
        const locationData = await fetchData('locationAggregates');

        // Transform the data into a tree structure
        const transformedTree = transformLocationDataToTree(locationData);
        setTreeData(transformedTree);

        // Calculate total sales count from the fetched data
        const totalSales = locationData.reduce((sum, item) => sum + item.Count, 0);
        setTotalSalesCount(totalSales);

        // Populate the state and city sales counts
        const stateCounts = [];
        const cityCounts = [];
        locationData.forEach((item) => {
          const state = stateCounts.find((s) => s.abbreviation === item.State);
          if (state) {
            state.salesCount += item.Count;
          } else {
            stateCounts.push({
              name: states.find((s) => s.abbreviation === item.State)?.name || item.State,
              abbreviation: item.State,
              salesCount: item.Count,
            });
          }

          cityCounts.push({
            name: item.City,
            stateAbbreviation: item.State,
            salesCount: item.Count,
          });
        });

        setStateSalesCounts(stateCounts);
        setCitySalesCounts(cityCounts);

        // Fetch salespersons if necessary
        await fetchSalespersons();
      } catch (err) {
        console.error('Error fetching location data:', err);
        setError('Failed to fetch location sales data.');
      } finally {
        setLoadingStates(false);
      }
    };

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);






  // useEffect(() => {
  //   // Since we are mocking data, just set the treeData directly
  //   setLoadingStates(true);
  //   try {
  //     setTreeData(mockData);
  //     setTotalSalesCount(84); // United States total
  //   } catch (err) {
  //     setError('Failed to load mock data.');
  //   } finally {
  //     setLoadingStates(false);
  //   }
  // }, []);
  const handleToggle = (key) => {
    setExpandedKeys((prev) =>
      prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key]
    );
  };

  const handleSelectNode = (currentKey, node) => {
    if (selectedNodeKey === currentKey) {
      // If the node clicked is already selected, unselect it
      setSelectedNodeKey(null);
      setSelectedState('');
      setSelectedCity('');
      setStateSalesCount(0);
      setCitySalesCount(0);
      return;
    }

    // Otherwise, select the new node
    setSelectedNodeKey(currentKey);

    if (node.key === 'USA') {
      setSelectedState('');
      setSelectedCity('');
      setStateSalesCount(totalSalesCount);
      setCitySalesCount(0);
    } else if (node.isLeaf) {
      const cityName = node.name;
      const cityCount = node.salesCount;
      const stateAbbreviation = node.stateAbbreviation;

      setSelectedCity(cityName);
      setSelectedState(stateAbbreviation);
      setCitySalesCount(Number(cityCount));
      const stateObj = stateSalesCounts.find((s) => s.abbreviation === stateAbbreviation);
      setStateSalesCount(stateObj ? stateObj.salesCount : 0);
    } else {
      // State level node
      const stateAbbreviation = node.key;
      setSelectedState(stateAbbreviation);
      setSelectedCity('');
      setStateSalesCount(Number(node.salesCount));
      setCitySalesCount(0);
    }
  };

  const rowCounterRef = useRef(0);

  function flattenTreeData(nodes, expandedKeys, depth = 0, parentKey = '') {
    const result = [];
    for (const node of nodes) {
      const currentKey = parentKey ? `${parentKey}-${node.key}` : node.key;
      result.push({ node, depth, currentKey });
      if (node.children && expandedKeys.includes(currentKey)) {
        result.push(...flattenTreeData(node.children, expandedKeys, depth + 1, currentKey));
      }
    }
    return result;
  }

  const visibleNodes = flattenTreeData(treeData || [], expandedKeys);

  function getParentKey(currentKey) {
    const parts = currentKey.split('-');
    // The root node (e.g. 'USA') has no parent
    if (parts.length === 1) return null;
    return parts.slice(0, parts.length - 1).join('-');
  }

  function computeLineMap(visibleNodes) {
    // For quick lookups, group nodes by parentKey and depth
    const byParentDepth = {};

    visibleNodes.forEach(({ node, depth, currentKey }) => {
      const parentK = getParentKey(currentKey);
      const key = `${parentK}|${depth}`;
      if (!byParentDepth[key]) byParentDepth[key] = [];
      byParentDepth[key].push({ node, depth, currentKey });
    });

    // Now determine lineMap for each node
    return visibleNodes.map(({ node, depth, currentKey }, index) => {
      const parentK = getParentKey(currentKey);
      const key = `${parentK}|${depth}`;
      const siblings = byParentDepth[key];
      const idx = siblings.findIndex(s => s.currentKey === currentKey);
      const isLast = (idx === siblings.length - 1);

      // lineMap: for each level < depth, check if line continues
      // The line at this node's depth only continues if it's NOT the last child
      // Also consider higher levels - if parent's parent is not last child, line continues at that level too.
      // We'll need to track ancestry lines:
      const lineMap = [];
      // To determine if upper levels continue their lines, we must check ancestors
      // We'll walk up the parent chain and see if at any ancestor level, that ancestor is not the last child.

      function ancestorNotLast(key) {
        if (!key) return false;
        const pDepth = key.split('-').length - 1;
        const pParent = getParentKey(key);
        const pSibsKey = `${pParent}|${pDepth}`;
        const siblings = byParentDepth[pSibsKey] || [];
        const i = siblings.findIndex(s => s.currentKey === key);
        // If node not found or is last, no line continues
        return (i !== -1 && i < siblings.length - 1);
      }

      // Build lineage keys to determine line continuation at each level
      const parts = currentKey.split('-');
      // For each ancestor depth, check if that ancestor is not last to continue line
      for (let d = 0; d < depth; d++) {
        // Ancestor key at level d is parts[0..d]
        const ancKey = parts.slice(0, d + 1).join('-');
        lineMap[d] = ancestorNotLast(ancKey);
      }

      // Current depth line continues if not last child
      lineMap[depth] = !isLast;

      return { node, depth, currentKey, lineMap };
    });
  }

  const enrichedNodes = computeLineMap(visibleNodes);

  const handleStatusFilterChange = (event) => {
    const { name, checked } = event.target;
    setStatusFilters((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleAdditionalFilterChange = (event) => {
    const { name, checked } = event.target;
    setAdditionalFilters((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleSalespersonChange = (event) => {
    setSelectedSalesperson(event.target.value);
  };

  const generateFilterURL = () => {
    const baseURL = window.location.origin + '/salesinsights';
    const params = new URLSearchParams();

    if (selectedCity) {
      params.append('location', selectedCity.toLowerCase());
    } else if (selectedState) {
      const stateObj = states.find((state) => state.abbreviation === selectedState);
      const stateName = stateObj ? stateObj.name.toLowerCase() : '';
      if (stateName) {
        params.append('location', stateName);
      }
    } else {
      params.append('location', 'usa');
    }

    if (selectedSalesperson) {
      params.append('salesperson', selectedSalesperson);
    }

    params.append('green', statusFilters.green);
    params.append('yellow', statusFilters.yellow);
    params.append('red', statusFilters.red);

    params.append('reit', additionalFilters.reit);
    params.append('gatedFenced', additionalFilters.gatedFenced);
    params.append('garage', additionalFilters.garage);
    params.append('fee', additionalFilters.fee);

    const fullURL = `${baseURL}?${params.toString()}`;
    return fullURL;
  };

  const handleApplyFilters = () => {
    const url = generateFilterURL();
    window.open(url, '_blank');
  };

  rowCounterRef.current = 0;

  return (
    <div className="filter-screen-container">
      <Navbar />
      <div className="filter-layout">
        <div className="left-filters">
          <Typography variant="h6" className="filter-title">Filters</Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>Salesperson</InputLabel>
            <Select value={selectedSalesperson} onChange={handleSalespersonChange}>
              <MenuItem value=""><em>None</em></MenuItem>
              {salespersons.map((person) => (
                <MenuItem key={person} value={person}>{person}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography variant="subtitle1" className="filter-section-title">Status</Typography>
          <div className="checkbox-group">
            <FormControlLabel
              control={<Checkbox name="green" checked={statusFilters.green} onChange={handleStatusFilterChange} />}
              label="Green (Won)"
            />
            <FormControlLabel
              control={<Checkbox name="yellow" checked={statusFilters.yellow} onChange={handleStatusFilterChange} />}
              label="Yellow (In Progress)"
            />
            <FormControlLabel
              control={<Checkbox name="red" checked={statusFilters.red} onChange={handleStatusFilterChange} />}
              label="Red (Lost)"
            />
          </div>

          <Typography variant="subtitle1" className="filter-section-title">Other Filters</Typography>
          <div className="checkbox-group">
            <FormControlLabel
              control={<Checkbox name="reit" checked={additionalFilters.reit} onChange={handleAdditionalFilterChange} />}
              label="REIT"
            />
            <FormControlLabel
              control={<Checkbox name="gatedFenced" checked={additionalFilters.gatedFenced} onChange={handleAdditionalFilterChange} />}
              label="Gated/Fenced"
            />
            <FormControlLabel
              control={<Checkbox name="garage" checked={additionalFilters.garage} onChange={handleAdditionalFilterChange} />}
              label="Garage"
            />
            <FormControlLabel
              control={<Checkbox name="fee" checked={additionalFilters.fee} onChange={handleAdditionalFilterChange} />}
              label="Fee"
            />
          </div>

          <Button variant="contained" color="primary" fullWidth className="apply-button" onClick={handleApplyFilters}>
            Apply Filters
          </Button>
        </div>

        <div className="right-tree">
          <Typography variant="h6" className="filter-title">Locations</Typography>
          {loadingStates ? (
            <Typography>Loading data...</Typography>
          ) : treeData.length > 0 ? (
            <div className="custom-tree-container">
              {enrichedNodes.map(({ node, depth, currentKey, lineMap }) => {
                rowCounterRef.current += 1;
                const isEven = (rowCounterRef.current % 2 === 0);
                const rowClass = isEven ? 'row-even' : 'row-odd';
                const isExpanded = expandedKeys.includes(currentKey);
                const isSelected = selectedNodeKey === currentKey;
                const indentation = depth * 20;

                return (
                  <div key={currentKey} className={`custom-tree-node ${rowClass}`}>
                    <div
                      className={`tree-node-header ${isExpanded ? 'expanded' : ''} ${isSelected ? 'selected' : ''}`}
                      style={{ width: '100%', position: 'relative' }}
                      onClick={() => {
                        if (node.children) {
                          handleToggle(currentKey);
                        }
                        handleSelectNode(currentKey, node);
                      }}
                    >
                      {/* Render vertical lines at each ancestor level where lineMap[d] = true */}
                      {lineMap.map((continues, d) => {
                        if (!continues) return null;
                        return (
                          <span
                            key={d}
                            className="vertical-line"
                            style={{
                              position: 'absolute',
                              left: `${d * 20 + 12}px`,
                              top: 0,
                              bottom: 0,
                              width: '1px',
                              background: '#e0e0e0',
                              zIndex: 2,
                            }}
                          ></span>
                        );
                      })}

                      {node.children && (
                        <span className="tree-toggle-icon" style={{ paddingLeft: indentation }}>
                          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </span>
                      )}
                      {!node.children && (
                        <span style={{ display: 'inline-block', width: indentation }}></span>
                      )}
                      <span className="tree-node-title">
                        {node.name}
                        {node.salesCount !== undefined && (
                          <span className="tree-node-sales">{node.salesCount}</span>
                        )}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <Typography>No data available</Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterScreen;