import React, { useEffect, useState, useRef } from 'react';
import { BiSortAlt2 } from "react-icons/bi";
import { fetchUserDetailsByEmpId, deleteUserByEmail } from '../../Services/userService.js'
import { getLoggedInUser, fetchGetInternalEmployeeDetailsByEmailId } from '../../Services/employeeService.jsx';
import OwnerRegistartionCard from './OwnerRegistrationList.jsx'
import OwnerParkinglotRegistration from './OwnerParkinglotRegistration.jsx';
import './OwnerRegistration.css';
import { fetchParkinglotDetailsByEmpId } from '../../Services/parkinglotService'
import MapDashboard from '../myproporties/MapDashboard';

const OwnerDetailsComponent = (props) => {
  const [registerOwner, setRegisterOwner] = useState(false);
  const [userDetails, setUserdetails] = useState([]);
  const [filter, setFilter] = useState([]);
  const [user, setUser] = useState([]);
  const [search, setSearch] = useState("");
  const [sortDirection, setSortDirection] = useState('asc');
  const [parkingLotBookingDetails, setParkingLotBookingDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isEdit, setEdit] = useState();
  const [areasmarked, setAreasmarked] = useState([]);
  const [parkinglotObj, setParkinglotObj] = useState();
  const [defaultaddress, setDefaultaddress] = useState();
  const [editOption, setEditoption] = useState();
  const [salespersonloggedin, setSalespersonloggedin] = useState();
  const handleRegsiterOwner = (val) => {
    props.RegsiterOwner()
    setRegisterOwner(val);
  }
  const handleSetAreasMarked = (newAreas) => {
    setAreasmarked(newAreas);
  };

  const handleSetParkingLotObj = (newParkingLot) => {
    setParkinglotObj(newParkingLot);
  };

  const handleSetDefaultAddress = (newAddress) => {
    setDefaultaddress(newAddress);
  };

  const handleSetEditOption = (newEditOption) => {
    setEditoption(newEditOption);
  };
  const handleSetEdit = (val) => {
    setEdit(val);
  };
  const EmployeeId = getLoggedInUser('empId');
  const stateLogedInUser = getLoggedInUser('companyEmail');
  const fetchVerifiedBy = async () => {
    try {
      const verifiedby = await fetchGetInternalEmployeeDetailsByEmailId(stateLogedInUser);
      setSalespersonloggedin(verifiedby?.firstName);
    } catch (error) {
      console.error("Error fetching parking lot details:", error);
    }
  };
  useEffect(() => {
    fetchVerifiedBy();
  }, [stateLogedInUser])
  return (
    <div>
      <div>
        {!isEdit ? (
          <div className='container-fluid p-0'>
            <div className="lotmanagement">
              <div className="row alignLeft">
                <div className="col-7">
                  <div className="viewHeader">
                    <button
                      className="backButton"
                      onClick={props.handleSelectedowner}
                      style={{
                        marginRight: '10px',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer'
                      }}
                    >
                      <i className="fas fa-arrow-left"></i>
                    </button>
                    Manage Owners & Properties
                  </div>
                </div>

                <div className="col-3">
                  <input
                    type='text'
                    className="form-control"
                    placeholder='Search by address.'
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div className="col-2 a alignEnd">
                  <button
                    className="add-property-btn"
                    onClick={() => handleRegsiterOwner(true)}
                  >
                    Register Property Owner
                  </button>
                </div>
              </div>
              <div className="row alignLeft">
                <div className="col-12 mb-2">
                  Total Registered Owners: {userDetails.length}
                </div>
              </div>
              <OwnerRegistartionCard
                ownerName={props.selectedRow.firstName}
                entityName={props.selectedRow.entityName}
                email={props.selectedRow.emailId}
                entityId={props.selectedRow.entityRegistrationID}
                mobilePhone={props.selectedRow.mobileNumber}
                entityAddress={props.selectedRow.enityMailingAdderess}
                handleSelectedowner={props.handleSelectedowner}
                propertyOwner={props.propertyOwner}
                handleEdit={props.handleEdit}
                handleDelete={props.handleDelete}
                handleRegsiterOwner={props.handleRegsiterOwner}
                property={props.selectedRow}
              />
              <OwnerParkinglotRegistration
                entityId={props.selectedRow.entityRegistrationID}
                onSetAreasMarked={handleSetAreasMarked}
                onSetParkingLotObj={handleSetParkingLotObj}
                onSetDefaultAddress={handleSetDefaultAddress}
                onSetEditOption={handleSetEditOption}
                handleSetEdit={handleSetEdit}
                OwnerDetails={props.selectedRow}
                handleEdit={props.handleEdit}
              />
            </div>
          </div>

        ) : (
          <div><MapDashboard isEdit={isEdit} areasMarked={areasmarked} defaultaddress={defaultaddress} parkinglotObj={parkinglotObj} editOption={editOption} empId={EmployeeId}
            verifiedBy={salespersonloggedin} userData={props.selectedRow}
          /></div>
        )}
      </div>
    </div>
  );
};

export default OwnerDetailsComponent;
