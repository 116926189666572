import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import './Navbar.css';
import logo from '../../Images/GeoParkingLogo.png'; // Adjust the path as necessary
import LogoutIcon from '@mui/icons-material/Logout';

import Tooltip from '@mui/material/Tooltip';

const Navbar = ({ mapvisible, userData, mapVisibleChanges }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [showPopup1, setShowPopup1] = useState(false);
    const SESSION_TIMEOUT = 20 * 60 * 1000; // 20 minutes
    const WARNING_TIME = 60 * 1000; // Show popup for the last 60 seconds
    const [showPopup, setShowPopup] = useState(false);
    const [countdown, setCountdown] = useState(60);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);
    const [initial, setInitials] = useState(JSON.parse(localStorage.getItem('initials')));
    const [internalEmployeeEmail, setInternalEmployeeEmail] = useState('');
    const envname = process.env.REACT_APP_ENV_TXT || null;
    const activityTimeoutRef = useRef(null);
    const countdownIntervalRef = useRef(null);
    const [tooltip, setTooltip] = useState({ show: false, text: "", position: { top: 0, left: 0 } });

    useEffect(() => {
        const resetActivityTimeout = () => {
            clearTimeout(activityTimeoutRef.current);
            activityTimeoutRef.current = setTimeout(() => {
                setShowPopup(true);
                startCountdown();
            }, SESSION_TIMEOUT - WARNING_TIME);
        };
        const activityEvents = ['mousemove', 'keydown', 'click', 'scroll'];
        activityEvents.forEach(event =>
            window.addEventListener(event, resetActivityTimeout)
        );
        resetActivityTimeout();
        return () => {
            clearTimeout(activityTimeoutRef.current);
            clearInterval(countdownIntervalRef.current);
            activityEvents.forEach(event =>
                window.removeEventListener(event, resetActivityTimeout)
            );
        };
    }, []);

    const startCountdown = () => {
        countdownIntervalRef.current = setInterval(() => {
            setCountdown(prevCount => {
                const nextCount = prevCount - 1;
                if (nextCount === 0) {
                    clearInterval(countdownIntervalRef.current);
                    handleLogout();
                }
                return nextCount;
            });
        }, 1000);
    };

    const handleLogout = () => {
        setShowPopup(false);
        localStorage.clear();
        navigate('/login');
    };

    const handleStayLoggedIn = () => {
        setShowPopup(false);
        setCountdown(60);
        clearTimeout(activityTimeoutRef.current);
        clearInterval(countdownIntervalRef.current);
        activityTimeoutRef.current = setTimeout(() => {
            setShowPopup(true);
            startCountdown();
        }, SESSION_TIMEOUT - WARNING_TIME);
    };

    useEffect(() => {
        const storedEmail = JSON.parse(localStorage.getItem('companyEmail'));
        setInternalEmployeeEmail(storedEmail);
    }, []);

    const handleMyPropertiesClick = (e) => {
        if (mapvisible) {
            e.preventDefault();
            setShowPopup1(true);
        } else {
            navigate('/viewparkinginfo');
        }
    };

    const handleMouseEnter = (event, text, tabName) => {
        const currentTab = location.pathname;
        if (mapVisibleChanges && currentTab != tabName) {
            const rect = event.target.getBoundingClientRect();
            setTooltip({
                show: true,
                text: text,
                position: {
                    top: rect.bottom + window.scrollY + 6,
                    left: rect.left + rect.width / 2,
                },
            });
        }
    };
    const handleMouseLeave = () => {
        setTooltip({ show: false, text: "", position: { top: 0, left: 0 } });
    };

    const handleConfirmNavigate = () => {
        setShowPopup1(false);
        navigate('/viewparkinginfo');
    };

    const handleCancelNavigate = () => {
        setShowPopup1(false);
    };

    return (
        <nav className="navbar">
            {/* Navbar logo and links */}
            <div className="navbar-logo">
                <img src={logo} alt="Logo" />
            </div>
            {envname && <span className="restricted-badge-navbar">{envname}</span>}
            <div className="navbar-links">
                <NavLink
                    to="/dashboard"
                    className={({ isActive }) => (isActive ? 'selected' : '')}
                    onMouseEnter={(e) => handleMouseEnter(e, "ALERT:Switching to another tabb will cause you to lose any unsaved progress", "/dashboard")}
                    onMouseLeave={handleMouseLeave}
                >
                    Dashboard
                </NavLink>
                {!internalEmployeeEmail?.includes('@geoparking.com') ? (
                    <>
                        <NavLink
                            to="/viewparkinginfo"
                            className={({ isActive }) => (isActive ? 'selected' : '')}
                            onClick={handleMyPropertiesClick}
                        >
                            My Properties
                        </NavLink>
                        <NavLink to="/earnings" className={({ isActive }) => (isActive ? 'selected' : '')}>
                            Earnings
                        </NavLink>
                    </>
                ) : (
                    <>
                        <NavLink
                            to="/reviewtab"
                            className={({ isActive }) => (isActive ? 'selected' : '')}
                            onMouseEnter={(e) => handleMouseEnter(e, "ALERT:Switching to another tabb will cause you to lose any unsaved progress", "/reviewtab")}
                            onMouseLeave={handleMouseLeave}
                        >
                            Pending Approval
                        </NavLink>
                        <NavLink
                            to="/OwnerRegistrationDashboard"
                            className={({ isActive }) => (isActive ? 'selected' : '')}
                            onMouseEnter={(e) => handleMouseEnter(e, "ALERT:Switching to another tabb will cause you to lose any unsaved progress", "/OwnerRegistrationDashboard")}
                            onMouseLeave={handleMouseLeave}
                        >
                            Owner & Property Management
                        </NavLink>
                        <NavLink
                            to="/GlobalSearchDashBoard"
                            className={({ isActive }) => (isActive ? 'selected' : '')}
                            onMouseEnter={(e) => handleMouseEnter(e, "ALERT:Switching to another tabb will cause you to lose any unsaved progress", "/GlobalSearchDashBoard")}
                            onMouseLeave={handleMouseLeave}
                        >
                            Global Search
                        </NavLink>
                        <NavLink to="/salesinsights" className={({ isActive }) => (isActive ? 'selected' : '')}
                            onMouseEnter={(e) => handleMouseEnter(e, "ALERT:Switching to another tabb will cause you to lose any unsaved progress", "/salesinsights")}
                            onMouseLeave={handleMouseLeave}
                        >
                            Sales Insights
                        </NavLink>
                        {tooltip.show && (
                            <div
                                className="tooltip-custom"
                                style={{
                                    top: tooltip.position.top,
                                    left: tooltip.position.left,
                                    transform: "translateX(-50%)",
                                }}
                            >
                                {tooltip.text}
                            </div>
                        )}
                    </>
                )}
            </div>

            <div className="navbar-profile" onClick={toggleDropdown}>
                <div className="profile-icon">{initial}</div>
                {isDropdownVisible && (
                    <div className="dropdown-menu">
                        <ul>
                            {/* Show additional options only for non-internal employees */}
                            {internalEmployeeEmail?.includes('@geoparking.com') ? (
                                <>
                                    <li>
                                    <a href="/Home" onClick={handleLogout}>Logout</a>
                                    </li>
                                    <li>
                                        <a href="/settings/pricing" onClick={() => window.location.href = '/settings/pricing'}>
                                            Admin Settings 
                                        </a>
                                    </li>
                                </>

                            ) : (
                                <>
                                    <li>
                                        <a href="/settings/entity" onClick={() => window.location.href = '/settings/entity'}>
                                            Entity Information
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/settings/profile" onClick={() => window.location.href = '/settings/profile'}>
                                            Profile Information
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/settings/password" onClick={() => window.location.href = '/settings/change-password'}>
                                            Change Password
                                        </a>
                                    </li>
                                    <li>
                                    <a href="/Home" onClick={handleLogout}>Logout</a>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                )}
            </div>
            {showPopup1 && (
                <div className="custom-popup">
                    <div className="popup-text">
                        <p>All unsaved progress will be lost. Are you sure you want to continue?</p>
                        <div className="popup-buttons">
                            <button className="confirm-btn" onClick={handleConfirmNavigate}>
                                Continue
                            </button>
                            <button className="cancel-btn" onClick={handleCancelNavigate}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
              {showPopup && (
                <div className="custom-popup">
                    <div className="popup-text">
                        <p>Are you still there? You will be logged out in {countdown} seconds.</p>
                        <div className="popup-buttons">
                            <button className="confirm-btn" onClick={handleStayLoggedIn}>
                                Stay Logged In
                            </button>
                            <button className="cancel-btn" onClick={handleLogout}>
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
