import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { format, isAfter } from 'date-fns';

const HolidayPopup = ({
  open,
  onClose,
  onHolidaySelect,
}) => {
  const [holidays, setHolidays] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFetched, setHasFetched] = useState(false); // Tracks if we’ve already fetched once
  const today = new Date();

  // Example: If you have an environment variable for the base URL:
  const hostPath = process.env.REACT_APP_API_BASE_URL;    
  const hostHeader = process.env.REACT_APP_CUSTOM_HEADER; 

  useEffect(() => {
    if (!open) {
     
      return;
    }

    // Only fetch if we haven't fetched once before
    if (!hasFetched) {
      const fetchHolidays = async () => {
       
        setIsLoading(true);

        try {
          const endpoint = `${hostPath}/api/GetAllHolidays`;
          

          const response = await fetch(endpoint, {
            method: 'GET',
            headers: {
              'x-functions-key': hostHeader,
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error(`Failed to fetch holiday data: ${response.status} ${response.statusText}`);
          }

          const data = await response.json();

          // Convert each holiday to include a real Date
          const mapped = data.map((h) => {
            console.log(`Holiday record: Name=${h.Name}, Year=${h.Year}, Month=${h.Month}, Day=${h.Day}`);
            const dateObj = new Date(h.Year, h.Month - 1, h.Day);
            return {
              ...h,
              dateObj,
            };
          });

          setHolidays(mapped);
          setHasFetched(true);
        } catch (error) {
          console.error('HolidayPopup: Error fetching holidays:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchHolidays();
    }

  // We only re-run the effect if the dialog *opens*, 
  // but has not fetched data before. Otherwise we skip.
  }, [open, hasFetched, hostPath, hostHeader]);

  // Filter out any holidays in the past
  const futureHolidays = holidays.filter((holiday) => {
    const isFuture = isAfter(holiday.dateObj, today);
    console.log(
      `Filtering ${holiday.Name} (${holiday.Year}-${holiday.Month}-${holiday.Day}). isFuture?`,
      isFuture
    );
    return isFuture;
  });


  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ fontWeight: 'bold' }}>
        Upcoming U.S. Holidays
      </DialogTitle>

      <DialogContent dividers>
        {/* 1) If we're loading, show a loading message */}
        {isLoading ? (
          <Typography variant="body1">Loading Holidays...</Typography>
        ) : (
          /* 2) If not loading and no future holidays, show "No upcoming holidays" */
          futureHolidays.length === 0 ? (
            <Typography variant="body1">No upcoming holidays found!</Typography>
          ) : (
            /* 3) Otherwise, render the holiday list */
            futureHolidays.map((holiday) => {
              const formattedDate = format(holiday.dateObj, 'MMMM d, yyyy');
              return (
                <div
                  key={holiday.id || holiday.Name}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <Typography variant="body1">
                    <strong>{holiday.Name}</strong> — {formattedDate}
                  </Typography>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      const holidayDateStr = format(holiday.dateObj, 'yyyy-MM-dd');
                      onHolidaySelect({
                        name: holiday.Name,
                        date: holidayDateStr,
                      });
                      onClose();
                    }}
                  >
                    Schedule
                  </Button>
                </div>
              );
            })
          )
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HolidayPopup;