import { useState, useCallback, useEffect, useRef } from 'react';
import Frame1 from "./Frame1.jsx";
import PortalPopup from "./PortalPopup.jsx";
import atlas from 'azure-maps-control';
import Frame from "./Frame.jsx";
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Scheduler from '../calendar/Scheduler.jsx';
import BankDetailsMain from '../bankDetails/bankDetailsMain.jsx';
import ReviewAndConfirm from '../reviewConfirm/reviewandConfirm.jsx';
import { GrFormSearch } from "react-icons/gr";
import MarkingAlert from '../NavBar/MarkingAlert.jsx';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { addDays, addWeeks, addMonths, format } from "date-fns";
import FadeLoader from "react-spinners/FadeLoader";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { useNavigate } from "react-router-dom";
//import ScheduleAvailability from '../scheduleAvailability/scheduleAvailability.jsx';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MapDashboard = (props) => {
	const { overrides, ...rest } = props;
	const mapRef = useRef(null);
	const markerEnabled = useRef(false);
	let map;
	const [expanded, setExpanded] = useState(false);
	const hostPath = process.env.REACT_APP_API_BASE_URL;
	const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
	const [isFrameOpen, setFrameOpen] = useState(true);
	const [isFrame1Open, setFrame1Open] = useState(false);
	const [isAddressSelected, setConfirmAddress] = useState(false)
	const [isScheduleAvailabilityPopupConfirm, setConfirmforScheduleAvailability] = useState(true)
	const [searchValue, setSearchValue] = useState('');
	const [suggestions, setSuggestions] = useState([]);
	const [selecetedAddress, setSelecetedAddress] = useState([]);
	const [searchError, setSearchError] = useState(false);
	const [issearchdisabled, setSearchdisabled] = useState(false);
	const [addressvalue, setAddressvalue] = useState('');
	const [selectedOption, setSelectedOption] = useState([]);
	const [currentPropertyaddress, setCurrentpropertyaddress] = useState();
	const [areasMarked, setareasmarked] = useState(props.areasMarked || []);
	const [scheduleavailability, setscheduleavailability] = useState(false);
	const [selectedValue, setSelectedValue] = useState(''); // Set default to Custom
	const [bankaccountdetails, setbankaccountdetails] = useState({});
	const [alertpopup, setAlertpopup] = useState(false);
	const [optionerror, setOptionerror] = useState(false);
	const [expandedMark, setExpandedMark] = useState(true)
	const [exitAlert, setExitAlert] = useState(false);
	const [weekoption, setWeekoption] = useState();
	const [streetname, setStreetname] = useState();
	const [currentProperty, setCurrentproperty] = useState();
	const [isDashboard, setDashboard] = useState(false)
	const [parkinglotId, setParkinglotId] = useState();
	const [parkinglotregistrationId, setParkinglotregistrationId] = useState();
	const [loader, setLoader] = useState(false);
	const [entity, setEntity] = useState(props.entityId || props.parkinglotObj?.entityRegistrationID)
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedMarkOption, setMarkOption] = useState(false)
	const [onlyPark, setOnlyPark] = useState(false);
	const [showPickUPSDropoffs, setShowPickUPSDropoffs] = useState(false);
	const [showParkOnly, setshowParkOnly] = useState(true)
	const [Shownextbtn, setShownextbtn] = useState(false)
	const [EventTriggered, SetEventTriggered] = useState(false)
	const [SelectedBankDetailID, setSelectedBankDetailID] = useState(0)
	const steps = [
		"1 of 6 - Locate your Property",
		"2 of 6 - Mark Parking Spaces",
		"3 of 6 - Pick ups and  Drop offs",
		"4 of 6 - Schedule Availability",
		"5 of 6 - Enter Bank Details",
		"6 of 6 - Review and Confirm"
	];
	const stepParkOnly = [
		"1 of 5 - Locate your Property",
		"2 of 5 - Mark Parking Spaces",
		"3 of 5 - Schedule Availability",
		"4 of 5 - Enter Bank Details",
		"5 of 5 - Review and Confirm"
	];
	const [areas, setAreas] = useState({
		"PSL - Parking Space Location": 0,
		"PKE - Parking Entrance": 0,
		"PKX - Parking Exit": 0,
	});
	const [PickUPSDropoffs, setPickUPSDropoffs] = useState({
		"PUE - Pick Up Entrance": 0,
		"DOE - Drop Off Entrance": 0,
		"PUL - Pick Up Location": 0,
		"DOL - Drop Off Location": 0,
		"PUX - Pick Up Exit": 0,
		"DOX - Drop Off Exit": 0
	});
	const selectOption = (event) => {
		const { name, checked } = event.target;

		if (checked) {
			setMarkOption(name);
			setOnlyPark(true);
			setStepParkOnly({
				"1 of 5 - Locate your Property": false,
				"2 of 5 - Mark Parking Spaces": true,
				"3 of 5 - Schedule Availability": false,
				"4 of 5 - Enter Bank Details": false,
				"5 of 5 - Review and Confirm": false
			});
			setPickUPSDropoffs({
				"PUE - Pick Up Entrance": 0,
				"DOE - Drop Off Entrance": 0,
				"PUL - Pick Up Location": 0,
				"DOL - Drop Off Location": 0,
				"PUX - Pick Up Exit": 0,
				"DOX - Drop Off Exit": 0
			});
			const updateAreas = areasMarked?.filter((name) => (name.Name === "PSL - Parking Space Location") || (name.Name === "PKE - Parking Entrance") || (name.Name === "PKX - Parking Exit"))
			setareasmarked(updateAreas)
		} else {
			setPickUPSDropoffs({
				"PUE - Pick Up Entrance": 0,
				"DOE - Drop Off Entrance": 0,
				"PUL - Pick Up Location": 0,
				"DOL - Drop Off Location": 0,
				"PUX - Pick Up Exit": 0,
				"DOX - Drop Off Exit": 0
			});
			setMarkOption('');
			setOnlyPark(false);
		}
	};
	// MapDashboard.jsx
	const parseDate = (date) => {
		if (!date) return null;
		let parsedDate;
		if (typeof date === 'number') {
			parsedDate = new Date(date);
		} else if (typeof date === 'string') {
			if (/^\d+$/.test(date)) {
				// Numeric string, parse as integer
				parsedDate = new Date(parseInt(date, 10));
			} else {
				// Try parsing as date string
				parsedDate = new Date(date);
			}
		} else if (date instanceof Date) {
			parsedDate = date;
		} else {
			return null;
		}
		if (isNaN(parsedDate.getTime())) {
			return null;
		}
		return parsedDate;
	};
	const [events, setEvents] = useState(props?.parkinglotObj?.scheduleavailability || []);
	const logEvents = () => {
		console.log('Events in MapDashboard at the time of edit:', events);
	};
	let mapMarkers = useRef([]);
	let currentMarker = useRef();
	const [timeValues, setTimeValues] = useState(props?.parkinglotObj?.scheduleavailability?.timeSlot || {
		Monday: "",
		Tuesday: "",
		Wednesday: "",
		Thursday: "",
		Friday: "",
		Saturday: "",
		Sunday: "",
	});

	const [markingalert, setMarkingalert] = useState(false);
	const [conditions1, setStepParkOnly] = useState({
		"1 of 5 - Locate your Property": true,
		"2 of 5 - Mark Parking Spaces": false,
		"3 of 5 - Schedule Availability": false,
		"4 of 5 - Enter Bank Details": false,
		"5 of 5 - Review and Confirm": false,
	});
	const [conditions, setConditions] = useState({
		"1 of 6 - Locate your Property": true,
		"2 of 6 - Mark Parking Spaces": false,
		"3 of 6 - Pick ups and  Drop offs": false,
		"4 of 6 - Schedule Availability": false,
		"5 of 6 - Enter Bank Details": false,
		"6 of 6 - Review and Confirm": false,
	});
	const [latitude, setLatitude] = useState(null);
	const [longitude, setLongitude] = useState(null);
	const [enableMarker, setEnablemarker] = useState(false);
	const [bankdetails, setBankdetails] = useState(false);
	const [reviewlocation, setReviewlocation] = useState(false);
	const [errors, setErrors] = useState({});
	const [errorsbank, setbankErrors] = useState({});
	const navigate = useNavigate();
	const [isautoSave, setAutosave] = useState(false);
	const [accountinfo, setAccountinfo] = useState({
		BankName: bankaccountdetails ? bankaccountdetails.bankName : "",
		AccountHolderName: bankaccountdetails ? bankaccountdetails.accountHolderName : "",
		AccountType: bankaccountdetails ? bankaccountdetails.accountType : "",
		AccountNumber: bankaccountdetails ? bankaccountdetails.accountNumber : "",
		RoutingNumber: bankaccountdetails ? bankaccountdetails.routingNumber : "",
		SWIFT_BIC_Code: bankaccountdetails ? bankaccountdetails.swifT_BIC_Code : "",
	});
	const handleDatePickerChange = (date) => {
		setSelectedDate(date);
	};
	const lastSavedStateRef = useRef({
		addressvalue: '',
		areasMarked: props.areasMarked || [],
		timeValues: props?.parkinglotObj?.scheduleavailability?.timeSlot || timeValues,
		accountinfo: { ...accountinfo }
	});
	// Condition to disable the Save button if nothing has changed since the last save
	const isSaveDisabled =
		(addressvalue.length === 0 && areasMarked.length === 0) || (events?.length === 0 && scheduleavailability) ||
		(addressvalue === lastSavedStateRef?.current?.addressvalue &&
			JSON.stringify(areasMarked) === JSON.stringify(lastSavedStateRef.current.areasMarked)
			&& JSON.stringify(accountinfo) === JSON.stringify(lastSavedStateRef.current.accountinfo)
			&& JSON.stringify(timeValues) === JSON.stringify(lastSavedStateRef.current.timeValues)
		);

	// Calculate if any value in areas is above 100
	const isAnyValueAbove100 = Object.values(areas).some(value => value >= 100);
	const minWidthStyle = isAnyValueAbove100 ? '30px' : '20px';

	function getLoggedInUser() {
		const loggedInUser = localStorage.getItem('LogedInUser');
		if (!loggedInUser) {
			console.error('No LoggedInUser found');
			return null;
		}
		try {
			const stateLoggedInUser = JSON.parse(loggedInUser);
			return stateLoggedInUser;
		} catch (error) {
			console.error('Error parsing LoggedInUser', error);
			return null;
		}
	}
	const stateLogedInUser = props?.userData?.emailId;
	const entityRegistrationId = props?.userData?.entityRegistrationID;
	const accordionStyles = {
		marginTop: "14px",
		marginLeft: !scheduleavailability ? '0px' : '20px',
		flexGrow: !scheduleavailability ? 0 : 1
	};
	const mapStyles = {
		display: !scheduleavailability ? '' : 'flex'
	};
	const validateTimeValues = () => {
		const newErrors = {};
		switch (selectedValue) {
			case 'WeekDays':
				['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].forEach(day => {
					if (!timeValues[day]) newErrors[day] = `${day} is required.`;
				});
				break;
			case 'WeekEnd':
				['Saturday', 'Sunday'].forEach(day => {
					if (!timeValues[day]) newErrors[day] = `${day} is required.`;
				});
				break;
			case 'Custom':
				if (!Object.values(timeValues).some(value => value)) {
					newErrors.general = "At least one day must have a value.";
				}
				break;
			case 'WholeWeek':
				Object.keys(timeValues).forEach(day => {
					if (!timeValues[day]) newErrors[day] = `${day} is required.`;
				});
				break;
			default:
				break;
		}
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};
	const handleSelectedBankDetailID = (bankDetailID) => {
		setSelectedBankDetailID(bankDetailID);
	};


	const handleInputChange = (account) => (e) => {
		const { name, value } = e.target;
		setAccountinfo({ ...accountinfo, [account]: value });
		const validateField = (name, value, regex, emptyMessage, invalidMessage) => {
			if (value.trim() === "") {
				setbankErrors(errors => ({ ...errors, [name]: emptyMessage }));
			} else if (regex && !regex.test(value)) {
				setbankErrors(errors => ({ ...errors, [name]: invalidMessage }));
			} else {
				setbankErrors(errors => ({ ...errors, [name]: "" }));
			}
		};
		switch (name) {
			case 'BankName':
				validateField(name, value, /^[a-zA-Z\s]*$/, '', 'Please enter alphabets only');
				break;
			case 'AccountHolderName':
				validateField(name, value, /^[a-zA-Z\s]*$/, '', 'Please enter alphabets only');
				break;
			case 'AccountType':
				validateField(name, value, /^[A-Za-z\s()]+$/, '', 'Please check the Account Type format');
				break;
			case 'AccountNumber':
				validateField(name, value, /^\d{8,12}$/, '', 'Account Number must be between 8 and 12 digits');
				break;
			case 'RoutingNumber':
				validateField(name, value, /^\d{9}$/, '', 'Routing Number must be exactly 9 digits');
				break;
			case 'SWIFT_BIC_Code':
				// Add validation if needed
				break;
			default:
				break;
		}
	};
	const handleCancelalert = (val) => {
		setMarkingalert(val);
	};
	const resetStates = () => {
		setExpanded(false);
		setFrame1Open(false);
		setConfirmAddress(false);
		setConfirmforScheduleAvailability(true);
		setSearchValue('');
		setSuggestions([]);
		setSearchError(false);
		setSearchdisabled(false);
		setAddressvalue('');
		setSelectedOption([]);
		setareasmarked([]);
		setscheduleavailability(false);
		setSelectedValue('');
		setAlertpopup(false);
		setOptionerror(false);
		setOnlyPark(false);
		setMarkOption(false);
		setTimeValues({
			Monday: "", Tuesday: "", Wednesday: "",
			Thursday: "", Friday: "", Saturday: "", Sunday: "",
		});
		setConditions({
			"1 of 6 - Locate your Property": true,
			"2 of 6 - Mark Parking Spaces": false,
			"3 of 6 - Pick ups and  Drop offs": false,
			"4 of 6 - Schedule Availability": false,
			"5 of 6 - Enter Bank Details": false,
			"6 of 6 - Review and Confirm": false,
		});
		setStepParkOnly({
			"1 of 5 - Locate your Property": true,
			"2 of 5 - Mark Parking Spaces": false,
			"3 of 5 - Schedule Availability": false,
			"4 of 5 - Enter Bank Details": false,
			"5 of 5 - Review and Confirm": false,
		})
		setEnablemarker(false);
		setBankdetails(false);
		setReviewlocation(false);
		setErrors({});
		setbankErrors({});
		setMarkingalert(false);
		searchLocation(props.defaultaddress);
		setAreas({
			"PSL - Parking Space Location": 0,
			"PKE - Parking Entrance": 0,
			"PKX - Parking Exit": 0,
		});
		setShowPickUPSDropoffs(false);
		setshowParkOnly(true);
		setShownextbtn(false);
		setPickUPSDropoffs({
			"PUE - Pick Up Entrance": 0,
			"DOE - Drop Off Entrance": 0,
			"PUL - Pick Up Location": 0,
			"DOL - Drop Off Location": 0,
			"PUX - Pick Up Exit": 0,
			"DOX - Drop Off Exit": 0
		});
		let mapElement = document.getElementById('myMap');
		if ((mapElement)) {
			map = new atlas.Map('myMap', {
				center: [longitude || -122.18402494821507, latitude || 47.61373420362662],
				zoom: 18,
				language: 'en-US',
				style: 'satellite',
				authOptions: {
					authType: 'subscriptionKey',
					subscriptionKey: 'EN5vcaicwC78zKi3M3980VVB1m7DuuN0vKaDMtYdjBg7daKhx3OTJQQJ99AHAC8vTIngVrqvAAAgAZMPLtXG'
				}
			});
			mapRef.current = map;
		}

	};
	const validateFields = () => {
		let tempErrors = {};
		let isValid = true;
		const validateField = (field, value, regex, emptyMessage, invalidMessage) => {
			// if (value?.trim() === "" || value === null || value === undefined) {
			// 	tempErrors[field] = emptyMessage;
			// 	isValid = false;
			// } else 
			if (regex && !regex.test(value) && value?.length > 0) {
				tempErrors[field] = invalidMessage;
				isValid = false;
			}
		};
		validateField('BankName', accountinfo.BankName, /^[A-Za-z\s]+$/, 'Bank Name is required', 'Please enter alphabets only');
		validateField('AccountHolderName', accountinfo.AccountHolderName, /^[A-Za-z\s]+$/, 'Account Holder Name is required', 'Please enter alphabets only');
		validateField('AccountNumber', accountinfo.AccountNumber, /^\d{8,12}$/, 'Account Number is required', 'Account Number must be between 8 and 12 digits');
		validateField('RoutingNumber', accountinfo.RoutingNumber, /^\d{9}$/, 'Routing Number is required', 'Routing Number must be exactly 9 digits');
		validateField('AccountType', accountinfo.AccountType, /^[A-Za-z\s()]+$/, 'Account Type is required', 'Please Check the Account Type Format');
		setbankErrors(tempErrors);
		return isValid;
	};
	const handleReview = (val) => {
		setReviewlocation(val);
		if (onlyPark) {
			setStepParkOnly({
				"1 of 5 - Locate your Property": false,
				"2 of 5 - Mark Parking Spaces": false,
				"3 of 5 - Schedule Availability": false,
				"4 of 5 - Enter Bank Details": false,
				"5 of 5 - Review and Confirm": true,
			})
		} else {
			setConditions({
				"1 of 6 - Locate your Property": false,
				"2 of 6 - Mark Parking Spaces": false,
				"3 of 6 - Pick ups and  Drop offs": false,
				"4 of 6 - Schedule Availability": false,
				"5 of 6 - Enter Bank Details": false,
				"6 of 6 - Review and Confirm": true,
			});
		}
	}
	const backToBankdetails = () => {
		if (onlyPark) {
			setStepParkOnly({
				"1 of 5 - Locate your Property": false,
				"2 of 5 - Mark Parking Spaces": false,
				"3 of 5 - Schedule Availability": false,
				"4 of 5 - Enter Bank Details": true,
				"5 of 5 - Review and Confirm": false,
			})
		} else {
			setConditions({
				"1 of 6 - Locate your Property": false,
				"2 of 6 - Mark Parking Spaces": false,
				"3 of 6 - Pick ups and  Drop offs": false,
				"4 of 6 - Schedule Availability": false,
				"5 of 6 - Enter Bank Details": true,
				"6 of 6 - Review and Confirm": false,
			});
		}
	}

	const timeSlotesAvailability = () => {
		if (validateTimeValues()) {
			setBankdetails(true);
			if (onlyPark) {
				setStepParkOnly({
					"1 of 5 - Locate your Property": false,
					"2 of 5 - Mark Parking Spaces": false,
					"3 of 5 - Schedule Availability": false,
					"4 of 5 - Enter Bank Details": true,
					"5 of 5 - Review and Confirm": false,
				})
			} else {
				setConditions({
					"1 of 6 - Locate your Property": false,
					"2 of 6 - Mark Parking Spaces": false,
					"3 of 6 - Pick ups and  Drop offs": false,
					"4 of 6 - Schedule Availability": false,
					"5 of 6 - Enter Bank Details": true,
					"6 of 6 - Review and Confirm": false,
				});
			}
			toggleCondition('4 of 5 - Enter Bank Details')
			toggleCondition('3 of 5 - Schedule Availability')
		}
	}
	useEffect(() => {
		if (weekoption === '') {
			setTimeValues({
				Monday: "",
				Tuesday: "",
				Wednesday: "",
				Thursday: "",
				Friday: "",
				Saturday: "",
				Sunday: "",
			});
		}
		setErrors({});
	}, [selectedValue]);

	const handlebankdetails = (val) => {
		setBankdetails(val);
		if (onlyPark) {
			setStepParkOnly({
				"1 of 5 - Locate your Property": false,
				"2 of 5 - Mark Parking Spaces": false,
				"3 of 5 - Schedule Availability": true,
				"4 of 5 - Enter Bank Details": false,
				"5 of 5 - Review and Confirm": false,
			})
		} else {
			setConditions({
				"1 of 6 - Locate your Property": false,
				"2 of 6 - Mark Parking Spaces": false,
				"3 of 6 - Pick ups and  Drop offs": false,
				"4 of 6 - Schedule Availability": true,
				"5 of 6 - Enter Bank Details": false,
				"6 of 6 - Review and Confirm": false,
			});
		}

	}
	const handlebankaccountdetails = (val) => {
		setbankaccountdetails(val);
	}

	const handleChange = (day) => (event) => {
		const { value } = event.target;
		setTimeValues({ ...timeValues, [day]: value });
		const newErrors = { ...errors };
		delete newErrors.general;
		delete newErrors[day];
		setErrors(newErrors);

	};

	const findKeyByValue = (obj, valueToFind) => {
		for (const [key, val] of Object.entries(obj)) {
			if (val === valueToFind) {
				return key;
			}
		}
		return null;
	};
	const handleselectedOption = (area) => {
		setEnablemarker(true);
		markerEnabled.current = true;
		setSelectedOption(area);
		setOptionerror(false);

	}
	const openFrame = useCallback(() => {
		setFrameOpen(true);
	}, []);

	const closeFrame = () => {
		setFrameOpen(false);
	};
	const nextBtn = () => {
		const hasPSLEntry = areasMarked.some(area => area.Name === 'PSL - Parking Space Location');
		if ((areasMarked?.length > 0 && !hasPSLEntry) || (areasMarked.length === 0 && !hasPSLEntry)) {
			handleCancelalert(true);
			return;
		}
		if (areasMarked.length > 0 && hasPSLEntry) {
			setConfirmforScheduleAvailability(true);
			setFrame1Open(true);
		}
	}
	const openFrame1 = useCallback(() => {
		if ((addressvalue || props.defaultaddress) && !isAddressSelected) {
			setFrame1Open(true);
			setConfirmAddress(true);
			return;
		}
		if (isAddressSelected) {
			setSelectedOption([]);
			setOptionerror(true);
			const hasPSLEntry = areasMarked.some(area => area.Name === 'PSL - Parking Space Location');

			if (areasMarked.length === 0 && issearchdisabled) {
				setOptionerror(true);
				return;
			}

			if (selectedOption.length > 0 && (areasMarked?.length === 0 || !hasPSLEntry)) {
				handleCancelalert(true);
				return;
			}
			if ((areasMarked?.length > 0 && !hasPSLEntry)) {
				handleCancelalert(true);
				return;
			}

			if (onlyPark && hasPSLEntry) {
				setscheduleavailability(true);
				setStepParkOnly({
					"1 of 5 - Locate your Property": false,
					"2 of 5 - Mark Parking Spaces": false,
					"3 of 5 - Schedule Availability": true,
					"4 of 5 - Enter Bank Details": false,
					"5 of 5 - Review and Confirm": false,
				})
				return;
			}
			if (!onlyPark) {
				setshowParkOnly(false)
				setShowPickUPSDropoffs(true);
				setShownextbtn(true)
				setConditions({
					"1 of 6 - Locate your Property": false,
					"2 of 6 - Mark Parking Spaces": false,
					"3 of 6 - Pick ups and  Drop offs": true,
					"4 of 6 - Schedule Availability": false,
					"5 of 6 - Enter Bank Details": false,
					"6 of 6 - Review and Confirm": false,
				});
			}
			// if (selectedOption.length > 0 && areasMarked.length > 0 && hasPickupsDropoffs && showPickUPSDropoffs) {
			// 	setConfirmforScheduleAvailability(true);
			// 	setFrame1Open(true);
			// } else {
			// 	setOptionerror(true);
			// }
		}
	}, [addressvalue, props.defaultaddress, isAddressSelected, selectedOption, issearchdisabled, areasMarked, onlyPark]);



	const closeFrame1 = useCallback(() => {
		if (areasMarked.length > 0) {
			setFrame1Open(false);
			return;
		}
		setConditions({
			"1 of 6 - Locate your Property": true,
			"2 of 6 - Mark Parking Spaces": false,
			"3 of 6 - Pick ups and  Drop offs": false,
			"4 of 6 - Schedule Availability": false,
			"5 of 6 - Enter Bank Details": false,
			"6 of 6 - Review and Confirm": false,
		});
		setFrame1Open(false);
		setConfirmAddress(false);
		setAlertpopup(false);
	}, [areasMarked]);

	const closeaddressReset = useCallback(() => {
		setFrame1Open(false);
		setAlertpopup(false);
	}, []);


	const handleContinue = () => {
		if (areasMarked.length > 0) {
			setscheduleavailability(true)
			setConditions({
				"1 of 6 - Locate your Property": false,
				"2 of 6 - Mark Parking Spaces": false,
				"3 of 6 - Pick ups and  Drop offs": false,
				"4 of 6 - Schedule Availability": true,
				"5 of 6 - Enter Bank Details": false,
				"6 of 6 - Review and Confirm": false,
			});
			// toggleCondition('2 of 5 - Mark Parking Spaces & Other Areas')
			// toggleCondition('3 of 5 - Schedule Availability')
			setExpanded(true)
			setFrame1Open(false);
			setAlertpopup(false);

		}
		else {
			setFrameOpen(true)
			setSearchdisabled(true);
			setFrame1Open(false);
			setAlertpopup(false);
			setConditions({
				"1 of 6 - Locate your Property": false,
				"2 of 6 - Mark Parking Spaces": true,
				"3 of 6 - Pick ups and  Drop offs": false,
				"4 of 6 - Schedule Availability": false,
				"5 of 6 - Enter Bank Details": false,
				"6 of 6 - Review and Confirm": false,
			});
		}

	}
	const handleConfirm = (val) => {
		setscheduleavailability(val);
		if (onlyPark) {
			setStepParkOnly({
				"1 of 5 - Locate your Property": false,
				"2 of 5 - Mark Parking Spaces": true,
				"3 of 5 - Schedule Availability": false,
				"4 of 5 - Enter Bank Details": false,
				"5 of 5 - Review and Confirm": false,
			})
		}
		else {
			setConditions({
				"1 of 6 - Locate your Property": false,
				"2 of 6 - Mark Parking Spaces": false,
				"3 of 6 - Pick ups and  Drop offs": true,
				"4 of 6 - Schedule Availability": false,
				"5 of 6 - Enter Bank Details": false,
				"6 of 6 - Review and Confirm": false,
			});
		}
		// setExpanded(false);
	}
	let markers = []; // Array to store all markers
	const selectedOptionRef = useRef(selectedOption);

	useEffect(() => {
		selectedOptionRef.current = selectedOption; // Update the ref value whenever selectedOption changes
	}, [selectedOption]);
	useEffect(() => {
		const initializeMap = () => {
			let mapElement = document.getElementById('myMap');
			if (mapElement) {
				map = new atlas.Map('myMap', {
					center: [longitude || -122.18402494821507, latitude || 47.61373420362662],
					zoom: 18,
					language: 'en-US',
					style: 'satellite',
					authOptions: {
						authType: 'subscriptionKey',
						subscriptionKey: 'EN5vcaicwC78zKi3M3980VVB1m7DuuN0vKaDMtYdjBg7daKhx3OTJQQJ99AHAC8vTIngVrqvAAAgAZMPLtXG'
					}
				});
				mapRef.current = map;
				if (map && map.events && typeof map.events.add === 'function') {
					map.events.add('ready', function () {
						const zoomControl = new atlas.control.ZoomControl();
						if (map.controls && typeof map.controls.add === 'function') {
							map.controls.add(zoomControl, {
								position: 'bottom-right'
							});
						}
					});
				};
			}
		}
		if (latitude && longitude) {
			initializeMap();
			InitMap();

		}
	}, [latitude, longitude, scheduleavailability, props.areasMarked, onlyPark, showPickUPSDropoffs]);
	const getColorForText = (text) => {
		const colorMap = {
			"PSL - Parking Space Location": "#006400", // Dark Green
			"PKE - Parking Entrance": "#32CD32", // Medium Green
			"PKX - Parking Exit": "#98FB98", // Light Green
			"PUE - Pick Up Entrance": "#FFD700", // Bright Yellow
			"PUL - Pick Up Location": "#FFC300", // Medium Yellow
			"PUX - Pick Up Exit": "#FFFACD", // Pale Yellow
			"DOE - Drop Off Entrance": "#00008B", // Dark Blue
			"DOL - Drop Off Location": "#1E90FF", // Medium Blue
			"DOX - Drop Off Exit": "#ADD8E6", // Light Blue
		};
		return colorMap[text];
	};
	const isColorDark = (color) => {
		const hex = color?.replace("#", "");
		const r = parseInt(hex?.substring(0, 2), 16);
		const g = parseInt(hex?.substring(2, 4), 16);
		const b = parseInt(hex?.substring(4, 6), 16);
		// Calculate brightness (YIQ color model)
		return ((r * 299 + g * 587 + b * 114) / 1000) < 128;
	};
	async function InitMap() {
		const map = mapRef.current;
		const createMarkerHTMLContent = (optionText, latitude, longitude) => {
			const isParkingLocation = (optionText) =>
				["PSL - Parking Space Location", "PKE - Parking Entrance", "PKX - Parking Exit"].includes(optionText);

			const isPickUpOrDropOff = (optionText) =>
				["PUE - Pick Up Entrance", "DOE - Drop Off Entrance", "PUL - Pick Up Location", "DOL - Drop Off Location", "PUX - Pick Up Exit", "DOX - Drop Off Exit"].includes(optionText);

			const isDraggable = !(showPickUPSDropoffs && isParkingLocation(optionText)) &&
				!(isPickUpOrDropOff(optionText) && !showPickUPSDropoffs);

			const text = typeof optionText === 'string' ? optionText : String(optionText);
			const color = getColorForText(text);
			const textColor = isColorDark(color) ? "white" : "black";
			const drag = "Drag to move, click to delete"

			return `
    <div style="position: relative; width: 35px; height: 60px;" id="closeButton">
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="60" viewBox="0 0 24 36" fill="${color}">
            <path d="M12 0C5.383 0 0 5.383 0 12c0 7.188 10.83 22.812 10.83 22.812C11.147 34.81 12 34.365 12 34.365S12.853 34.81 13.17 34.812C13.17 34.812 24 19.188 24 12C24 5.383 18.617 0 12 0z"/>
            <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="${textColor}" font-size="8">${text?.substring(0, 3)}</text>
        </svg>
        <div class="${isDraggable ? "marker-card marker " : "marker-card marker ristrictedmarker"} ">
            ${isDraggable ? `
                <div id="closeButton" style="position: absolute; top: 5px; right: 5px; width: 20px; height: 20px; display: flex; align-items: center; justify-content: center; color: black; cursor: pointer;">
                    <img className="icround-gps-fixed-icon" alt="Delete" src="Delete.png" style="width: 100%; height: 100%;" />
                </div>` : ''}
            <div style="font-size: 12px; color: black; margin-bottom: 8px;">
                ${text}<br />
                ${isDraggable ? drag : ''}
            </div>
        </div>
    </div>`;
		};
		const addMarker = (latitude, longitude, optionText, id) => {
			var marker;
			const isParkingLocation = (optionText) =>
				["PSL - Parking Space Location", "PKE - Parking Entrance", "PKX - Parking Exit"].includes(optionText);

			const isPickUpOrDropOff = (optionText) =>
				["PUE - Pick Up Entrance", "DOE - Drop Off Entrance", "PUL - Pick Up Location", "DOL - Drop Off Location", "PUX - Pick Up Exit", "DOX - Drop Off Exit"].includes(optionText);

			const isDraggable = !(showPickUPSDropoffs && isParkingLocation(optionText)) &&
				!(isPickUpOrDropOff(optionText) && !showPickUPSDropoffs);

			marker = new atlas.HtmlMarker({
				draggable: isDraggable,
				position: [longitude, latitude],
				htmlContent: createMarkerHTMLContent(optionText, latitude, longitude),
			});

			map?.markers?.add(marker);


			const markerElement = marker?.getElement();
			const closeButton = markerElement?.querySelector('#closeButton');

			if (closeButton) {
				markerElement?.addEventListener('mouseover', () => {
					const card = markerElement.querySelector('.marker-card');
					if (card) {
						card.style.display = 'flex';
					}
				});

				markerElement.addEventListener('mouseout', () => {
					const card = markerElement.querySelector('.marker-card');
					if (card) {
						card.style.display = 'none';
					}
				});

				closeButton.addEventListener('click', function (e) {
					if (showPickUPSDropoffs === false && (optionText === "PSL - Parking Space Location" || optionText === "PKE - Parking Entrance" || optionText === "PKX - Parking Exit")) {
						map.markers.remove(marker);
						setareasmarked(prev => prev.filter(m => m.id !== id));
						setAreas(prevAreas => ({
							...prevAreas,
							[optionText]: prevAreas[optionText] - 1
						}));

					} else if (showPickUPSDropoffs === true && (optionText === "PUE - Pick Up Entrance" || optionText === "PUL - Pick Up Location" || optionText === "PUX - Pick Up Exit" || optionText === "DOE - Drop Off Entrance" || optionText === "DOL - Drop Off Location" || optionText === "DOX - Drop Off Exit")) {
						map.markers.remove(marker);
						setareasmarked(prev => prev.filter(m => m.id !== id));
						setPickUPSDropoffs(prevAreas => ({
							...prevAreas,
							[optionText]: prevAreas[optionText] - 1
						}));
					}
					e.stopPropagation();
				});
			}

			map?.events?.add('dragend', marker, function () {
				const newPosition = marker.getOptions().position;
				const newLatitude = newPosition[1];
				const newLongitude = newPosition[0];
				setareasmarked(prev => {
					return prev.map(m => {
						if (m.id === id) {
							return {
								...m,
								latandlong: `Latitude: ${newLatitude} Longitude: ${newLongitude}`,
								Coordinates: {
									...m.Coordinates,
									Latitude: newLatitude,
									Longitude: newLongitude
								}
							};
						}
						return m;
					});
				});
			});

			const markdata = {
				id,
				"Name": optionText,
				"latandlong": `Latitude: ${latitude} Longitude: ${longitude}`,
				"Coordinates": {
					"Title": optionText,
					"Latitude": latitude,
					"Longitude": longitude
				}
			};

			const markerExists = areasMarked.some(area => (area.id === id || (area.Coordinates.Latitude === latitude && area.Coordinates.Longitude === longitude)));
			if (!markerExists) {
				if (optionText === "PSL - Parking Space Location" || optionText === "PKE - Parking Entrance" || optionText === "PKX - Parking Exit") {
					setareasmarked(prev => [...prev, markdata]);
					setAreas(prevAreas => ({
						...prevAreas,
						[optionText]: prevAreas[optionText] + 1
					}));
				} else {
					setareasmarked(prev => [...prev, markdata]);
					setPickUPSDropoffs(prevAreas => ({
						...prevAreas,
						[optionText]: prevAreas[optionText] + 1
					}));
				}
			}

		};
		if (enableMarker) {
			if (map && map.events && map.events.add) {
				if (!map._listenersAdded) {
					map._listenersAdded = true;
					map.events.add('ready', function () {
						areasMarked.forEach(area => {
							const { Latitude, Longitude, Title, id } = area.Coordinates;
							addMarker(Latitude, Longitude, Title, area.id);
						});
					});

					map.events.add('click', function (e) {
						const clickedLatitude = e.position[1];
						const clickedLongitude = e.position[0];
						const newId = Date.now();
						if (selectedOptionRef.current.length != 0) {
							addMarker(clickedLatitude, clickedLongitude, selectedOptionRef?.current, newId);
						}
					});
				}
			}
		}
	}
	useEffect(() => {
		InitMap();
	}, [enableMarker, scheduleavailability]);

	useEffect(() => {
		if (props.areasMarked) {
			setOnlyPark(props.parkinglotObj?.isParkingSpaceSelected);
			setTimeValues(props?.parkinglotObj?.scheduleavailability?.timeSlot);
			setWeekoption(props?.parkinglotObj?.scheduleavailability?.weekOption);
			setSelectedValue(props?.parkinglotObj?.scheduleavailability?.weekOption);
			setConditions({
				"1 of 5 - Locate your Property": false,
				"2 of 5 - Mark Parking Spaces & Other Areas": true,
				"3 of 5 - Schedule Availability": false,
				"4 of 5 - Enter Bank Details": false,
				"5 of 5 - Review and Confirm": false
			});
			const newCounts = { ...areas };
			const newPickUPsDropoffCounts = { ...PickUPSDropoffs }
			const PickUPSDropdoffs = areasMarked.filter((name) => (name.Name === "PUE - Pick Up Entrance") || (name.Name === "DOE - Drop Off Entrance") || (name.Name === "PUL - Pick Up Location") || (name.Name === "DOL - Drop Off Location") || (name.Name === "PUX - Pick Up Exit") || (name.Name === "DOX - Drop Off Exit"))
			areasMarked.forEach(marker => {
				const title = marker.Name;
				setSelectedOption(title);
				if (newCounts.hasOwnProperty(title)) {
					newCounts[title] = (newCounts[title] || 0) + 1;
				}
			});
			PickUPSDropdoffs.forEach(marker => {
				const title = marker.Name;
				setSelectedOption(title);
				if (newPickUPsDropoffCounts.hasOwnProperty(title)) {
					newPickUPsDropoffCounts[title] = (newPickUPsDropoffCounts[title] || 0) + 1;
				}
			});
			setAreas(newCounts);
			setPickUPSDropoffs(newPickUPsDropoffCounts)
			setSearchdisabled(true);
			setConfirmAddress(true);
			setOptionerror(false);
			setEnablemarker(true)
			setFrameOpen(false);
			setExpandedMark(true);
			setSelectedOption([]);
			const bankData = props?.parkinglotObj?.bankDetails;
			setbankaccountdetails(bankData);
			if (props.editOption === 'parkingschedule') {
				setscheduleavailability(true);
				setExpanded(true)
			}
			else if (props.editOption === 'bankdetails') {
				setscheduleavailability(true);
				setBankdetails(true);
				setExpanded(true)
			}
		}
	}, [props.areasMarked]);

	useEffect(() => {
		// Update all markers' text when selectedOption changes
		markers.forEach(marker => {
			if (marker._textElement) {
				marker._textElement.textContent = selectedOption?.substring(0, 3);
			}
		});
	}, [selectedOption]);
	const searchLocation = (locationName) => {
		var data = []
		data.location = locationName
		axios.get(`https://atlas.microsoft.com/search/address/json?&subscription-key=EN5vcaicwC78zKi3M3980VVB1m7DuuN0vKaDMtYdjBg7daKhx3OTJQQJ99AHAC8vTIngVrqvAAAgAZMPLtXG&api-version=1.0&language=en-US&query=${locationName}`)
			.then((response) => {
				const results = response.data.results;
				if (results && results.length > 0) {
					const firstResult = results[0];
					const { position } = firstResult;
					setLatitude(position.lat);
					setLongitude(position.lon);
					const bounds = [];
					setCurrentproperty(firstResult?.address);
					setCurrentpropertyaddress(firstResult?.address?.freeformAddress)
					setStreetname(firstResult?.address?.streetName);
					// InitMap();
				}
			})
			.catch((error) => {
				console.error('Error fetching location data:', error);
			});
	};

	useEffect(() => {
		if (props?.defaultaddress?.trim() !== '') {
			searchLocation(props.defaultaddress);
		}
	}, [props.defaultaddress]);
	const getDataFromAPI = (value) => {
		if (value) {
			const locationName = value.trim();
			if (locationName !== '') {
				setAddressvalue(locationName);
				setTimeout(() => {
					searchLocation(locationName, map);
				}, 1000);
			}

		}
		else {
			setAddressvalue('');
		}
	};
	useEffect(() => {
		if (searchValue?.trim() !== '') {
			// Fetch suggestions from API
			axios.get(`https://atlas.microsoft.com/search/fuzzy/json?&subscription-key=EN5vcaicwC78zKi3M3980VVB1m7DuuN0vKaDMtYdjBg7daKhx3OTJQQJ99AHAC8vTIngVrqvAAAgAZMPLtXG&api-version=1.0&language=en-US&query=${searchValue}&extendedPostalCodesFor=PAD,Addr,POI,Str,Xstr&limit=10&countrySet=US`)
				.then(response => {
					const results = response.data.results;
					if (results && results.length > 0) {
						const suggestedLocations = results?.filter(result => result.address.countryCode === 'US' && result.address.streetNumber).map(result => result.address.freeformAddress);
						const selecetedAddress = results?.filter(result => result.address.countryCode === 'US').map(result => result.address);
						setSuggestions(suggestedLocations);
						setSelecetedAddress(selecetedAddress);
					}
				})
				.catch(error => {
					console.error('Error fetching location suggestions:', error);
				});
		} else {
			setAddressvalue('');
			setSuggestions([]);

		}
	}, [searchValue]);

	const handleSearchChange = (value) => {
		const invalidCharacters = /[^a-zA-Z0-9\s,]/;
		if (invalidCharacters.test(value)) {
			setSearchError(true);
		} else {
			setSearchError(false);
		}
		setSearchValue(value);
	};

	const handleradiobutton = (event) => {
		const selectedValue = event.target.value;
		setSelectedValue(selectedValue);
		setWeekoption('');
	};
	useEffect(() => {
		setAccountinfo({
			BankName: bankaccountdetails ? bankaccountdetails.bankName : "",
			AccountHolderName: bankaccountdetails ? bankaccountdetails.accountHolderName : "",
			AccountType: bankaccountdetails ? bankaccountdetails.accountType : "",
			AccountNumber: bankaccountdetails ? bankaccountdetails.accountNumber : "",
			RoutingNumber: bankaccountdetails ? bankaccountdetails.routingNumber : "",
			SWIFT_BIC_Code: bankaccountdetails ? bankaccountdetails.swifT_BIC_Code : "",
			//Add_Ass_with_Account: bankaccountdetails ? bankaccountdetails.add_Ass_with_Account : "",
		});
	}, [bankaccountdetails]);

	useEffect(() => {
		if (onlyPark) {
			const updateAreas = areasMarked.filter((name) => (name.Name === "PSL - Parking Space Location") || (name.Name === "PKE - Parking Entrance") || (name.Name === "PKX - Parking Exit"))
			setareasmarked(updateAreas)
		}
	}, [props.areasMarked])

	useEffect(() => {
		if (entityRegistrationId) {
			const emailId = {
				"EmailId": stateLogedInUser
			}
			const requestOptions = {
				method: 'POST',
				headers: { "x-functions-key": `${hostHeader}` },
				body: emailId
			};
			axios.get(`${hostPath}/api/UpdateBankDetails/` + entityRegistrationId, { headers: requestOptions.headers })
				.then(response => {
					if (response.data) {
						const bankdetails = response.data;
						setbankaccountdetails(bankdetails);
					}
				})
				.catch(error => {
					console.error('Error:', error);
				});
		}
	}, [])
	const newEventTriggered = (newEvent) => {
		SetEventTriggered(newEvent)
	}
	const handleSave = async (e) => {
		e.preventDefault();

		if (isSaveDisabled) {
			return; // Prevent the save action if the conditions aren't met
		}

		const searchLocation = (addressvalue.length !== 0) ? addressvalue : props.defaultaddress.trim();

		const CoardinateDetails = areasMarked?.map(item => ({
			Latitude: item.Coordinates.Latitude.toString(),
			Longitude: item.Coordinates.Longitude.toString(),
			Title: item.Coordinates.Title,
			ID: item.id.toString()
		}));
		const Scheduleavailability = events.map((event) => ({
			...event,
			// No conversion of dates; keep them as strings
		}));

		const BankDetails = accountinfo;
		BankDetails.EntityRegistrationID = entity;
		try {
			if (props.parkinglotObj) {
				const ScheduleEventSave = props.parkinglotObj.scheduleavailability.find((item) => item.Recurrence === EventTriggered.Recurrence)
				setLoader(true);
				const parkinglot = {
					EmailId: props.parkinglotObj.emailId,
					EntityRegistrationID: props.parkinglotObj.entityRegistrationID,
					ParkingLotAddress: searchLocation,
					Parking_Lot_ID: props.parkinglotObj.parking_Lot_ID,
					id: props.parkinglotObj.id,
					CreatedDate: props.parkinglotObj.createdDate,
					CreatedBy: props.parkinglotObj.createdBy,
					Status: props.parkinglotObj.status,
					Comment: props.parkinglotObj.comment,
					StreetName: streetname || props.streetName,
					EmpId: props.parkinglotObj.empId ? props.parkinglotObj.empId : 0,
					BankDetails,
					CoardinateDetails,
					Scheduleavailability,
					isParkingSpaceSelected: props.parkinglotObj?.isParkingSpaceSelected,
					NickName: props.parkinglotObj.nickName
				};

				if (scheduleavailability) {
					if (ScheduleEventSave) {
						toast.dismiss();
						toast.success("Successfully Updated Scheduled Details!");
					}
				} else if (props.parkinglotObj.coardinateDetails.length > 0) {
					const PickDropoffs = props.parkinglotObj.coardinateDetails?.filter((name) => (name.Name === "PUE - Pick Up Entrance") || (name.Name === "DOE - Drop Off Entrance") || (name.Name === "PUL - Pick Up Location") ||
						(name.Name === "DOL - Drop Off Location") || (name.Name === "PUX - Pick Up Exit") || (name.Name === "DOX - Drop Off Exit"))
					const ParkingSpaces = props.parkinglotObj.coardinateDetails?.filter((name) => (name.Name === "PSL - Parking Space Location") || (name.Name === "PKE - Parking Entrance") || (name.Name === "PKX - Parking Exit"))
					if (showPickUPSDropoffs && PickDropoffs) {
						toast.dismiss();
						toast.success("PicksUP/DropOffs Updated Successfully!");
					} else if (!showPickUPSDropoffs && ParkingSpaces) {
						toast.dismiss();
						toast.success("Parking Spaces Updated Successfully!");
					}
				}

				await axios.post(`${hostPath}/api/UpdateParkingLotDetails`, parkinglot, {
					headers: {
						"x-functions-key": hostHeader
					}
				});
				await axios.post(`${hostPath}/api/UpdateBankDetails`, BankDetails, {
					headers: {
						"x-functions-key": hostHeader
					}
				});
				lastSavedStateRef.current = {
					addressvalue,
					areasMarked: [...areasMarked],
					accountinfo: { ...accountinfo },
					events: { ...events }
				};
			} else if (addressvalue.length !== 0 || areasMarked.length > 0) {

				setLoader(true);
				const ScheduleEventSave = Scheduleavailability.find((item) => item.Recurrence === EventTriggered.Recurrence)
				if (scheduleavailability) {
					if (ScheduleEventSave?.Title) {
						toast.dismiss();
						toast.success("Successfully Saved Scheduled Details!");
					}
				} else if (areasMarked.length > 0) {
					const PickDropoffs = areasMarked?.filter((name) => (name.Name === "PUE - Pick Up Entrance") || (name.Name === "DOE - Drop Off Entrance") || (name.Name === "PUL - Pick Up Location") ||
						(name.Name === "DOL - Drop Off Location") || (name.Name === "PUX - Pick Up Exit") || (name.Name === "DOX - Drop Off Exit"))
					const ParkingSpaces = areasMarked?.filter((name) => (name.Name === "PSL - Parking Space Location") || (name.Name === "PKE - Parking Entrance") || (name.Name === "PKX - Parking Exit"))
					if (showPickUPSDropoffs && PickDropoffs.length > 0) {
						toast.dismiss();
						toast.success("PicksUP/DropOffs Saved Successfully!");
					} else if (!showPickUPSDropoffs && ParkingSpaces) {
						toast.dismiss();
						toast.success("Parking Spaces Saved Successfully!");
					}
				}

				const email1 = {
					EmailId: stateLogedInUser,
					Status: "Draft",
					StreetName: streetname,
					EntityRegistrationID: entity,
					ParkingLotAddress: searchLocation,
					EmpId: props.empId ? props.empId : 0,
					CreatedBy: props.verifiedBy,
					CoardinateDetails,
					Scheduleavailability
				};
				if (BankDetails.AccountNumber) {
					email1.BankDetails = BankDetails;
				}
				let reqbody = { ...email1 };
				if (parkinglotId) {
					reqbody = {
						...reqbody,
						Parking_Lot_ID: parkinglotId.parkingLotId,
						id: parkinglotId.id,
						CreatedDate: parkinglotId.createdDate
					};

					const UpdateParkingLotDetails = await axios.post(`${hostPath}/api/UpdateParkingLotDetails`, reqbody, {
						headers: {
							"x-functions-key": hostHeader
						}
					});
					if (UpdateParkingLotDetails.data.status === true && areasMarked.length === 0) {
						toast.dismiss();
						toast.success("ParkingLot Address Updated Successfully!");
					}
				} else {
					const parkinglotBookingResponse = await axios.post(`${hostPath}/api/ParkingLotBooking`, reqbody, {
						headers: {
							"x-functions-key": hostHeader
						}
					});
					if (parkinglotBookingResponse?.data && areasMarked.length === 0) {
						setParkinglotId(parkinglotBookingResponse.data);
						toast.dismiss();
						toast.success("ParkingLot Address Saved Successfully!");
					}
					else if (parkinglotBookingResponse?.data && areasMarked.length !== 0) {
						setParkinglotId(parkinglotBookingResponse.data);
					}
				}
				if (BankDetails.AccountNumber) {
					await axios.post(`${hostPath}/api/UpdateBankDetails`, BankDetails, {
						headers: {
							"x-functions-key": hostHeader
						}
					});
				}
				lastSavedStateRef.current = {
					addressvalue,
					areasMarked: [...areasMarked],
					accountinfo: { ...accountinfo },
					events: { ...events }
				};
				setAutosave(true);

			}
		} catch (error) {
			console.error('Error:', error);
		} finally {
			setLoader(false); // Ensure loader is always turned off after API calls
		}
	};


	const handleAccord = () => {
		setExpanded(!expanded);
	}
	const handleMarkerAccord = () => {
		setExpandedMark(!expandedMark);
	}
	const toggleCondition = (key) => {
		setConditions((prevConditions) => ({
			...prevConditions,
			[key]: !prevConditions[key],
		}));
	};
	const handleSearchclick = () => {
		setSelectedOption([]);
		if (!isAddressSelected) {
			setDashboard(true);
			setFrame1Open(true);
			setAlertpopup(false);
		}
		else {
			setAlertpopup(true);
			setFrame1Open(true);
		}
	}
	const handleBackclick = () => {
		setSelectedOption([]);
		if (!isAddressSelected) {
			setDashboard(true);
			setFrame1Open(true);
			setAlertpopup(false);
		} else if (showPickUPSDropoffs) {
			setConditions({
				"1 of 6 - Locate your Property": false,
				"2 of 6 - Mark Parking Spaces": true,
				"3 of 6 - Pick ups and  Drop offs": false,
				"4 of 6 - Schedule Availability": false,
				"5 of 6 - Enter Bank Details": false,
				"6 of 6 - Review and Confirm": false,
			});
			setshowParkOnly(true)
			setShowPickUPSDropoffs(false);
		}
		else {
			setshowParkOnly(true)
			setAlertpopup(true);
			setFrame1Open(true);
		}
	}
	const navigateToDashboard = (isDashboard) => {
		navigate('/dashboard', { state: { isDashboard } });
	};
	const handleAddressreset = () => {
		resetStates();
	}

	const handleChangeAccordian = () => {
		setExpanded(!expanded);
	};
	const handleAccordMark = () => {
		setExpandedMark(!expandedMark);
	}
	const handleNavigate = (newDate) => {
		if (typeof newDate === 'string') {
			const currentDate = new Date(selectedDate);
			switch (newDate) {
				case 'PREV':
					currentDate.setMonth(currentDate.getMonth() - 1);
					setSelectedDate(new Date(currentDate));
					break;
				case 'NEXT':
					currentDate.setMonth(currentDate.getMonth() + 1);
					setSelectedDate(new Date(currentDate));
					break;
				case 'TODAY':
					setSelectedDate(new Date());
					break;
				default:
					break;
			}
		} else if (newDate instanceof Date) {
			setSelectedDate(newDate);
		}
	};

	useEffect(() => {
		const toastTop = expandedMark ? '140px' : '30px';

		if (areasMarked.length === 0 && optionerror) {
			if (!toast.isActive('error-toast')) {
				toast.error('Mark Parking Spaces: Parking Spot Location Markers', {
					autoClose: false,
					hideProgressBar: false,
					closeOnClick: false,
					draggable: false,
					className: 'custom-toast',
					style: {
						top: toastTop,
						left: '-3%',
						position: 'absolute',
						zIndex: 10,
					},
					toastId: 'error-toast',
					onClose: () => setOptionerror(false),
				});
			} else {
				toast.update('error-toast', {
					className: 'custom-toast',
					style: {
						top: toastTop,
						left: '-3%',
						position: 'absolute',
					},
				});
			}
		} else if (!optionerror) {
			toast.dismiss('error-toast');
		}
	}, [areasMarked, optionerror, expandedMark]);
	return (
		<>
			{loader && (
				<div style={{
					position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000
				}}>
					<FadeLoader color="#36d7b7" loading={loader} />
				</div>
			)}

			{/* <div className="map"> */}
			<div className={scheduleavailability ? "d-flex" : "flex-container"}>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<AccordionSummary
						expanded={expanded}
						onChange={handleChangeAccordian}
						disableDivider
						sx={{ maxWidth: 1000 }}
						style={{
							zIndex: 1000,
							position: 'relative',
							alignItems: 'center',
							padding: '50px',
							maxWidth: '350px',
						}}
						className="vector-parent"
					>
						<Accordion expanded={expanded} onChange={handleAccord}>
							<AccordionSummary
								expandIcon={
									expanded ? (
										<RemoveIcon data-testid="RemoveIcon" />
									) : (
										<AddIcon data-testid="AddIcon" />
									)
								}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								Property Registration Steps
							</AccordionSummary>
							{(onlyPark ? stepParkOnly : steps).map((step, index) => (
								<AccordionDetails
									key={step}
									className={`AccordName ${onlyPark ? (conditions1[step] ? 'selected' : '') : conditions[step] ? 'selected' : ''}`}
								>
									{step}
								</AccordionDetails>
							))}
						</Accordion>
					</AccordionSummary>

					{/* Date picker under AccordionSummary when scheduleavailability is true */}
					{scheduleavailability && !bankdetails && (
						<div style={{ marginBottom: '20px', marginTop: '10px', marginLeft: '40px' }}>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<StaticDatePicker
									displayStaticWrapperAs="desktop"
									openTo="day"
									value={selectedDate}
									onChange={handleDatePickerChange}
									sx={{
										'& .MuiPickerStaticWrapper-root': {
											width: '100%',
										},
										'& .MuiPickersDay-root': {
											fontSize: '14px',
										},
										'& .MuiPickersDay-dayWithMargin': {
											margin: '1px',
										},
										'& .MuiCalendarPicker-root': {
											fontSize: '12px',
										},
										'& .MuiPickersCalendarHeader-label': {
											fontSize: '16px',
										},
										'& .MuiPickersArrowSwitcher-root button': {
											fontSize: '1.2rem',
										},
									}}
								/>
							</LocalizationProvider>
						</div>
					)}
				</div>
				<div style={accordionStyles}>
					{!scheduleavailability ? (
						<div>
							<div className="map-child" id="myMap" data-testid="myMap"></div>
							<div className="material-symbolsquestion-mark-wrapper" style={{ left: "400px" }} onClick={openFrame}>
								<img className="material-symbolsquestion-mark-icon" alt="" src="question-mark.png" />
							</div>
							{showPickUPSDropoffs ?
								<div className="next-wrapper" onClick={nextBtn}>
									<b className="next">Next</b>
								</div> :
								<div className="next-wrapper" onClick={openFrame1}>
									<b className="next">Next</b>
								</div>
							}
							<div className="back-wrapper" onClick={handleBackclick}>
								<b className="next">Back</b>
							</div>
							<div
								className="autosave1-wrapper"
								onClick={(e) => handleSave(e)}
								style={{
									pointerEvents: isSaveDisabled ? 'none' : 'auto',
									opacity: isSaveDisabled ? 0.5 : 1,
								}}
							>
								<b className="next">Save</b>
							</div>
							<div className="component-1">
								<div style={{ flexShrink: "1", display: "flex" }}>
									{!issearchdisabled ? (
										<div className="enter-your-address">
											<Autocomplete
												style={{ width: 300, backgroundColor: "white", marginLeft: "-0%", borderRadius: "10px", flexShrink: "1" }}
												freeSolo
												autoComplete
												autoHighlight
												options={suggestions}
												getOptionLabel={(suggestions) => suggestions}
												filterOptions={(suggestions) => suggestions}
												onChange={(event, value) => getDataFromAPI(value)}
												onInputChange={(event, value) => {
													setAddressvalue(value);
													setSearchError(false);
												}}
												disabled={issearchdisabled}
												value={addressvalue}
												renderInput={(params) => (
													<TextField
														{...params}
														onChange={(e) => { handleSearchChange(e.target.value); }}
														variant="filled"
														label="Search Box"
														disabled={issearchdisabled}
														value={addressvalue}
													/>
												)}
											/>
											{searchError && (
												<div style={{ color: 'red', marginTop: '8px' }}>
													Enter alphabetic characters only
												</div>
											)}
										</div>) : <GrFormSearch
										data-testid="GrFormSearch"
										onClick={handleSearchclick} style={{ backgroundColor: 'white', height: '50px', width: '50px', borderRadius: "10px", marginTop: "13px", cursor: "pointer" }} />}

									{isAddressSelected && issearchdisabled ?
										<div className='d-flex'>
											{showParkOnly ?
												<div className='Parking-Only'>
													<FormGroup>
														<FormControlLabel
															control={
																<Checkbox
																	name="Parking Only"
																	checked={selectedMarkOption === 'Parking Only' || onlyPark}
																	onChange={selectOption}
																/>
															}
															label="Parking Only and No rider Pickup/drop offs"
														/>
													</FormGroup>
												</div> : ""}
											<div className="enter-your-address">
												<AccordionSummary
													expanded={expandedMark}
													onChange={handleAccordMark}
													disableDivider
													sx={{ maxWidth: 500 }}
													style={{ zIndex: 1000, position: 'relative', alignItems: "center", padding: "10px" }}
													className='vector-child'
												>
													<Accordion expanded={expandedMark} onChange={handleMarkerAccord}>
														<AccordionSummary
															expandIcon={expandedMark ? <RemoveIcon /> : <AddIcon />}
															aria-controls="panel1a-content"
															id="panel1a-header"
														>
															{showPickUPSDropoffs ? "Points to Mark on the Map  ( Optional )" : "Points to Mark on the Map"}
														</AccordionSummary>
														{Object.entries(showPickUPSDropoffs ? PickUPSDropoffs : areas).map(([key, value]) => (
															<AccordionDetails
																key={key}
																className={`AccordName1 ${selectedOption === key ? 'selected' : ''}`}
																onClick={() => handleselectedOption(key)}
															>
																<span style={{ display: 'inline-block', minWidth: minWidthStyle, textAlign: 'right' }}>
																	<strong>{value}</strong>
																</span>
																{"\u00A0\u00A0\u00A0\u00A0"}
																{key}
																{"\u00A0\u00A0\u00A0\u00A0"}
																{key === "PSL - Parking Space Location" && (
																	<span style={{ color: 'red', fontWeight: '900', fontSize: '1.2em', marginRight: '6px' }}>
																		*
																	</span>
																)}
															</AccordionDetails>
														))}
													</Accordion>
												</AccordionSummary>
											</div>
										</div>
										: ""}

									<ToastContainer />
								</div>
							</div>
						</div>
					) : (
						!bankdetails ? (
							<>
								<div style={{ marginLeft: '60px', marginRight: '80px', marginBottom: '-20px', marginTop: '15px' }}>
									<Alert severity="info">By default all <strong> marked</strong> parking spots are available 24/7 throughout the year. This scheduler is used to designate the times when the marked parking spaces will <strong>not</strong> be allowed for use by Robotaxis</Alert>
								</div>
								<Scheduler
									logEvents={logEvents}
									timeSlotesAvailability={timeSlotesAvailability}
									handleConfirm={handleConfirm}
									toggleCondition={toggleCondition}
									events={events}
									setEvents={setEvents}
									selectedDate={selectedDate}
									setSelectedDate={setSelectedDate}
									handleNavigate={handleNavigate}
									handleSave={handleSave}
									isSaveDisabled={isSaveDisabled}
									newEventTriggered={newEventTriggered}
								/>
							</>
						) : (
							!reviewlocation ? (
								<BankDetailsMain handlebankdetails={handlebankdetails} bankdetails={bankaccountdetails} entity={entity}
									handleReview={handleReview} validateFields={validateFields} handleInputChange={handleInputChange}
									errors={errorsbank} accountinfo={accountinfo} toggleCondition={toggleCondition} parkinglotObj={props.parkinglotObj}
									handleSave={handleSave} isSaveDisabled={isSaveDisabled} verificationstatus={props.verificationstatus} setSelectedBankDetailID={handleSelectedBankDetailID}
								/>) : (
								<ReviewAndConfirm handleReview={handleReview} areasMarked={areasMarked} addressvalue={addressvalue} SelectedBankDetailID={SelectedBankDetailID}
									accountinfo={accountinfo} bankdetails={accountinfo} EmailId={stateLogedInUser} latitude={latitude} longitude={longitude} toggleCondition={toggleCondition}
									handleviewparking={props.handleviewparking} defaultaddress={props.defaultaddress} entityId={entity} parkinglotObj={props.parkinglotObj} events={events} selectedValue={selectedValue}
									streetName={streetname} isSalespersonloggedin={props.isSalespersonloggedin} myworklistredirect={props.myworklistredirect} isautoSave={isautoSave} parkinglotId={parkinglotId}
									empId={props.empId} verificationstatus={props.verificationstatus} backToBankdetails={backToBankdetails} onlyPark={onlyPark} userData={props?.userData} selecetedAddress={selecetedAddress}
									verifiedBy={props.verifiedBy}
								/>
							)
						)
					)}
				</div>
			</div>
			{isFrameOpen && (
				<PortalPopup
					overlayColor="rgba(113, 113, 113, 0.3)"
					placement="Centered"
					onOutsideClick={closeFrame}
				>
					<Frame1 defaultaddress={props.defaultaddress} isFrameOpen={isFrameOpen} onClose={closeFrame} enableMarker={enableMarker} isScheduleAvailabilityPopupConfirm={isScheduleAvailabilityPopupConfirm} isAddressSelected={isAddressSelected} userData={props.userData}
						currentPropertyaddress={currentPropertyaddress} />
				</PortalPopup>
			)}
			{isFrame1Open && (
				<PortalPopup
					overlayColor={!searchError ? "rgba(113, 113, 113, 0.3)" : "transparent"}
					placement="Centered"
					onOutsideClick={closeFrame1}
				>
					<Frame isFrame1Open={isFrame1Open} onClose={closeFrame1} handleContinue={handleContinue} isScheduleAvailabilityPopupConfirm={isScheduleAvailabilityPopupConfirm} isAddressSelected={isAddressSelected} isDashboard={isDashboard}
						alertpopup={alertpopup} handleSearchclick={handleSearchclick} handleAddressreset={handleAddressreset} areasMarked={areasMarked} addressvalue={addressvalue} defaultaddress={props.userData} navigateToDashboard={e => navigateToDashboard(true)}
						currentProperty={currentProperty} closeaddressReset={closeaddressReset} searchError={searchError}
					/>
				</PortalPopup>
			)}
			{(markingalert || exitAlert) && (<MarkingAlert markingalert={markingalert} handleCancelalert={handleCancelalert} exitAlert={exitAlert}
				currentMarker={currentMarker.current} areasMarkedCount={areasMarked.length} hasPSLEntry={areasMarked.some(area => area.Name === 'PSL - Parking Space Location')}
			/>)}
		</>
	);
};

export default MapDashboard;
