import React, { useState, useEffect } from 'react';
import './BankDetails.css';


const accountTypes = [
    'Checking Account',
    'Savings Account',
    'Money Market Account',
    'Certificate of Deposit (CD)',
    'Brokerage Account',
    'Individual Retirement Account (IRA)',
    'Joint Account',
    'Business Account'
];
const AddBankDetails = (props) => {
    const { overrides, bankdetails, handleReview, validateFields, handleInputChange, errors, accountinfo, toggleCondition, handleSave, isSaveDisabled, handleButtonClick, ...rest } = props;
    const [bankDetails, setBankDetails] = useState({
        bankName: '',
        accountHolderName: '',
        accountNumber: '',
        routingNumber: '',
        swiftCode: '',
        accountType: '',
        nickName: '',
        address: ''
    });
    useEffect(() => {
        const savedDetails = localStorage.getItem('bankDetails');
        if (savedDetails) {
            setBankDetails(JSON.parse(savedDetails));
        }
    }, []);

    const handlebankdetailsSave = (e) => {
        if (validateFields()) {
            handleSave(e);
        }
    }
    const backButtonClick = () => {
        props.handlebankdetails(false);
    }
    return (
        <div>
            <div className="col-md-10 ">
                <div className="row m-4">
                    <div className="col-lg-12 col-md-12 col-sm-12 m-4" >

                        <div >
                            <div className="bnkDetailsForm" >
                                <h1 className='text-start bankDetails'>Enter Bank Details</h1>
                                <div className="row mb-3">
                                    <div className="form-group col-md-4 bankInputs">
                                        <label htmlFor="Bank_Name" className='label'>Bank Name</label><span className='mandatorymark loginlabel'>*</span>
                                        <input
                                            type="text"
                                            className={`form-control ${accountinfo?.BankName ? 'input-active' : ''}`}
                                            id="Bank_Name"
                                            maxLength={30}
                                            placeholder="Enter Bank Name"
                                            onInput={(e) => {
                                                const value = e.target.value;
                                                const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
                                                e.target.value = filteredValue;
                                                handleInputChange("BankName")(e);
                                            }}
                                            value={accountinfo?.BankName}
                                            name="BankName"
                                        />
                                        {errors.BankName && <div className="error-message mandatorymark">{errors.BankName}</div>}
                                    </div>

                                    <div className="form-group col-md-4 bankInputs">
                                        <label htmlFor="Account_Holder_Name" className='label'>Account Holder Name</label><span className='mandatorymark loginlabel'>*</span>
                                        <input
                                            type="text"
                                            className={`form-control ${accountinfo?.AccountHolderName ? 'input-active' : ''}`}
                                            id="Account_Holder_Name"
                                            maxLength={30}
                                            placeholder="Enter Account Holder Name"
                                            onInput={(e) => {
                                                const value = e.target.value;
                                                const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
                                                e.target.value = filteredValue;
                                                handleInputChange("AccountHolderName")(e);
                                            }}
                                            value={accountinfo?.AccountHolderName}
                                            name="AccountHolderName"
                                        />
                                        {errors.AccountHolderName && <div className="error-message mandatorymark">{errors.AccountHolderName}</div>}
                                    </div>

                                    <div className="form-group col-md-4 bankInputs">
                                        <label htmlFor="Account_Number" className='label'>Account Number</label><span className='mandatorymark loginlabel'>*</span>
                                        <input
                                            type="text"
                                            maxLength={12}
                                            className={`form-control ${accountinfo?.AccountNumber ? 'input-active' : ''}`}
                                            id="Account_Number"
                                            placeholder="Enter Account Number"
                                            onInput={(e) => {
                                                const value = e.target.value;
                                                const filteredValue = value.replace(/[^0-9]/g, '');
                                                e.target.value = filteredValue;
                                                handleInputChange("AccountNumber")(e);
                                            }}
                                            value={accountinfo?.AccountNumber}
                                            name="AccountNumber"
                                        />
                                        {errors.AccountNumber && <div className="error-message mandatorymark">{errors.AccountNumber}</div>}
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-md-4 form-group bankInputs">
                                        <label htmlFor="Routing_Number" className='label'>Routing Number</label>
                                        <span className="mandatorymark loginlabel">*</span>
                                        <input
                                            type="text"
                                            className={`form-control ${accountinfo?.RoutingNumber ? 'input-active' : ''}`}
                                            id="Routing_Number"
                                            placeholder="Enter Routing Number"
                                            maxLength={9}
                                            onInput={(e) => {
                                                const value = e.target.value;
                                                const filteredValue = value.replace(/[^0-9]/g, '');
                                                e.target.value = filteredValue;
                                                handleInputChange("RoutingNumber")(e);
                                            }}
                                            value={accountinfo?.RoutingNumber}
                                            name="RoutingNumber"
                                        />
                                        {errors.RoutingNumber && <div className="error-message mandatorymark">{errors.RoutingNumber}</div>}
                                    </div>

                                    <div className="col-md-4 form-group bankInputs">
                                        <label htmlFor="SWIFT_BIC_Code" className='label'>SWIFT/BIC Code (if applicable)</label>
                                        <input
                                            type="text"
                                            className={`form-control ${accountinfo?.SWIFT_BIC_Code ? 'input-active' : ''}`}
                                            id="SWIFT_BIC_Code"
                                            maxLength={11}
                                            placeholder="Enter SWIFT/BIC Code (if applicable)"
                                            onInput={(e) => {
                                                const value = e.target.value;
                                                const filteredValue = value.replace(/[^a-zA-Z0-9]/g, '');
                                                e.target.value = filteredValue;
                                                handleInputChange("SWIFT_BIC_Code")(e);
                                            }}
                                            value={accountinfo?.SWIFT_BIC_Code}
                                            name="SWIFT_BIC_Code"
                                        />
                                    </div>

                                    <div className="col-md-4 form-group bankInputs">
                                        <label htmlFor="Account_Type" className='label'>Account Type</label>
                                        <span className="mandatorymark loginlabel">*</span>
                                        <input
                                            list="accountTypes"
                                            className={`form-control ${accountinfo?.AccountType ? 'input-active' : ''}`}
                                            id="Account_Type"
                                            placeholder="Select or Type Account Type"
                                            onInput={(e) => {
                                                const value = e.target.value;
                                                const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
                                                e.target.value = filteredValue;
                                                handleInputChange("AccountType")(e);
                                            }}
                                            value={accountinfo?.AccountType}
                                            name="AccountType"
                                        />
                                        <datalist id="accountTypes">
                                            {accountTypes.map((type, index) => (
                                                <option key={index} value={type} />
                                            ))}
                                        </datalist>
                                        {errors.AccountType && <div className="error-message mandatorymark">{errors.AccountType}</div>}
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    {/* <div className="col-md-4 form-group bankInputs">
                                        <label htmlFor="Address" className='label'>Address Associated with Account</label>
                                        <span className="mandatorymark loginlabel">*</span>
                                        <input
                                            type="text"
                                            className={`form-control ${accountinfo?.Add_Ass_with_Account ? 'input-active' : ''}`}
                                            id="Address"
                                            placeholder="Enter Address"
                                            maxLength={100}
                                            onInput={(e) => {
                                                const value = e.target.value;
                                                const filteredValue = value;
                                                e.target.value = filteredValue;
                                                handleInputChange("Add_Ass_with_Account")(e);
                                            }}
                                            value={accountinfo?.Add_Ass_with_Account}
                                            name="Add_Ass_with_Account"
                                        />
                                        {errors.Add_Ass_with_Account && <div className="error-message mandatorymark">{errors.Add_Ass_with_Account}</div>}
                                    </div> */}

                                    <div className="col-md-4 form-group bankInputs">
                                        <label htmlFor="NickName" className='label'>NickName</label>
                                        <span className="mandatorymark loginlabel">*</span>
                                        <input
                                            type="text"
                                            className={`form-control ${accountinfo?.NickName ? 'input-active' : ''}`}
                                            id="NickName"
                                            maxLength={100}
                                            placeholder="Enter NickName"
                                            onInput={(e) => {
                                                const value = e.target.value;
                                                const filteredValue = value;
                                                e.target.value = filteredValue;
                                                handleInputChange("NickName")(e);
                                            }}
                                            value={accountinfo?.NickName}
                                            name="NickName"
                                        />
                                        {errors.NickName && <div className="error-message mandatorymark">{errors.NickName}</div>}
                                    </div>
                                    <div className="col-md-4 form-group bankInputs"></div>
                                    <div className="col-md-4 form-group bankInputs"></div>
                                </div>

                                <div>
                                    <div className="mb-3" style={{ textAlign: "end" }}>
                                        <button type="submit" className='finishbtn' onClick={backButtonClick}>Cancel</button>
                                        <button type="submit" className='autonextBtn ' onClick={handleButtonClick}> {accountinfo?.id ? "Update Bank Account" : "Add Bank Account"}</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddBankDetails;