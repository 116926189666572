export const extendedAlphaNumericRegex = /^[a-zA-Z0-9À-ÿ\s\-,.]*$/;
export const geoparkingEmailPattern = /^[a-zA-Z0-9._%+-]+@geoparking\.com$/;
export const removeDoublequotesPattern = (param) => {
  if (typeof param === 'string') {
    return param.replace(/^"(.*)"$/, '$1');
  }
  return param;
};
export const digitRegex = /^\d*$/;
export const alphabetRegex = /^[A-Za-z\s\'']*$/;
export const digitsWithAlphabetsRegex = /^(?!^[0-9\s&.]+$)[a-zA-Z0-9\s&.]+$/;