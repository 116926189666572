import React, { useState, useEffect } from 'react';
import './AvailabilityModal.css';
import ClockIcon from './assets/Layer_1.svg';
import SmallClock from './assets/Capa_1.svg';
import ArrowIcon from './assets/Arrow 2.svg';
import RecurrenceIcon from './assets/Layer_2.svg';
import CalendarIcon from './assets/CalendarIcon.svg';

const AvailabilityModal = ({
    show,
    onClose,
    onSave,
    onDelete,
    isEditing,
    editMode,
    eventDetails,
}) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [recurrence, setRecurrence] = useState(null);
    const [selectedDays, setSelectedDays] = useState([]);
    const [duration, setDuration] = useState('');
    const [allDay, setAllDay] = useState(false);
    const [customRecurrenceType, setCustomRecurrenceType] = useState('week');
    const [customRecurrenceCount, setCustomRecurrenceCount] = useState(1);
    const [endType, setEndType] = useState('never'); // 'never', 'on', 'after'
    const [endDateOn, setEndDateOn] = useState('');
    const [endAfter, setEndAfter] = useState(1);
    const [isHoliday, setIsHoliday] = useState(false);
    const [recurrenceSummary, setRecurrenceSummary] = useState('');

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (`${date.getMonth() + 1}`).padStart(2, '0');
        const day = (`${date.getDate()}`).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const formatTime = (date) => {
        const hours = (`${date.getHours()}`).padStart(2, '0');
        const minutes = (`${date.getMinutes()}`).padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    function convertTo24Hour(timeString) {
        // Trim and uppercase so "am", "pm", etc. all work
        let t = timeString.trim().toUpperCase();
        // If it doesn't contain AM or PM, assume it's already HH:MM
        if (!t.endsWith("AM") && !t.endsWith("PM")) {
            return t; // e.g. "14:30"
        }

        // Split off the AM/PM
        const isPM = t.endsWith("PM");
        t = t.replace(/AM|PM/, "").trim(); // remove the AM/PM part

        let [hh, mm = "00"] = t.split(":");
        let hours = parseInt(hh, 10);
        let minutes = parseInt(mm, 10) || 0;

        // Convert 12‐hour to 24‐hour
        if (isPM && hours < 12) hours += 12;       // e.g. 1PM => 13
        if (!isPM && hours === 12) hours = 0;      // 12AM => 00

        // Return "HH:MM" zero-padded
        return String(hours).padStart(2, "0") + ":" + String(minutes).padStart(2, "0");
    }

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteSeriesFlag, setDeleteSeriesFlag] = useState(false);

    const handleDeleteEvent = (deleteSeries) => {
        setDeleteSeriesFlag(deleteSeries);
        setShowDeleteConfirmation(true);
    };

    const handleConfirmDelete = () => {
        onDelete(eventDetails, deleteSeriesFlag);
        setShowDeleteConfirmation(false);
    };

    const handleCancelDelete = () => {
        setShowDeleteConfirmation(false);
    };


    // Calculate duration between start and end times
    const calculateDuration = (start, end) => {
        if (start && end) {
            // Parse start time
            const [startHours, startMinutes] = start.split(':').map(Number);
            // Parse end time
            const [endHours, endMinutes] = end.split(':').map(Number);

            // Calculate total minutes
            const startTotalMinutes = startHours * 60 + startMinutes;
            const endTotalMinutes = endHours * 60 + endMinutes;

            let diff = endTotalMinutes - startTotalMinutes;

            if (diff < 0) {
                // If end time is before start time, assume the event spans to next day
                diff += 24 * 60;
            }

            const hours = Math.floor(diff / 60);
            const minutes = diff % 60;
            return `${hours}h ${minutes}min`;
        }
        return '';
    };

    const handleAllDayChange = () => {
        const newAllDay = !allDay;
        setAllDay(newAllDay);

        if (newAllDay) {
            // When "All Day" is checked, set times to cover the entire day
            setStartTime('00:00');
            setEndTime('23:59');
        } else {
            // When "All Day" is unchecked, clear the times
            setStartTime('');
            setEndTime('');
        }
        // Update duration
        setDuration(calculateDuration('00:00', '23:59'));
    };




    useEffect(() => {
        if (show) {
            if (eventDetails && Object.keys(eventDetails).length > 0) {
                // Initialize variables for date and time strings
                let startDateStr = '';
                let startTimeStr = '';
                let endDateStr = '';
                let endTimeStr = '';

                // Handle eventDetails.start
                if (eventDetails.start) {
                    if (typeof eventDetails.start === 'string' && eventDetails.start.length >= 10) {
                        // If it's a string, parse it directly
                        startDateStr = eventDetails.start.substring(0, 10); // 'YYYY-MM-DD'
                        startTimeStr = eventDetails.start.substring(11, 16) || ''; // 'HH:mm'
                    } else if (eventDetails.start instanceof Date) {
                        // If it's a Date object, format it to string
                        startDateStr = formatDate(eventDetails.start);
                        startTimeStr = formatTime(eventDetails.start);
                    } else {
                        console.error('Unexpected type for eventDetails.start:', eventDetails.start);
                    }

                    setStartDate(startDateStr);
                    setStartTime(startTimeStr);
                } else {
                    // Reset if start is empty
                    setStartDate('');
                    setStartTime('');
                }

                // Handle eventDetails.end
                if (eventDetails.end) {
                    if (typeof eventDetails.end === 'string' && eventDetails.end.length >= 10) {
                        // If it's a string, parse it directly
                        endDateStr = eventDetails.end.substring(0, 10); // 'YYYY-MM-DD'
                        endTimeStr = eventDetails.end.substring(11, 16) || ''; // 'HH:mm'
                    } else if (eventDetails.end instanceof Date) {
                        // If it's a Date object, format it
                        endDateStr = formatDate(eventDetails.end);
                        endTimeStr = formatTime(eventDetails.end);
                    } else {
                        console.error('Unexpected type for eventDetails.end:', eventDetails.end);
                    }

                    setEndDate(endDateStr);
                    setEndTime(endTimeStr);
                } else {
                    // Reset if End is empty
                    setEndDate('');
                    setEndTime('');
                }

                // Set other state variables
                setAllDay(eventDetails.allDay || false);
                setIsHoliday(eventDetails.isHoliday || false);

                // If editing a single occurrence => no recurrence
                if (isEditing && editMode === 'single') {
                    setRecurrence(null);
                    setSelectedDays([]);
                    setCustomRecurrenceType('week');
                    setCustomRecurrenceCount(1);
                    setEndType('never');
                    setEndDateOn('');
                    setEndAfter(1);
                } else {
                    // Set recurrence options from eventDetails
                    // (We might override them below if we detect a 'weekends' case)
                    setRecurrence(eventDetails.recurrence || null);
                    setSelectedDays(eventDetails.selectedDays || []);
                    setCustomRecurrenceType(eventDetails.customRecurrenceType || 'week');
                    setCustomRecurrenceCount(eventDetails.customRecurrenceCount || 1);
                    setEndType(eventDetails.endType || 'never');
                    setEndDateOn(
                        eventDetails.endDateOn ? eventDetails.endDateOn.substring(0, 10) : ''
                    );
                    setEndAfter(
                        eventDetails.endAfter ? eventDetails.endAfter.toString() : ''
                    );
                }

                // NEW LOGIC: detect if it's actually "weekends" in disguise
                // (custom with selectedDays=['Sat','Sun'], weekly, count=1)
                if (
                    (eventDetails.recurrence === 'custom') &&
                    eventDetails.selectedDays &&
                    eventDetails.selectedDays.length === 2 &&
                    eventDetails.selectedDays.includes('Sat') &&
                    eventDetails.selectedDays.includes('Sun') &&
                    eventDetails.customRecurrenceType === 'week' &&
                    (eventDetails.customRecurrenceCount === 1)
                ) {
                    console.log('Detected weekends disguised as custom -> forcing "weekends"');
                    setRecurrence('weekends');
                }

                // Update duration
                setDuration(calculateDuration(startTimeStr, endTimeStr));
            } else {
                // Reset state variables for new event with default values
                const now = new Date();
                const defaultStartDateStr = formatDate(now);
                const defaultStartTimeStr = formatTime(now);
                const defaultEndTimeStr = formatTime(new Date(now.getTime() + 60 * 60 * 1000)); // +1 hr

                setStartDate(defaultStartDateStr);
                setStartTime(defaultStartTimeStr);
                setEndDate(defaultStartDateStr);
                setEndTime(defaultEndTimeStr);
                setAllDay(false);
                setDuration(calculateDuration(defaultStartTimeStr, defaultEndTimeStr));
                setRecurrence(null);
                setSelectedDays([]);
                setCustomRecurrenceType('week');
                setCustomRecurrenceCount(1);
                setEndType('never');
                setEndDateOn('');
                setEndAfter(1);
                setIsHoliday(false);
            }
        }
    }, [eventDetails, show, isEditing, editMode]);


    // Ensure 'Never' defaults to 1 year later if selected
    useEffect(() => {
        if (endType === 'never' && startDate) {
            const oneYearLater = new Date(startDate);
            oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
            setEndDateOn(formatDate(oneYearLater));
            // Ensure endAfter is cleared when 'never' is selected
            setEndAfter(null); // No occurrences limit when "never" is selected
        } else if (endType !== 'after') {
            // If EndType is not 'after', ensure EndAfter is null
            setEndAfter(null);
        }
    }, [endType, startDate]);

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
        setEndDate(e.target.value);
    };

    // EXACT code to replace for startTime:
    const handleStartTimeChange = (event) => {
        console.log('Start Time Changed:', event.target.value);
        const newStartTime = convertTo24Hour(event.target.value);
        setStartTime(newStartTime);
        updateEndDateAndTime(startDate, newStartTime, endTime);
    };

    // EXACT code to replace for endTime:
    const handleEndTimeChange = (event) => {
        console.log('End Time Changed:', event.target.value);
        const newEndTime = convertTo24Hour(event.target.value);
        setEndTime(newEndTime);
        updateEndDateAndTime(startDate, startTime, newEndTime);
    };

    const updateEndDateAndTime = (startDate, startTime, endTime) => {
        if (!startDate || !startTime || !endTime) {
            return;
        }

        // Parse start time
        const [startHours, startMinutes] = startTime.split(':').map(Number);
        // Parse end time
        const [endHours, endMinutes] = endTime.split(':').map(Number);

        // Calculate total minutes
        const startTotalMinutes = startHours * 60 + startMinutes;
        const endTotalMinutes = endHours * 60 + endMinutes;

        let startDateTime = new Date(startDate);
        startDateTime.setHours(startHours, startMinutes, 0, 0);

        let endDateTime = new Date(startDate);
        endDateTime.setHours(endHours, endMinutes, 0, 0);

        if (endTotalMinutes <= startTotalMinutes) {
            // If end time is before or equal to start time, event spans to next day
            endDateTime.setDate(endDateTime.getDate() + 1);
            setEndDate(formatDate(endDateTime));
        } else {
            setEndDate(startDate);
        }

        setDuration(calculateDuration(startTime, endTime));
    };

    const handleRecurrenceChange = (e) => {
        const selectedRecurrence = e.target.id;
        setRecurrence(selectedRecurrence);

        if (selectedRecurrence === 'custom') {
            // Allow user to select custom days
            // Do not modify selectedDays here to allow user selection.
        } else if (selectedRecurrence === 'weekdays') {
            // Automatically select all weekdays
            setSelectedDays(WEEKDAYS);
        } else if (selectedRecurrence === 'weekends') {
            // Automatically select weekends
            setSelectedDays(WEEKENDS);
        } else {
            // For other recurrence types, clear selectedDays
            setSelectedDays([]);
        }
    };

    const handleDayClick = (day) => {
        if (recurrence === 'custom') {
            setSelectedDays((prevDays) =>
                prevDays.includes(day) ? prevDays.filter((d) => d !== day) : [...prevDays, day]
            );
        }
    };

    const WEEKDAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
    const WEEKENDS = ['Sat', 'Sun'];
    const parseDateStringToLocalDate = (dateString) => {
        const [year, month, day] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day);
    };


    useEffect(() => {
        // This effect calculates a human-readable summary of the recurrence
        const computeRecurrenceSummary = () => {
            // If no recurrence is set, clear the summary
            if (!recurrence) {
                setRecurrenceSummary('');
                return;
            }

            let summary = 'Occurs every ';

            // Helper to format selected days (for custom weekly)
            const formatSelectedDays = (daysArray) => {
                // Example: 'Mon, Wed and Fri'
                if (!daysArray || !daysArray.length) return '';
                if (daysArray.length === 1) return daysArray[0];

                return daysArray.reduce((acc, day, idx) => {
                    if (idx === 0) return day;
                    if (idx === daysArray.length - 1) {
                        return acc + ' and ' + day;
                    }
                    return acc + ', ' + day;
                }, '');
            };

            // Start date text (e.g., "Monday, January 1, 2025")
            let startDateFormatted = '';
            if (startDate) {
                const date = parseDateStringToLocalDate(startDate);
                const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
                startDateFormatted = date.toLocaleDateString('en-US', options);
            }

            // Build the main part of the recurrence text
            switch (recurrence) {
                case 'daily':
                    summary += 'day';
                    break;
                case 'weekly':
                    summary += 'week';
                    if (startDate) {
                        const date = parseDateStringToLocalDate(startDate);
                        const options = { weekday: 'long' };
                        const dayOfWeek = date.toLocaleDateString('en-US', options);
                        summary += ` on ${dayOfWeek}`;
                    }
                    break;
                case 'bi-weekly':
                    summary += '2 weeks';
                    if (startDate) {
                        const date = parseDateStringToLocalDate(startDate);
                        const options = { weekday: 'long' };
                        const dayOfWeek = date.toLocaleDateString('en-US', options);
                        summary += ` on ${dayOfWeek}`;
                    }
                    break;
                case 'monthly':
                    summary += 'month';
                    if (startDate) {
                        const date = parseDateStringToLocalDate(startDate);
                        const dayOfMonth = date.getDate();
                        summary += ` on day ${dayOfMonth}`;
                    }
                    break;
                case 'yearly':
                    summary += 'year';
                    if (startDate) {
                        const date = parseDateStringToLocalDate(startDate);
                        const options = { month: 'long', day: 'numeric' };
                        const dateFormatted = date.toLocaleDateString('en-US', options);
                        summary += ` on ${dateFormatted}`;
                    }
                    break;
                case 'weekdays':
                    summary += 'weekday (Monday through Friday)';
                    break;
                case 'weekends':
                    summary += 'weekend (Saturday and Sunday)';
                    break;
                case 'custom':
                    {
                        // For custom, e.g. “Occurs every 2 weeks on Mon, Wed and Fri”
                        const interval = parseInt(customRecurrenceCount, 10) || 1;
                        let unitText = '';
                        switch (customRecurrenceType) {
                            case 'day':
                                unitText = interval === 1 ? 'day' : 'days';
                                break;
                            case 'week':
                                unitText = interval === 1 ? 'week' : 'weeks';
                                break;
                            case 'month':
                                unitText = interval === 1 ? 'month' : 'months';
                                break;
                            case 'year':
                                unitText = interval === 1 ? 'year' : 'years';
                                break;
                            default:
                                unitText = 'days';
                        }
                        summary += `${interval} ${unitText}`;

                        if (customRecurrenceType === 'week' && selectedDays.length > 0) {
                            const daysList = formatSelectedDays(selectedDays);
                            summary += ` on ${daysList}`;
                        }
                    }
                    break;
                default:
                    summary += 'unknown pattern';
                    break;
            }

            // Add “starting …”
            if (startDateFormatted) {
                summary += ` starting ${startDateFormatted}`;
            }

            // End conditions
            if (endType === 'on' && endDateOn) {
                const date = parseDateStringToLocalDate(endDateOn);
                const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
                const endDateFormatted = date.toLocaleDateString('en-US', options);
                summary += ` and ends on ${endDateFormatted}`;
            } else if (endType === 'after' && endAfter) {
                summary += ` and ends after ${endAfter} occurrences`;
            } else if (endType === 'never') {
                // you can optionally mention "and never ends"
                // summary += ' and never ends';
            }

            setRecurrenceSummary(summary);
        };

        computeRecurrenceSummary();
    }, [
        recurrence,
        customRecurrenceType,
        customRecurrenceCount,
        selectedDays,
        startDate,
        endType,
        endDateOn,
        endAfter,
    ]);

    const handleSave = () => {
        const errors = [];

        // Validate start date
        if (!startDate) {
            errors.push('Please enter a valid start date.');
        }

        // Validate end date
        if (!endDate) {
            errors.push('Please enter a valid end date.');
        }

        // Validate start and end times if not all-day
        if (!allDay) {
            if (!startTime) {
                errors.push('Please enter a valid start time.');
            }
            if (!endTime) {
                errors.push('Please enter a valid end time.');
            }
        }

        if (errors.length > 0) {
            alert(errors.join('\n'));
            return;
        }

        // Parse date and time components
        const [startYear, startMonth, startDay] = startDate.split('-').map(Number);
        const [endYear, endMonth, endDay] = endDate.split('-').map(Number);

        if (
            isNaN(startYear) || isNaN(startMonth) || isNaN(startDay) ||
            isNaN(endYear) || isNaN(endMonth) || isNaN(endDay)
        ) {
            alert('Invalid date format. Please enter dates in YYYY-MM-DD format.');
            return;
        }

        let formattedStartTime = startTime;
        let formattedEndTime = endTime;

        if (allDay) {
            // For all-day events, set times to 00:00 and 23:59
            formattedStartTime = '00:00:00';
            formattedEndTime = '23:59:59';
        } else {
            // Ensure that startTime and endTime have seconds
            if (startTime.length === 5) {
                formattedStartTime = `${startTime}:00`;
            }
            if (endTime.length === 5) {
                formattedEndTime = `${endTime}:00`;
            }
        }

        const [startHours, startMinutes, startSeconds] = formattedStartTime.split(':').map(Number);
        const [endHours, endMinutes, endSeconds] = formattedEndTime.split(':').map(Number);

        if (
            isNaN(startHours) || isNaN(startMinutes) ||
            isNaN(endHours) || isNaN(endMinutes)
        ) {
            alert('Invalid time format. Please enter times in HH:MM format.');
            return;
        }

        // Create Date objects using individual components
        const startDateTime = new Date(
            startYear,
            startMonth - 1,
            startDay,
            startHours,
            startMinutes,
            startSeconds || 0,
            0
        );

        const endDateTime = new Date(
            endYear,
            endMonth - 1,
            endDay,
            endHours,
            endMinutes,
            endSeconds || 0,
            0
        );

        // Re-validate after building date objects
        if (!startDate) {
            errors.push('Please enter a valid start date.');
        }
        if (!endDate) {
            errors.push('Please enter a valid end date.');
        }

        if (!allDay) {
            if (!startTime) {
                errors.push('Please enter a valid start time.');
            }
            if (!endTime) {
                errors.push('Please enter a valid end time.');
            }
        }

        if (errors.length > 0) {
            alert(errors.join('\n'));
            return;
        }

        // Check if end time is after start time
        if (startDateTime >= endDateTime) {
            alert('End time must be later than start time.');
            return;
        }

        // Do not convert to UTC; store as local time strings
        const formattedStart = `${startDate}T${formattedStartTime}`;
        const formattedEnd = `${endDate}T${formattedEndTime}`;

        // Ensure EndAfter and EndDateOn are correctly set based on EndType
        let finalEndAfter = null;
        let finalEndDateOn = null;

        if (endType === 'after') {
            finalEndAfter = parseInt(endAfter, 10) || null;
        } else if (endType === 'on') {
            finalEndDateOn = endDateOn ? `${endDateOn}T23:59:59` : null;
        } else if (endType === 'never') {
            // For 'never', set EndDateOn to one year later
            const oneYearLater = new Date(startDateTime);
            oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
            finalEndDateOn = `${oneYearLater.getFullYear()}-${String(
                oneYearLater.getMonth() + 1
            ).padStart(2, '0')}-${String(oneYearLater.getDate()).padStart(2, '0')}T${String(
                oneYearLater.getHours()
            ).padStart(2, '0')}:${String(oneYearLater.getMinutes()).padStart(2, '0')}:${String(
                oneYearLater.getSeconds()
            ).padStart(2, '0')}`;
        }

        // Ensure CustomRecurrenceCount defaults to 1 if not specified
        const recurrenceCount = parseInt(customRecurrenceCount, 10) || 1;

        const newEvent = {
            title: allDay ? 'Closed All Day' : 'Open Hours',
            start: formattedStart,
            end: formattedEnd,
            recurrence: recurrence || null,
            allDay: allDay,
            selectedDays,
            customRecurrenceType: customRecurrenceType || null,
            customRecurrenceCount: recurrenceCount,
            endType: endType || 'never',
            endDateOn: finalEndDateOn,
            endAfter: finalEndAfter,
            isHoliday: isHoliday || false,
        };

        // If editing a single occurrence, strip out recurrence data
        if (isEditing && editMode === 'single') {
            newEvent.recurrence = null;
            newEvent.selectedDays = [];
            newEvent.customRecurrenceType = null;
            newEvent.customRecurrenceCount = null;
            newEvent.endType = null;
            newEvent.endDateOn = null;
            newEvent.endAfter = null;
        }

        // **** HERE IS THE IMPORTANT LINE: set the summary on the event object ****
        newEvent.recurrenceSummary = recurrenceSummary;

        const recurrenceChanged = isEditing && eventDetails.Recurrence !== recurrence;

        // Finally call the parent callback
        onSave(newEvent, recurrenceChanged);
    };



    if (!show) return null;

    if (showDeleteConfirmation) {
        return (
            <div className="aval-custom-modal-overlay">
                <div className="aval-custom-modal">
                    <div className="aval-custom-modal-header">
                        <div className="aval-custom-modal-title-wrapper">
                            <h2 className="aval-custom-modal-title">Confirm Deletion</h2>
                        </div>
                        <button onClick={handleCancelDelete} className="aval-custom-close-button">
                            ×
                        </button>
                    </div>
                    <div className="aval-custom-modal-body">
                        <p>
                            {deleteSeriesFlag
                                ? 'Are you sure you want to delete this series?'
                                : 'Are you sure you want to delete this occurrence?'}
                        </p>
                    </div>
                    <div className="aval-custom-modal-footer">
                        <button
                            className="aval-custom-button aval-custom-button-delete"
                            onClick={handleConfirmDelete}
                        >
                            Delete
                        </button>
                        <button
                            className="aval-custom-button"
                            onClick={handleCancelDelete}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    }


    return (
        <div className="aval-custom-modal-overlay">
            <div className="aval-custom-modal">
                <div className="aval-custom-modal-header">
                    <div className="aval-custom-modal-title-wrapper">
                        <img
                            src={ClockIcon}
                            alt="Clock Icon"
                            className="aval-custom-clock-icon"
                        />
                        <div>
                            <h2 className="aval-custom-modal-title">
                                {isEditing
                                    ? editMode === 'series'
                                        ? 'Edit Series'
                                        : 'Edit Occurrence'
                                    : 'Add Unavailability'}
                            </h2>
                            <p className="aval-custom-modal-subtitle">
                                Confirm the selected time slots for parking space(s) Robotaxis/AV are not allowed.
                            </p>
                        </div>
                    </div>
                    <button onClick={onClose} className="aval-custom-close-button">
                        ×
                    </button>
                </div>
                <div className="aval-custom-modal-body">
                    {/* Date Inputs */}
                    <div className="aval-custom-datetime-group">
                        <div className="aval-custom-calendar-icon-wrapper">
                            <img
                                src={CalendarIcon}
                                alt="Calendar Icon"
                                className="aval-custom-calendar-icon"
                            />
                        </div>
                        <div className="aval-custom-date-group">
                            <div className="aval-custom-input-container">
                                <input
                                    type="date"
                                    className="aval-custom-input"
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    placeholder="Select start date"
                                />
                            </div>
                        </div>
                    </div>
                    {/* Time Inputs */}
                    <div className="aval-custom-datetime-group">
                        <img
                            src={SmallClock}
                            alt="Clock Icon"
                            className="aval-custom-datetime-icon"
                        />
                        <div className="aval-custom-time-group">
                            <div className="aval-custom-input-container">
                                <input
                                    type="time"
                                    className="aval-custom-input"
                                    value={startTime || ''}
                                    onChange={handleStartTimeChange}
                                    placeholder="Select start time"
                                    disabled={allDay}
                                />
                            </div>
                            <div className="aval-custom-arrow-container">
                                <img
                                    src={ArrowIcon}
                                    alt="Arrow Icon"
                                    className="aval-custom-arrow-icon"
                                />
                            </div>
                            <div className="aval-custom-input-container">
                                <input
                                    type="time"
                                    className="aval-custom-input"
                                    value={endTime || ''}
                                    onChange={handleEndTimeChange}
                                    placeholder="Select end time"
                                    disabled={allDay}
                                />
                            </div>
                            {!allDay && (
                                <span
                                    className="custom-duration-text"
                                    style={{
                                        marginLeft: '2px',
                                        marginRight: '5px',
                                        fontSize: '12px',
                                    }}
                                >
                                    {duration}
                                </span>
                            )}
                        </div>

                        <div
                            className="custom-all-day"
                            style={{
                                alignItems: 'left',
                                textAlign: 'left',
                                fontSize: 'small',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            <input
                                type="checkbox"
                                id="allDay"
                                checked={allDay}
                                onChange={handleAllDayChange}
                                style={{ marginRight: '5px' }}
                            />
                            <label htmlFor="allDay" style={{ marginLeft: '-15px' }}>
                                All Day
                            </label>
                        </div>
                    </div>
                    {/* Conditionally render recurrence options */}
                    {!(isEditing && editMode === 'single') && (
                        <>
                            {/* Recurrence Options */}
                            <div className="aval-custom-recurrence-icon-wrapper">
                                <img
                                    src={RecurrenceIcon}
                                    alt="Recurrence Icon"
                                    className="aval-custom-recurrence-icon"
                                />
                            </div>
                            <div className="aval-custom-recurrence-options">
                                <div className="aval-custom-recurrence-option">
                                    <input
                                        type="radio"
                                        id="daily"
                                        name="recurrence"
                                        onChange={handleRecurrenceChange}
                                        checked={recurrence === 'daily'}
                                    />
                                    <label htmlFor="daily">Daily</label>
                                </div>
                                <div className="aval-custom-recurrence-option">
                                    <input
                                        type="radio"
                                        id="weekly"
                                        name="recurrence"
                                        onChange={handleRecurrenceChange}
                                        checked={recurrence === 'weekly'}
                                    />
                                    <label htmlFor="weekly">Weekly on the Same Day</label>
                                </div>
                                <div className="aval-custom-recurrence-option">
                                    <input
                                        type="radio"
                                        id="bi-weekly"
                                        name="recurrence"
                                        onChange={handleRecurrenceChange}
                                        checked={recurrence === 'bi-weekly'}
                                    />
                                    <label htmlFor="bi-weekly">Bi-Weekly</label>
                                </div>
                                <div className="aval-custom-recurrence-option">
                                    <input
                                        type="radio"
                                        id="monthly"
                                        name="recurrence"
                                        onChange={handleRecurrenceChange}
                                        checked={recurrence === 'monthly'}
                                    />
                                    <label htmlFor="monthly">Monthly on the Same Date</label>
                                </div>
                                <div className="aval-custom-recurrence-option">
                                    <input
                                        type="radio"
                                        id="yearly"
                                        name="recurrence"
                                        onChange={handleRecurrenceChange}
                                        checked={recurrence === 'yearly'}
                                    />
                                    <label htmlFor="yearly">Yearly on the Same Date</label>
                                </div>
                                <div className="aval-custom-recurrence-option">
                                    <input
                                        type="radio"
                                        id="weekdays"
                                        name="recurrence"
                                        onChange={handleRecurrenceChange}
                                        checked={recurrence === 'weekdays'}
                                    />
                                    <label htmlFor="weekdays">Every Weekdays Mon-Fri</label>
                                </div>
                                <div className="aval-custom-recurrence-option">
                                    <input
                                        type="radio"
                                        id="weekends"
                                        name="recurrence"
                                        onChange={handleRecurrenceChange}
                                        checked={recurrence === 'weekends'}
                                    />
                                    <label htmlFor="weekends">Every Weekends Sat-Sun</label>
                                </div>
                                <div className="aval-custom-recurrence-option">
                                    <input
                                        type="radio"
                                        id="custom"
                                        name="recurrence"
                                        onChange={handleRecurrenceChange}
                                        checked={recurrence === 'custom'}
                                    />
                                    <label htmlFor="custom">Custom Schedule</label>
                                </div>
                            </div>
                            {/* Custom Recurrence Options */}
                            {recurrence === 'custom' && (
                                <div className="aval-custom-recurrence-custom">
                                    <div className="aval-custom-recurrence-custom-every">
                                        <label htmlFor="customRecurrenceCount">Every</label>
                                        <input
                                            type="number"
                                            id="customRecurrenceCount"
                                            min="1"
                                            value={customRecurrenceCount}
                                            onChange={(e) => setCustomRecurrenceCount(e.target.value)}
                                            className="aval-custom-recurrence-input"
                                            placeholder="Enter recurrence count"
                                        />
                                        <label htmlFor="customRecurrenceType">
                                            Recurrence Type
                                        </label>
                                        <select
                                            id="customRecurrenceType"
                                            value={customRecurrenceType}
                                            onChange={(e) => setCustomRecurrenceType(e.target.value)}
                                            className="aval-custom-recurrence-select"
                                        >
                                            <option value="day">Day</option>
                                            <option value="week">Week</option>
                                            <option value="month">Month</option>
                                            <option value="year">Year</option>
                                        </select>
                                    </div>
                                    {customRecurrenceType === 'week' && (
                                        <div className="aval-custom-weekdays-group">
                                            <div className="aval-custom-weekdays-options">
                                                <p
                                                    className="aval-custom-weekdays-options"
                                                    style={{
                                                        paddingBottom: '15px',
                                                        color: '#000',
                                                        fontSize: 'small',
                                                    }}
                                                >
                                                    On
                                                </p>
                                                {[
                                                    'Mon',
                                                    'Tue',
                                                    'Wed',
                                                    'Thu',
                                                    'Fri',
                                                    'Sat',
                                                    'Sun',
                                                ].map((day) => (
                                                    <button
                                                        key={day}
                                                        className={`aval-custom-weekday-button ${selectedDays.includes(day) ? 'selected' : ''
                                                            }`}
                                                        onClick={() => handleDayClick(day)}
                                                    >
                                                        {day}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {/* Recurrence End Options */}
                            <div className="aval-custom-recurrence-end">
                                <p
                                    className="aval-custom-recurrence-custom-every"
                                    style={{ textAlign: 'left', paddingTop: '10px' }}
                                >
                                    Ends
                                </p>
                                <div className="aval-custom-recurrence-end-options">
                                    <div className="aval-custom-recurrence-option">
                                        <input
                                            type="radio"
                                            id="endNever"
                                            name="endType"
                                            checked={endType === 'never'}
                                            onChange={() => setEndType('never')}
                                        />
                                        <label htmlFor="endNever">Never</label>
                                    </div>
                                    <div className="aval-custom-recurrence-end-option">
                                        <input
                                            type="radio"
                                            id="endOn"
                                            name="endType"
                                            checked={endType === 'on'}
                                            onChange={() => setEndType('on')}
                                        />
                                        <label htmlFor="endOn">On</label>
                                        <div className="aval-custom-input-container">
                                            <input
                                                type="date"
                                                id="endDateOn"
                                                value={endDateOn}
                                                onChange={(e) => setEndDateOn(e.target.value)}
                                                disabled={endType !== 'on'}
                                                className="aval-custom-input"
                                                placeholder="Select end date"
                                            />
                                        </div>
                                    </div>
                                    <div className="aval-custom-recurrence-end-option">
                                        <input
                                            type="radio"
                                            data-testid="end-type-input"
                                            id="endAfter"
                                            name="endType"
                                            checked={endType === 'after'}
                                            onChange={() => setEndType('after')}
                                        />
                                        <label htmlFor="endAfter">After</label>
                                        <div className="aval-custom-input-container aval-custom-input-container-small">
                                            <input
                                                data-testid="recurcount"
                                                type="number"
                                                id="endAfterCount"
                                                min="1"
                                                value={endAfter !== null ? endAfter : ''}
                                                onChange={(e) => setEndAfter(e.target.value)}
                                                disabled={endType !== 'after'}
                                                className="aval-custom-input aval-custom-recurrence-end-input"
                                                placeholder="Enter occurrences"
                                            />
                                        </div>
                                        <span>times</span>
                                    </div>
                                </div>
                            </div>
                            {/* Recurrence Summary */}
                            {recurrence && recurrenceSummary && (
                                <div className="aval-custom-recurrence-summary">
                                    <p>{recurrenceSummary}</p>
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className="aval-custom-modal-footer">
                    <button
                        className="aval-custom-button aval-custom-button-primary"
                        onClick={handleSave}
                    >
                        {isEditing ? 'Save' : 'Confirm'}
                    </button>
                    {isEditing && (
                        <>
                            {editMode === 'single' && (
                                <button
                                    className="aval-custom-button aval-custom-button-delete"
                                    onClick={() => handleDeleteEvent(false)}
                                >
                                    Delete
                                </button>
                            )}
                            {editMode === 'series' && (
                                <button
                                    className="aval-custom-button aval-custom-button-delete"
                                    onClick={() => handleDeleteEvent(true)}
                                >
                                    Delete Series
                                </button>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AvailabilityModal;
