import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AddBankDetails from '../bankDetails/AddBankDetails';
import BankAccountList from './BankAccountList';
import axios from 'axios';
import FadeLoader from "react-spinners/FadeLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

const BankDetailsMain = (props) => {

    const { overrides, bankdetails, handleReview, errors, toggleCondition, handleSave, isSaveDisabled, parkinglotObj, setSelectedBankDetailID, ...rest } = props;
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;

    const navigate = useNavigate();
    const [NewAccount, setNewAccount] = useState(false);
    const [errorsbank, setbankErrors] = useState({});
    const [bankDetails, setBankDetails] = useState([]);
    const [loader, setLoader] = useState(true);
    const [filter, setFilter] = useState([]);
    const [search, setSearch] = useState("");
    const [open, setOpen] = useState(false);
    const [SelectedDetails, setSelectedDetails] = useState({})
    const [SelectedCard, setSelectedCard] = useState({})
    const [SelectedBank, prevSelectedBank] = useState(false)
    const [BankID, setID] = useState(0)
    const [accountinfo, setAccountinfo] = useState({
        BankName: "",
        AccountHolderName: "",
        AccountType: "",
        AccountNumber: "",
        RoutingNumber: "",
        SWIFT_BIC_Code: "",
        Add_Ass_with_Account: "",
        NickName: "",
        id: "",
    });

    useEffect(() => {
        fetchBankDetails();
    }, [props?.entity]);
    const fetchBankDetails = async () => {
        setLoader(true);
        try {
            const response = await fetch(`${hostPath}/api/UpdateBankDetails/${props?.entity}`, {
                headers: {
                    "x-functions-key": hostHeader
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();


            setBankDetails(data);
            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.error("Error fetching bank details:", error);
        }
    };

    const validateFields = () => {
        let tempErrors = {};
        let isValid = true;
        const validateField = (field, value, regex, emptyMessage, invalidMessage) => {
            if (value?.trim() === "" || value === null || value === undefined) {
                tempErrors[field] = emptyMessage;
                isValid = false;
            } else
                if (regex && !regex.test(value) && value?.length > 0) {
                    tempErrors[field] = invalidMessage;
                    isValid = false;
                }
        };
        validateField('BankName', accountinfo.BankName, /^[A-Za-z\s]+$/, 'Bank Name is required', 'Please enter alphabets only');
        validateField('AccountHolderName', accountinfo.AccountHolderName, /^[A-Za-z\s]+$/, 'Account Holder Name is required', 'Please enter alphabets only');
        {
            accountinfo?.id ?
                validateField('AccountNumber', accountinfo.AccountNumber, /^[\d\*]{8,12}$/, 'Account Number is required', 'Account Number must be between 8 and 12 digits or asterisks') :

                validateField('AccountNumber', accountinfo.AccountNumber, /^\d{8,12}$/, 'Account Number is required', 'Account Number must be between 8 and 12 digits')
        }
        validateField('RoutingNumber', accountinfo.RoutingNumber, /^\d{9}$/, 'Routing Number is required', 'Routing Number must be exactly 9 digits');
        validateField('AccountType', accountinfo.AccountType, /^[A-Za-z\s()]+$/, 'Account Type is required', 'Please Check the Account Type Format');
        // validateField('Add_Ass_with_Account', accountinfo.Add_Ass_with_Account, /^[A-Za-z0-9\s!@#$%^&*(),.?":{}|<>-_]+$/, 'Address is required', 'Please Check the Account Type Format');
        validateField('NickName', accountinfo.NickName, /^[A-Za-z0-9\s!@#$%^&*(),.?":{}|<>-_]+$/, ' NickName is required', 'Please Check the NickName Format');
        setbankErrors(tempErrors);
        return isValid;
    };
    const handleInputChange = (account) => (e) => {
        const { name, value } = e.target;
        setAccountinfo({ ...accountinfo, [account]: value });
        const validateField = (name, value, regex, emptyMessage, invalidMessage) => {
            if (value.trim() === "") {
                setbankErrors(errors => ({ ...errors, [name]: emptyMessage }));
            } else if (regex && !regex.test(value)) {
                setbankErrors(errors => ({ ...errors, [name]: invalidMessage }));
            } else {
                setbankErrors(errors => ({ ...errors, [name]: "" }));
            }
        };
        switch (name) {
            case 'BankName':
                validateField(name, value, /^[a-zA-Z\s]*$/, '', 'Please enter alphabets only');
                break;
            case 'AccountHolderName':
                validateField(name, value, /^[a-zA-Z\s]*$/, '', 'Please enter alphabets only');
                break;
            case 'AccountType':
                validateField(name, value, /^[A-Za-z\s()]+$/, '', 'Please check the Account Type format');
                break;
            case 'AccountNumber':
                validateField(name, value, /^\d{8,12}$/, '', 'Account Number must be between 8 and 12 digits');
                break;
            case 'RoutingNumber':
                validateField(name, value, /^\d{9}$/, '', 'Routing Number must be exactly 9 digits');
                break;
            case 'Add_Ass_with_Account':
                validateField(name, value, /^[A-Za-z0-9\s!@#$%^&*(),.?":{}|<>-_]+$/, '', 'Address Number must be alphabets only');
                break;
            case 'NickName':
                validateField(name, value, /^[A-Za-z0-9\s!@#$%^&*(),.?":{}|<>-_]+$/, '', 'NickName alphabets only');
                break;
            case 'SWIFT_BIC_Code':
                // Add validation if needed
                break;
            default:
                break;
        }
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedDetails(null);
    };

    const handleButtonClick = async (e) => {
        if (validateFields()) {
            accountinfo.EntityRegistrationID = props?.entity;

            if (accountinfo?.id) {
                try {
                    const UpdatesResp = await axios.put(`${hostPath}/api/UpdateBankDetails`, accountinfo, {
                        headers: {
                            "x-functions-key": hostHeader,
                        },
                    });

                    if (UpdatesResp.data.status === true) {
                        toast.success("Successfully Updated Bank Details!", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setNewAccount(false);
                        fetchBankDetails();
                        setAccountinfo([]);
                    } else {
                        toast.error(UpdatesResp.data.responseMessage || "Failed to update bank details!");
                    }
                } catch (error) {
                    toast.error("Error updating bank details!");
                }
            } else {
                const payload = {
                    BankName: accountinfo.BankName,
                    AccountHolderName: accountinfo.AccountHolderName,
                    AccountType: accountinfo.AccountType,
                    AccountNumber: accountinfo.AccountNumber,
                    RoutingNumber: accountinfo.RoutingNumber,
                    SWIFT_BIC_Code: accountinfo.SWIFT_BIC_Code,
                    Add_Ass_with_Account: accountinfo.Add_Ass_with_Account,
                    NickName: accountinfo.NickName,
                    EntityRegistrationID: props?.entity,
                };
                try {
                    const res = await axios.post(`${hostPath}/api/UpdateBankDetails`, payload, {
                        headers: {
                            "x-functions-key": hostHeader,
                        },
                    });

                    if (res.data.status === true) {
                        toast.success("Successfully Added Bank Details!");
                        setNewAccount(false);
                        fetchBankDetails();
                        setAccountinfo([]);
                    } else {
                        toast.error(res.data.responseMessage || "Failed to add bank details!");
                    }
                } catch (error) {
                    toast.error("Error adding bank details!");
                }
            }
        }
    };

    const handlebankdetailsSave = (e) => {
        // if (validateFields()) {
        //     handleSave(e);
        // }
    }
    const backButtonClick = () => {
        props.handlebankdetails(false);
        // toggleCondition('4 of 5 - Enter Bank Details')
        // toggleCondition('3 of 5 - Schedule Availability')
    }
    const addNewAccount = () => {
        setNewAccount(true);

    }

    const handleRowClick = (details) => {
        if (SelectedCard && SelectedCard === details.id) {
            setSelectedBankDetailID([]);
            setSelectedCard(null);
            prevSelectedBank(false)
            setID(0)
        } else {
            const select = filter.find((item) => item.bankDetailID === details.bankDetailID)
            setSelectedCard(details.id);
            prevSelectedBank(false)
            setID(select.bankDetailID)
            setSelectedBankDetailID(details.bankDetailID);
        }
    };


    const handleEdit = async (details) => {
        setNewAccount(true);
        setAccountinfo({
            BankName: details.bankName,
            AccountHolderName: details.accountHolderName,
            AccountType: details.accountType,
            AccountNumber: details.accountNumber,
            RoutingNumber: details.routingNumber,
            SWIFT_BIC_Code: details.swifT_BIC_Code,
            Add_Ass_with_Account: details.add_Ass_with_Account,
            NickName: details.nickName,
            BankDetailID: details.bankDetailID,
            id: details.id
        });
    }

    const handleDelete = (details) => {
        setSelectedDetails(details);
        setOpen(true);
    };

    const confirmDelete = async () => {
        try {
            const response = await axios.delete(`${hostPath}/api/DeleteBankDetailById/${SelectedDetails?.bankDetailID}`, {
                headers: {
                    "x-functions-key": hostHeader,
                },
            });

            if (response.status !== 200) {
                toast.error(response.data.responseMessage);
                setOpen(false)
                throw new Error(
                    `Failed to delete bank detail with ID ${SelectedDetails?.bankDetailID}. Status: ${response.status}`
                );
            }

            if (response.data.status === true) {
                const updateFilter = filter.filter(
                    account => account.bankDetailID !== SelectedDetails?.bankDetailID
                );
                setFilter(updateFilter);
                toast.success(response.data.responseMessage);
                setOpen(false)
            }
        } catch (error) {
            console.error("Error deleting bank detail:", error.message);
            toast.error("Failed to delete the bank detail. Please try again.");
            setOpen(false)
        }
    };

    const handlebankdetails = (val) => {
        setNewAccount(false);
        setAccountinfo([]);
    }
    useEffect(() => {
        let result = bankDetails;
        if (parkinglotObj?.bankDetailID) {
            const select = result.find((item) => item.bankDetailID === parkinglotObj?.bankDetailID)
            if (select?.bankDetailID) {
                setID(select?.bankDetailID)
                prevSelectedBank(true)
                setSelectedBankDetailID(select?.bankDetailID)
            }
        }
        // Filter by search query
        if (search) {
            result = result.filter((item) => {
                return (item.nickName).toLowerCase().includes(search.toLowerCase());
            });
        }

        setFilter(result);
    }, [search, bankDetails]);


    return (
        <div>
            {loader && (
                <div className="loader-overlay">
                    <FadeLoader />
                </div>
            )}
            <ToastContainer />
            <div style={{ marginLeft: '60px', marginRight: '80px', marginBottom: '-20px', marginTop: '15px' }}>
                <div className="col-md-10">
                    <div className="row m-4">
                        <div className="col-lg-12 col-md-12 col-sm-12 m-3">
                            {NewAccount ? (
                                <AddBankDetails
                                    accountinfo={accountinfo}
                                    handlebankdetails={handlebankdetails}
                                    handleInputChange={handleInputChange}
                                    validateFields={validateFields}
                                    handleButtonClick={handleButtonClick}
                                    errors={errorsbank}
                                />
                            ) : (
                                <>
                                    <div className="row" style={{ textAlign: "left" }}>
                                        <div className="col-8"></div>
                                        <div className="col-4">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search by NickName."
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    {filter.length > 0 ? (
                                        <>
                                            <div className="d-flex justify-content-between align-items-center bankdetails">
                                                <span className="PrefferedbankHeader">Choose Your Preferred Bank Account</span>
                                                <span className="AddBank" onClick={addNewAccount}>
                                                    Add Bank Account
                                                </span>
                                            </div>


                                            <div className="row scrollable-container">
                                                {filter.map((details, index) => (
                                                    <div key={index} onClick={() => handleRowClick(details)}>
                                                        <BankAccountList
                                                            details={details}
                                                            nickName={details.nickName}
                                                            accountNumber={details.accountNumber}
                                                            routingNumber={details.routingNumber}
                                                            accountHolderName={details.accountHolderName}
                                                            bankName={details.bankName}
                                                            onEdit={() => handleEdit(details)}
                                                            onDelete={() => handleDelete(details)}
                                                            handleSelectedowner={() => handleRowClick(details)}
                                                            SelectedCard={SelectedCard}
                                                            SelectedBank={SelectedBank}
                                                            BankID={BankID}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="align-items-center bankdetails" style={{ textAlign: "end" }}>
                                                <button
                                                    type="submit"
                                                    className="goback"
                                                    onClick={backButtonClick}
                                                    style={{ marginRight: "10px" }}
                                                >
                                                    Go Back
                                                </button>
                                                <Button className="nextBank" onClick={handleReview}>Next</Button>
                                            </div>
                                        </>
                                    ) : search.length > 0 ? (
                                        <div >
                                            <span className="no-records-found">No Data Found</span>
                                        </div>
                                    ) : (!loader &&
                                        <div>
                                            <div className="container-BankDetails-Homepage">
                                                <div className="group-home">
                                                    <div className="Bankdetails-img" />
                                                </div>
                                                <span className="Header-NoAccounts">No Bank Account(s) Saved Yet</span>
                                                <span className="text-AddNewAccount">
                                                    Add banking information where to pay you.
                                                </span>
                                                <div className="AddNewAccount" onClick={addNewAccount}>
                                                    <span className="AddNewAccount-Btn" >
                                                        Add New Account
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div
                                                    className="align-items-center bankDetailsbuttons"
                                                    style={{ textAlign: "end" }}
                                                >
                                                    <button
                                                        type="submit"
                                                        className="goback"
                                                        onClick={backButtonClick}
                                                        style={{ marginRight: "10px" }}
                                                    >
                                                        Go Back
                                                    </button>
                                                    <Button className="nextBank" onClick={handleReview}>Next</Button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>


            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        width: '400px',
                        padding: '16px',
                        borderRadius: '8px',
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontSize: '18px',
                    }}
                >
                    Confirm Deletion
                </DialogTitle>
                <DialogContent>
                    Are you sure you want to delete the <strong>{SelectedDetails?.nickName}</strong> bank details?
                </DialogContent>
                <DialogActions
                    sx={{
                        justifyContent: 'center',
                    }}
                >
                    <Button onClick={handleClose} sx={{
                        backgroundColor: '#d3d3d3',
                        color: '#000',
                        '&:hover': {
                            backgroundColor: '#c0c0c0',
                        },
                    }} >
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} color="primary" variant="contained">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

        </div>


    );
}

export default BankDetailsMain;